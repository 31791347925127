import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { ArrowBack, Refresh, SentimentDissatisfied } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

/**
 * NoRecordsFound Component
 * Renders a message when no records are found in a module.
 * 
 * Props:
 * - message: Custom message to display (optional, default: "No records found!")
 */
const NoRecordsFound = ({
  title = "No records found",
  subTitle = "Sorry, we couldn't find.",
  buttonText,
  onRefresh,
  Icon,
  ButtonIcon,
}) => {
  const navigate = useNavigate();

  const iconStyle = {
    fontSize: 150,
    mb: 2,
    color: "text.disabled",
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "50vh",
        p: 2,
      }}
    >
      {
        Icon
          ? <Icon sx={iconStyle} />
          : <SentimentDissatisfied sx={iconStyle} />
      }

      <Typography variant="h3" color="textDisabled" gutterBottom textAlign="center">
        {title}
      </Typography>

      <Typography variant="h6" color="textDisabled" gutterBottom textAlign="center">
        {subTitle}
      </Typography>

      <Button
        startIcon={ButtonIcon ? <ButtonIcon /> : (onRefresh ? <Refresh /> : <ArrowBack />)}
        onClick={() => {
          if (onRefresh) {
            onRefresh();
          } else {
            navigate(-1);
          }
        }}
        variant="contained"
        size="small"
        sx={{ mt: 1 }}
      >
        {buttonText || onRefresh ? (buttonText || "Refresh") : "Back"}
      </Button>
    </Box>
  );
};

export default NoRecordsFound;
