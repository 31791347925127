import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, IconButton } from "@mui/material";
import { authApi } from "../../services/api";
import ReactCodeInput from "react-code-input";
import { Edit } from "@mui/icons-material";
import reduxData from "../../utils/useReduxData";

const OtpVerificationModal = ({ open, onClose, fieldType, otpToken, setOtpToken, contactValue, onVerified }) => {
  const [otp, setOtp] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { checkOtp, sendOtp } = authApi();

  const [resendTimer, setResendTimer] = useState(30);
  const [canResend, setCanResend] = useState(false);

  // Start a timer for 30s to enable Resend
  useEffect(() => {
    const timer =
      resendTimer > 0 &&
      setInterval(() => setResendTimer((prev) => prev - 1), 1000);

    if (resendTimer === 0) {
      setCanResend(true);
    }

    return () => clearInterval(timer);
  }, [resendTimer]);

  const handleVerifyOtp = async (val) => {
    setIsSubmitting(true);
    setErrorMessage("");
    try {
      const updatedProfile = await checkOtp({ token: otpToken, otp: val });
      reduxData("profile", "set")(updatedProfile);
      onClose();
      onVerified();
    } catch (err) {
      setErrorMessage(err.message || "Invalid OTP. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOtpChange = (val) => {
    setErrorMessage("");
    setOtp(val);

    if (val.length === 6) {
      handleVerifyOtp(val);
    }
  };

  const handleResendOtp = async () => {
    setOtp("");
    setResendTimer(30);
    setCanResend(false);
    setErrorMessage("");

    try {
      const newResponse = await sendOtp({ value: contactValue });
      setOtpToken(newResponse.token);
    } catch (err) {
      setErrorMessage(err.message || "Failed to resend OTP.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth sx={{ zIndex: 122200 }}>
      <DialogTitle>
        <Typography variant="h6" fontWeight="bold" textAlign="center">
          Verify Your {fieldType === "email" ? "Email" : "Phone"}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>

        <Box
          sx={{
            py: 2,
            px: 0,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            alignItems: "center",
          }}
        >

          {/* Row with user value + Edit Icon */}
          <Typography variant="body2">
            Enter the OTP sent to
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              justifyContent: "center",
              mb: 2,
            }}
          >
            <Typography variant="body1" fontWeight="bold" noWrap>
              {contactValue}
            </Typography>
            <IconButton size="small" onClick={onClose}>
              <Edit fontSize="small" color="primary" />
            </IconButton>
          </Box>

          {/* OTP Input */}
          <ReactCodeInput
            type="text" // Change to text to remove the up/down buttons
            inputMode="numeric" // Ensures numeric keyboard on mobile
            pattern="[0-9]*" // Ensures only numbers are entered
            fields={6}
            autoFocus
            value={otp}
            onChange={handleOtpChange}
            inputStyle={{
              margin: "2px",
              width: "35px",
              height: "35px",
              fontSize: "20px",
              borderRadius: "8px",
              border: "1px solid #ccc",
              textAlign: "center",
              MozAppearance: "textfield", // Removes arrows in Firefox
            }}
          />
          {errorMessage && (
            <Typography variant="body2" color="error">
              {errorMessage}
            </Typography>
          )}

          {/* Resend */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="body2">
              Didn&apos;t receive code?
            </Typography>
            <Button
              variant="text"
              disabled={!canResend}
              onClick={handleResendOtp}
            >
              {canResend ? "Resend" : `Resend in ${resendTimer}`}
            </Button>
          </Box>

        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isSubmitting}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => handleVerifyOtp(otp)}
          disabled={isSubmitting || otp.length === 0}
        >
          {isSubmitting ? "Verifying..." : "Verify"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OtpVerificationModal;
