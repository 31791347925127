import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, TextField, Button, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { Person, Phone, Email, Business, CheckCircle } from "@mui/icons-material";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { authApi, profileApi } from "../../services/api";
import OtpVerificationModal from "./OtpVerificationModal"; // We'll create this below
import reduxData from "../../utils/useReduxData";

const InitialSetupModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const profile = useSelector((state) => state.profile);
  const library = useSelector((state) => state.library);

  const initialValues = {
    name: profile.name,
    email: profile.email,
    contactNumber: profile.contactNumber ? profile.contactNumber?.phoneNumber : "",
    libraryName: library.name,
  };

  useEffect(() => {
    if (profile) {
      const { name, email, contactNumber, isContactNumberVerified, isEmailVerified } = profile;
      const { name: libraryName } = library;
      const isProfileComplete = name && email && contactNumber && libraryName && isContactNumberVerified && isEmailVerified;
      setIsModalOpen(!isProfileComplete);
    }
  }, [profile, library]);

  const { checkEmail, checkNumber, checkLibraryName, sendOtp } = authApi();
  const { updateProfile, updateLibrary } = profileApi();

  // Track local error states
  const [emailError, setEmailError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [libraryNameError, setLibraryNameError] = useState("");

  // Track verification status
  const [emailVerified, setEmailVerified] = useState(profile.isEmailVerified);
  const [numberVerified, setNumberVerified] = useState(profile.isContactNumberVerified);
  const [nameSaved, setNameSaved] = useState(initialValues.name);
  const [libraryNameSaved, setLibraryNameSaved] = useState(initialValues.libraryName);
  const [libraryNameVerified, setLibraryNameVerified] = useState(initialValues.libraryName);

  // Checking states
  const [isEmailChecking, setIsEmailChecking] = useState(false);
  const [isContactNumberChecking, setIsContactNumberChecking] = useState(false);
  const [isNameSaving, setIsNameSaving] = useState(false);
  const [isLibraryNameChecking, setIsLibraryNameChecking] = useState(false);

  // Debounce
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  // OTP modal states
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [otpFieldType, setOtpFieldType] = useState(""); // "email" or "contactNumber"
  const [otpToken, setOtpToken] = useState("");
  const [pendingValue, setPendingValue] = useState(""); // store new email/phone for OTP verification

  // If user wants a password in the same form, keep your password logic
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Enter a valid email address")
      .required("Email is required"),
    contactNumber: Yup.string()
      .matches(/^\d{10}$/, "Phone number must contain exactly 10 digits")
      .required("Phone number is required"),
    libraryName: Yup.string().required("Library name is required"),
  });

  /**
   * Determine if the user changed email or phone from the initial values.
   * If changed, we require them to verify.
   */
  const hasEmailChanged = (values) => values.email !== initialValues.email;
  const hasNumberChanged = (values) => values.contactNumber !== initialValues.contactNumber;

  const handleSaveLibraryName = async (libraryName) => {
    setIsLibraryNameChecking(true);
    try {
      const res = await updateLibrary(libraryName);

      reduxData("library", "set")(res);
      setLibraryNameSaved(true);
    } catch (err) {
      setLibraryNameError(err.message || "Unable to save library name");
    } finally {
      setIsLibraryNameChecking(false);
    }
  }

  const handleSaveName = async (name) => {
    setIsNameSaving(true);
    try {
      const res = await updateProfile(name);

      reduxData("profile", "set")(res);
      setNameSaved(true);
    } catch (err) {
      // setEmailError(err.message || "Unable to send OTP");
    } finally {
      setIsNameSaving(false);
    }
  }

  /*******************************
   * Email Checking & Verification
   *******************************/
  const validateEmail = async (email) => {
    setEmailVerified(false);
    setEmailError("");
    if (!email) return;

    setIsEmailChecking(true);
    try {
      await checkEmail(email);
      setEmailError("");
      // We do NOT set emailVerified = true here, because user must do OTP verification
      // We'll show a "Verify" button if it's available
    } catch (error) {
      setEmailError(error.message || "Email is already in use");
    } finally {
      setIsEmailChecking(false);
    }
  };

  const handleVerifyEmail = async (email) => {
    // 1) Call sendOtp for the new email
    try {
      const res = await sendOtp({ value: email });
      setOtpToken(res.token);
      setPendingValue(email);
      setOtpFieldType("email");
      setOtpModalOpen(true); // open OTP modal
    } catch (err) {
      setEmailError(err.message || "Unable to send OTP");
    }
  };

  /***********************************
   * Phone Checking & Verification
   ***********************************/
  const validateContactNumber = async (contactNumber) => {
    setNumberVerified(false);
    setNumberError("");
    if (!contactNumber) return;

    setIsContactNumberChecking(true);
    try {
      await checkNumber(contactNumber);
      setNumberError("");
      // Similarly, we do not set verified yet. Must do OTP verification
    } catch (error) {
      setNumberError(error.message || "Phone number is already in use");
    } finally {
      setIsContactNumberChecking(false);
    }
  };

  const handleVerifyNumber = async (number) => {
    try {
      const res = await sendOtp({ value: number });
      setOtpToken(res.token);
      setPendingValue(number);
      setOtpFieldType("contactNumber");
      setOtpModalOpen(true); // open OTP modal
    } catch (err) {
      setNumberError(err.message || "Unable to send OTP");
    }
  };

  /***********************************************
   * Library Name Checking (unchanged from before)
   ***********************************************/
  const validateLibraryName = async (libraryName) => {
    setLibraryNameVerified(false);
    setLibraryNameError("");
    setIsLibraryNameChecking(true);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    if (!libraryName) return;

    const newTimeout = setTimeout(async () => {
      try {
        await checkLibraryName(libraryName);
        setLibraryNameVerified(true);
      } catch (error) {
        setLibraryNameError(error.message || "Library Name is already in use");
        setLibraryNameVerified(false);
      } finally {
        setIsLibraryNameChecking(false);
      }
    }, 2000);

    setDebounceTimeout(newTimeout);
  };

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
    };
  }, [debounceTimeout]);

  /***********************************************
   * OTP Verified Callback
   ***********************************************/
  const handleOtpVerified = () => {
    // If user verified email
    if (otpFieldType === "email") {
      setEmailVerified(true);
      setEmailError("");
    }
    // If user verified phone
    if (otpFieldType === "contactNumber") {
      setNumberVerified(true);
      setNumberError("");
    }
    // Close OTP modal
    setOtpModalOpen(false);
  };

  const EndAdornment = ({ isChecking, needVerification = false, btnText = "Verify", onVerify, verified = false }) => {
    return (
      <>
        {isChecking && <CircularProgress size={20} />}
        {needVerification && (
          <>
            <Button
              size="small"
              onClick={onVerify}
            >
              {btnText}
            </Button>
          </>
        )}
        {verified && <CheckCircle color="success" />}
      </>
    )
  }

  return (
    <>
      <Dialog
        open={isModalOpen}
        maxWidth="xs"
        fullWidth
        aria-labelledby="initial-setup-dialog-title"
      >
        <DialogTitle id="initial-setup-dialog-title">
          Library Initial Setup
        </DialogTitle>

        <DialogContent dividers>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({ values, errors, touched, setFieldValue }) => {
              const showEmailVerifyButton = !isEmailChecking && !errors.email && !emailError && (hasEmailChanged(values) || !emailVerified);
              const showNumberVerifyButton = !isContactNumberChecking && !errors.contactNumber && !numberError && (hasNumberChanged(values) || !numberVerified);
              const showLibraryNameSaveBtn = !isLibraryNameChecking && !errors.libraryName && !libraryNameError && libraryNameVerified && !libraryNameSaved;

              return (
                <Form id="initialSetupForm">
                  {/* Full Name */}
                  <Field
                    as={TextField}
                    fullWidth
                    label="Full Name"
                    name="name"
                    variant="standard"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    InputProps={{
                      startAdornment: <Person sx={{ mr: 1 }} />,
                      endAdornment: (
                        <EndAdornment
                          isChecking={isNameSaving}
                          onVerify={() => handleSaveName(values.name)}
                          needVerification={!nameSaved && values.name}
                          verified={nameSaved}
                          btnText="save"
                        />
                      ),
                    }}
                    required
                    sx={{ mb: 2 }}
                  />

                  {/* Phone Number */}
                  <Field
                    as={TextField}
                    fullWidth
                    label="Phone Number"
                    name="contactNumber"
                    variant="standard"
                    error={(touched.contactNumber && Boolean(errors.contactNumber)) || Boolean(numberError)}
                    helperText={(touched.contactNumber && errors.contactNumber) || numberError}
                    disabled={numberVerified}
                    InputProps={{
                      startAdornment: <Phone sx={{ mr: 1 }} />,
                      endAdornment: (
                        <EndAdornment
                          isChecking={isContactNumberChecking}
                          onVerify={() => handleVerifyNumber(values.contactNumber)}
                          needVerification={showNumberVerifyButton}
                          verified={numberVerified}
                        />
                      ),
                    }}
                    inputProps={{ maxLength: 10 }}
                    onChange={(e) => {
                      setNumberVerified(false);
                      setFieldValue("contactNumber", e.target.value);
                      setNumberError("");
                      if (e.target.value.length === 10) {
                        validateContactNumber(e.target.value);
                      }
                    }}
                    sx={{ mb: 2 }}
                  />

                  {/* Email */}
                  <Field
                    as={TextField}
                    fullWidth
                    label="Email"
                    name="email"
                    variant="standard"
                    error={(touched.email && Boolean(errors.email)) || Boolean(emailError)}
                    helperText={(touched.email && errors.email) || emailError}
                    disabled={emailVerified}
                    InputProps={{
                      startAdornment: <Email sx={{ mr: 1 }} />,
                      endAdornment: (
                        <EndAdornment
                          isChecking={isEmailChecking}
                          onVerify={() => handleVerifyEmail(values.email)}
                          needVerification={showEmailVerifyButton}
                          verified={emailVerified}
                        />
                      ),
                    }}
                    onChange={(e) => {
                      setEmailVerified(false);
                      setFieldValue("email", e.target.value);
                      setEmailError("");
                      // Minimal check before verifying
                      if (e.target.value.includes(".co")) {
                        validateEmail(e.target.value);
                      }
                    }}
                    sx={{ mb: 2 }}
                  />

                  {/* Library Name */}
                  <Field
                    as={TextField}
                    name="libraryName"
                    label="Your Library Name"
                    fullWidth
                    variant="standard"
                    error={(touched.libraryName && Boolean(errors.libraryName)) || Boolean(libraryNameError)}
                    helperText={(touched.libraryName && errors.libraryName) || libraryNameError}
                    InputProps={{
                      startAdornment: <Business sx={{ mr: 1 }} />,
                      endAdornment: (
                        <>
                          <EndAdornment
                            isChecking={isLibraryNameChecking}
                            onVerify={() => handleSaveLibraryName(values.libraryName)}
                            needVerification={showLibraryNameSaveBtn}
                            verified={libraryNameSaved}
                            btnText="save"
                          />
                        </>
                      ),
                    }}
                    onChange={(e) => {
                      setFieldValue("libraryName", e.target.value);
                      validateLibraryName(e.target.value);
                    }}
                    sx={{ mb: 2 }}
                  />
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>

      {/* OTP Verification Modal */}
      <OtpVerificationModal
        open={otpModalOpen}
        onClose={() => setOtpModalOpen(false)}
        fieldType={otpFieldType} // "email" or "contactNumber"
        otpToken={otpToken}
        setOtpToken={setOtpToken}
        contactValue={pendingValue}
        onVerified={handleOtpVerified}
      />
    </>
  );
};

export default InitialSetupModal;
