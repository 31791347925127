import React, { useState } from "react";
import { Box, Typography, Divider, Button, Grid2, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { useLocation, useNavigate } from "react-router-dom";
import PageContainer from "../components/Containers/PageContainer";
import CustomCard from "../components/Containers/Card";
import { initiatePayment } from "../services/paymentService";
import moment from "moment";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import formattedContactNumber from "../utils/formateContactNumber";

const PaymentConfirmation = () => {
  const navigate = useNavigate();

  const theme = useTheme();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const profile = useSelector(state => state.profile);

  if (!location.state && !Object.keys(location.state).length) {
    return null;
  }

  const { newSubscription, razorpayOrder } = location.state;
  const { planInfo } = newSubscription;
  const totalMonths = moment.duration(planInfo.duration.value, planInfo.duration.unit).asMonths();
  const perMonthPrice = (planInfo.finalPrice / totalMonths).toFixed(2);

  const handlePayment = async () => {
    setLoading(true);
    try {
      razorpayOrder.prefill = {
        name: profile.name,
        email: profile.email,
        contact: formattedContactNumber(profile.contactNumber),
      };

      await initiatePayment(razorpayOrder);
      Swal.fire({
        icon: "success",
        type: "success",
        title: "Plan Changed",
        text: "Your plan has been successfully changed.",
      })
      navigate("/settings", {
        state: { tabNumber: 3 }
      });
    } catch (error) {
      console.error("Payment Failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) =>
    new Date(dateString).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

  return (
    <PageContainer>
      {/* Header */}
      <Box
        sx={{
          backgroundColor: "primary.main",
          p: 2,
          borderRadius: 2,
          color: "primary.contrastText",
          textAlign: "center",
          mb: 3,
        }}
      >
        <Typography variant="h5" fontWeight="bold">
          Payment Confirmation
        </Typography>
      </Box>

      {/* Payment Status */}
      <CustomCard>
        <Box display="flex" alignItems="center">
          {razorpayOrder.status === "created" ? (
            <ErrorIcon color="warning" sx={{ fontSize: 40, mr: 2 }} />
          ) : (
            <CheckCircleIcon color="success" sx={{ fontSize: 40, mr: 2 }} />
          )}
          <Box>
            <Typography variant="h6" fontWeight="bold">
              Payment Status:{" "}
              <Typography
                component="span"
                fontWeight="bold"
                color={
                  razorpayOrder.status === "created"
                    ? theme.palette.warning.main
                    : theme.palette.success.main
                }
              >
                {razorpayOrder.status.toUpperCase()}
              </Typography>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Order ID: {razorpayOrder.id}
            </Typography>
          </Box>
        </Box>
      </CustomCard>

      {/* Subscription Details */}
      <CustomCard>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Subscription Details
        </Typography>
        <Divider sx={{ my: 2 }} />

        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12, sm: 6 }}>
            <Typography variant="subtitle1" fontWeight="bold">
              Plan Name:
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {planInfo.title}
            </Typography>
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 6 }}>
            <Typography variant="subtitle1" fontWeight="bold">
              Price:
            </Typography>
            <Typography variant="body2" color="text.secondary">
              ₹{perMonthPrice}{" "} / {planInfo.duration.unit} {totalMonths > 1 ? ` * ${totalMonths}` : ""}
            </Typography>
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 6 }}>
            <Typography variant="subtitle1" fontWeight="bold">
              Discount:
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {planInfo.discount.value}% Off
            </Typography>
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 6 }}>
            <Typography variant="subtitle1" fontWeight="bold">
              Subscription Period:
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {formatDate(newSubscription.startDate)} -{" "}
              {formatDate(newSubscription.endDate)}
            </Typography>
          </Grid2>
        </Grid2>

        <Divider sx={{ my: 3 }} />

        {/* Features Section */}
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Plan Features
        </Typography>
        <Grid2 container spacing={2}>
          {Object.entries(planInfo.features).map(([feature, value]) => (
            <Grid2 size={{ xs: 12, sm: 6 }} key={feature}>
              <Typography variant="body2" color="text.secondary">
                <strong>{feature.replace(/([A-Z])/g, " $1")}:</strong>{" "}
                {typeof value === "boolean" ? (value ? "✔️ Yes" : "❌ No") : value}
              </Typography>
            </Grid2>
          ))}
        </Grid2>

        <Divider sx={{ my: 3 }} />

        {/* Payment Details */}
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Payment Details
        </Typography>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 4 }}>
            <Typography variant="subtitle1" fontWeight="bold">
              Amount:
            </Typography>
            <Typography variant="body2" color="text.secondary">
              ₹{razorpayOrder.amount / 100}
            </Typography>
          </Grid2>

          <Grid2 size={{ xs: 4 }}>
            <Typography variant="subtitle1" fontWeight="bold">
              Amount Paid:
            </Typography>
            <Typography variant="body2" color="text.secondary">
              ₹{razorpayOrder.amount_paid / 100}
            </Typography>
          </Grid2>

          <Grid2 size={{ xs: 4 }}>
            <Typography variant="subtitle1" fontWeight="bold">
              Amount Due:
            </Typography>
            <Typography variant="body2" color="text.secondary">
              ₹{razorpayOrder.amount_due / 100}
            </Typography>
          </Grid2>
        </Grid2>

        <Divider sx={{ my: 3 }} />

        {/* Actions */}
        <Box display="flex" justifyContent="space-between">
          {razorpayOrder.status === "created" && (
            <Button
              variant="contained"
              color="success"
              fullWidth
              onClick={handlePayment}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : "Pay Now"}
            </Button>
          )}
        </Box>
      </CustomCard>
    </PageContainer>
  );
};

export default PaymentConfirmation;
