import React, { useState } from "react";
import { Badge, Box, Fab, Typography, useTheme } from "@mui/material";
import QuerySection from "./Query";
import DataListSection from "./ListData";
import usePagination from "../../../hooks/usePagination";
import { ArrowDownward } from "@mui/icons-material";
import NoRecordsFound from "../../NoRecordFound";

const QueryListContainer = ({ fetchData, defaultFilter, header, getItems, sortOptions, filters, noDataFound }) => {
  const theme = useTheme();
  const [isRefetching, setIsRefetching] = useState(false);
  const [isFilterChanged, setIsFilterChanged] = useState(false);

  const defaultQuery = {
    page: 1,
    limit: 10,
    search: "",
    sortKey: sortOptions[0].key || "createdAt",
    sortOrder: "DESC",
    ...Object.keys(filters).reduce((acc, filterKey) => {
      acc[filterKey] = filters[filterKey][0].value;
      return acc;
    }, {}),
    ...defaultFilter
  };

  const { data, loadMore, refetch, hasMore, loading, query, setQuery, totalItems } = usePagination(fetchData, defaultQuery);
  const remainingItems = totalItems - data.length;

  const handleRefetch = async () => {
    setIsRefetching(true);
    await refetch();
    setIsRefetching(false);
  };

  const items = getItems(data, handleRefetch).filter(item => item);

  const onRetry = () => {
    isFilterChanged ? setQuery(defaultQuery) : refetch();
  };

  const handleQueryChange = (newQuery) => {
    setQuery({ ...query, ...newQuery });
    setIsFilterChanged(true);
  };

  const isLoading = loading && !isRefetching && query.page === 1;

  return (
    <>
      <QuerySection
        query={query}
        onQueryChange={handleQueryChange}
        onReset={onRetry}
        sortOptions={sortOptions}
        filters={filters}
      />

      <DataListSection
        items={items}
        header={header}
        isLoading={isLoading}
        hasMore={hasMore}
        loadMore={loadMore}
      />

      {
        !items.length && !isLoading && <NoRecordsFound
          Icon={noDataFound.Icon}
          ButtonIcon={noDataFound.ButtonIcon}
          buttonText={noDataFound.buttonText}
          onRefresh={noDataFound.onRefresh || refetch}
          subTitle={noDataFound.subTitle}
          title={noDataFound.title}
        />
      }

      {!hasMore && items.length > 0 && (
        <Box textAlign="center" padding={2}>
          <Typography variant="body2" color="textSecondary">
            No more data to load
          </Typography>
        </Box>
      )}

      <Fab
        color="primary"
        size="small"
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          bgcolor: theme.palette.primary.main,
          boxShadow: theme.shadows[4],
        }}
        onClick={loadMore}
      >
        <Badge badgeContent={remainingItems} color="error">
          <ArrowDownward />
        </Badge>
      </Fab>
    </>
  );
};

export default QueryListContainer;
