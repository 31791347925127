import React, { useState, useRef, useEffect } from "react";
import { Button, Grid2, Typography } from "@mui/material";
import PageContainer from "../components/Containers/PageContainer";
import { useParams } from "react-router-dom";
import CustomMap from "../components/Location/Map";
import AddLocationForm from "../components/Location/AddLocationForm";
import AddressSearchBox from "../components/Location/AddressSearchBox";
import config from "../config/config";
import { locationsApi } from "../services/api";
import { useQuery } from "react-query";
import reduxData from "../utils/useReduxData";
import LibraryDetails from "../components/Setting/LibraryDetails";

const googleApiKey = config.googleApiKey;

const AddEditLocation = () => {
  const { locationId } = useParams();

  const [selectedLocation, setSelectedLocation] = useState({ lat: 26.271820411485425, lng: 72.99735808262858 });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [locationData, setLocationData] = useState({});
  const [locationFound, setLocationFound] = useState(true);
  const [locationIdForSetting, setLocationIdForSetting] = useState(locationId);

  const searchBoxRef = useRef(null);
  const mapRef = useRef(null);

  // React Query to fetch locations
  useQuery(
    ["locations-details", locationId],
    async () => await locationsApi().getById(locationId),
    {
      retry: false, // Disable retries on error
      enabled: !!locationId,
      select: (data) => ({
        name: data.name,
        address: data.address,
        city: data.city,
        state: data.state,
        postalCode: data.postalCode,
        country: data.country,
        contactNumber: data.contactNumber,
        coordinates: { lat: data.lat, lng: data.lng },
      }),
      onSuccess: (transformedData) => {
        const { coordinates, ...other } = transformedData;
        setLocationData(other);
        setSelectedLocation(coordinates);
      },
    }
  );


  const handleSetLocation = ({ formattedAddress, city, state, postalCode, country }) => {
    // Normalize other address parts for comparison
    const addressOtherParts = [city, state, postalCode, country].filter((part) => part).map((part) => part.toLowerCase());

    // Safely split and filter formatted address
    const address = formattedAddress
      .split(",")
      .map((part) => part?.trim().toLowerCase()) // Normalize parts
      .filter((part) => {
        if (!part) return false; // Skip empty parts

        // Check if part is included in normalized other address parts
        const isPartExists = addressOtherParts.includes(part);

        // Check if all sub-parts of `part` exist in normalized other address parts
        const areAllSubPartsExist = part
          .split(" ")
          .every((subPart) => addressOtherParts.includes(subPart));

        // Exclude the part if it's found in `addressOtherParts` or its sub-parts match
        return !(isPartExists || areAllSubPartsExist);
      })
      .join(", "); // Join filtered parts back with a comma and space

    setLocationData((prev) => ({
      ...prev,
      address: address || "",
      city: city || "",
      state: state || "",
      postalCode: postalCode || "",
      country: country || "",
    }));
  };

  // Fetch address details using Google Maps Geocoding API
  const fetchAddressDetails = async (lat, lng) => {
    try {
      // ✅ First Attempt: Google Geocoding API
      let response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${googleApiKey}`);
      let data = await response.json();

      if (data.status === "OK" && data.results?.length > 0) {
        const addressComponents = data.results[0]?.address_components || [];
        const formattedAddress = data.results[0]?.formatted_address || "";

        const city = addressComponents.find((c) => c.types.includes("administrative_area_level_3"))?.long_name || "";
        const state = addressComponents.find((c) => c.types.includes("administrative_area_level_1"))?.long_name || "";
        const postalCode = addressComponents.find((c) => c.types.includes("postal_code"))?.long_name || "";
        const country = addressComponents.find((c) => c.types.includes("country"))?.long_name || "";

        handleSetLocation({ formattedAddress, city, state, postalCode, country });
        return;
      }
    } catch (error) {
      console.error("Google Geocoding API Error:", error);
    }

    // ✅ Second Attempt: OpenStreetMap (Fallback)
    try {
      let osmResponse = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`);
      let osmData = await osmResponse.json();

      if (osmData.address) {
        const formattedAddress = osmData.display_name || "";
        const city = osmData.address.city || osmData.address.town || osmData.address.village || "";
        const state = osmData.address.state || "";
        const postalCode = osmData.address.postcode || "";
        const country = osmData.address.country || "";

        handleSetLocation({ formattedAddress, city, state, postalCode, country });
        return;
      }
    } catch (error) {
      console.error("OpenStreetMap API Error:", error);
    }

    console.error("❌ Both APIs failed to fetch location data.");
  };

  // Handle map click to set selected location
  const handleMapClick = (event) => {
    const newLocation = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setSelectedLocation(newLocation);
    fetchAddressDetails(newLocation.lat, newLocation.lng);

    if (mapRef.current) {
      mapRef.current.panTo(newLocation);
    }
  };

  // Handle search box place selection
  const handlePlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places && places.length > 0) {
      const place = places[0];
      const location = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      setSelectedLocation(location);
      const addressComponents = places[0].address_components;
      if (!addressComponents) {
        setLocationFound(false);
        return;
      }
      setLocationFound(true);

      const formattedAddress = places[0].name;
      const city = addressComponents.find((c) => c.types.includes("locality"))?.long_name;
      const state = addressComponents.find((c) => c.types.includes("administrative_area_level_1"))?.long_name;
      const postalCode = addressComponents.find((c) => c.types.includes("postal_code"))?.long_name;
      const country = addressComponents.find((c) => c.types.includes("country"))?.long_name;

      handleSetLocation({ formattedAddress, city, state, postalCode, country });

      if (mapRef.current) {
        mapRef.current.panTo(location);
      }
    }
  };

  useEffect(() => {
    if (searchBoxRef.current) {
      searchBoxRef.current.addListener("places_changed", handlePlacesChanged);
    }
    // eslint-disable-next-line
  }, [searchBoxRef.current]);

  const handleFormSubmit = async (values) => {
    setIsSubmitting(true);
    if (locationId) {
      await locationsApi().update(locationId, { ...values, ...selectedLocation });
    } else {
      const newLocation = await locationsApi().create({ ...values, ...selectedLocation });
      reduxData("locations", "add")(newLocation);

      setLocationIdForSetting(newLocation._id);
    }
    setIsSubmitting(false);
  };

  const handleDataChange = (key) => (e) => {
    setLocationData((prev) => ({ ...prev, [key]: e.target.value }));
  };

  return (
    <PageContainer>
      <Grid2 container spacing={2} sx={{ mb: 2 }}>
        <Grid2 size={{ xs: 12, md: 6 }} order={{ xs: 3, md: 2 }}>
          <AddressSearchBox
            onLoad={(ref) => (searchBoxRef.current = ref)}
            value={locationData.address}
            onChange={(e) =>
              setLocationData((prev) => ({
                ...prev,
                address: e.target.value, // Allow user to type into the field
              }))
            }
            sx={{ marginBottom: 2 }}
          />
          <AddLocationForm
            initialValues={locationData}
            handleLocationNameChange={handleDataChange("name")}
            handleLocationContactChange={handleDataChange("contactNumber")}
            onSubmit={handleFormSubmit} // Pass the form submit handler
            locationFound={locationFound}
          />
        </Grid2>

        {/* Map */}
        <Grid2 size={{ xs: 12, md: 6 }} order={{ xs: 1, md: 3 }}>
          <CustomMap
            location={selectedLocation}
            onClick={handleMapClick}
            onLoad={(map) => (mapRef.current = map)}
          />
        </Grid2>
      </Grid2>
      <Button
        type="button"
        variant="contained"
        size="small"
        color="primary"
        onClick={() => document.querySelector("form").dispatchEvent(new Event("submit", { bubbles: true }))} // Trigger Formik submit
        disabled={isSubmitting}
        sx={{ display: "block", mx: "auto", mt: 2 }}
      >
        {isSubmitting ? (locationId ? "Saving Location..." : "Adding Location...") : locationId ? "Save Location" : "Add Location"}
      </Button>

      {
        locationIdForSetting
          ? (
            <>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Online Setting
              </Typography>
              <LibraryDetails locationId={locationIdForSetting} />
            </>
          )
          : (<></>)
      }
    </PageContainer>
  );
};

export default React.memo(AddEditLocation);
