import React, { useState } from "react";
import { Avatar, Typography, Button, CircularProgress, Tooltip } from "@mui/material";
import formattedContactNumber from "../../utils/formateContactNumber";
import { RowBuilder } from "../Containers/QueryContainer/RowBuilder";
import { DropDownList } from "../Common/DropDownList";

const StudentItemForReservation = ({ student, floors, header, availableSeats, onReserve }) => {
  const { _id, name, email, contactNumber, image, subscription } = student;

  const [subscriptionType, setSubscriptionType] = useState("flexible");
  const [selectedFloor, setSelectedFloor] = useState("");
  const [selectedSeat, setSelectedSeat] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const posibleNewSubscriptions = [];
  const fixed = { value: "fixed", label: "Fixed" };
  const flexible = { value: "flexible", label: "Flexible" };

  if (subscription && subscription.status === 'active') {
    if (subscription.type === "fixed") {
      return null;
    } else {
      posibleNewSubscriptions.push(flexible);
    }
  } else {
    posibleNewSubscriptions.push(flexible);
    posibleNewSubscriptions.push(fixed);
  }

  const handleFloorChange = (floorId) => {
    setSelectedFloor(floorId);
    setSelectedSeat(""); // Reset seat when floor changes
  };

  const handleSeatChange = (seatId) => {
    setSelectedSeat(seatId);
  };

  // Handle dropdown changes
  const handleSubscriptionTypeChange = (type) => {
    setSubscriptionType(type);
    if (type === "flexible") {
      setSelectedFloor(null); // Reset floor selection
      setSelectedSeat(null); // Reset seat selection
    }
  };

  const handleBookSeat = async () => {
    if (subscriptionType === "fixed" && (!selectedFloor || !selectedSeat)) return;

    setIsSubmitting(true);
    await onReserve(_id, subscriptionType, selectedFloor, selectedSeat);
    setIsSubmitting(false);
  };

  // Button disable condition
  const disableTheButton = isSubmitting || (subscriptionType === "fixed" && (!selectedFloor || !selectedSeat));

  return <RowBuilder
    header={header}
    columns={{
      image: {
        primary: <Avatar src={image || ""} alt={name} sx={{ width: 60, height: 60 }} />,
      },
      name: {
        primary: (
          <Tooltip title={email}>
            <Typography variant="body1" noWrap>
              {name}
            </Typography>
          </Tooltip>
        ),
        secondary: <Typography variant="body2" color="textSecondary" noWrap>{formattedContactNumber(contactNumber)}</Typography>,
      },
      type: {
        primary: (
          <DropDownList
            label="Subscription Type"
            value={subscriptionType}
            onChange={handleSubscriptionTypeChange}
            items={posibleNewSubscriptions}
          />
        ),
      },
      floor: {
        primary: (
          <DropDownList
            label="Floor"
            value={selectedFloor}
            disabled={subscriptionType !== "fixed"}
            onChange={handleFloorChange}
            items={
              floors
                .filter((floor) => availableSeats[floor._id].length)
                .map((floor) => ({ value: floor._id, label: floor.name }))
            }
          />
        ),
      },
      seat: {
        primary: (
          <DropDownList
            label="Seat"
            value={selectedSeat}
            disabled={!selectedFloor}
            onChange={handleSeatChange}
            items={availableSeats[selectedFloor]?.map((seat) => ({ value: seat._id, label: seat.number })) || []}
          />
        ),
      },
      action: {
        primary: (
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={handleBookSeat}
            sx={{ textTransform: "capitalize", boxShadow: 2 }}
            disabled={disableTheButton}
          >
            {isSubmitting && <CircularProgress size={20} sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />}
            Reserve
          </Button>
        ),
      },
    }}
  />
};

export default StudentItemForReservation;