import React, { useState } from "react";
import { Avatar, Typography, Button, CircularProgress, Chip, Tooltip, Box } from "@mui/material";
import { RowBuilder } from "../Containers/QueryContainer/RowBuilder";
import formattedContactNumber from "../../utils/formateContactNumber";
import { DropDownList } from "../Common/DropDownList";
import { FloorSeat } from "../Common/FloorSeat";

const StudentItemForAttendance = ({ student, header, floors, availableSeats, onSeatBooking, onCheckIn }) => {
  const mySeats = Object.keys(availableSeats).reduce((obj, floorId) => {
    obj[floorId] = [...availableSeats[floorId]];
    return obj;
  }, {});

  const { _id, name, email, contactNumber, image, subscription, booking } = student;
  const previousFloor = subscription?.floorId || booking?.floorId || "";
  const previousSeat = subscription?.seatId || booking?.seatId || "";

  const [selectedFloor, setSelectedFloor] = useState(previousFloor);
  const [selectedSeat, setSelectedSeat] = useState(previousSeat);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isActive = subscription?.status === "active";
  const isReserved = subscription?.type === "fixed" && isActive;
  const isSeatAlreadyBooked = booking?.status === "pending";
  const isCheckedIn = booking?.status === "running";
  const disableDropdowns = (isReserved || isSeatAlreadyBooked);

  const disableTheButton = isSubmitting || (
    !(isReserved || isSeatAlreadyBooked) && (!selectedFloor || !selectedSeat)
  );

  let status = "";
  let statusColor = "";

  if (subscription && isActive) {
    status = "Subscribed";
    statusColor = "success";
  } else {
    status = "Guest";
    statusColor = "warning";
  }

  if (isReserved) {
    status = "Reserved";
    statusColor = "primary";
  } else if (isSeatAlreadyBooked) {
    status = "Booked";
    statusColor = "secondary";
  }

  if (isCheckedIn) {
    return null;
  }

  if (isReserved) {
    mySeats[selectedFloor]?.push({
      _id: subscription.seatId,
      number: subscription.seatNumber
    });
  } else if (isSeatAlreadyBooked) {
    mySeats[selectedFloor]?.push({
      _id: booking.seatId,
      number: booking.seatNumber
    });
  }

  const handleFloorChange = (floorId) => {
    setSelectedFloor(floorId);
    setSelectedSeat(""); // Reset seat when floor changes
  };

  const handleSeatChange = (seatId) => {
    setSelectedSeat(seatId);
  };

  const handleBookSeat = async () => {
    setIsSubmitting(true);
    if (isSeatAlreadyBooked) {
      await onCheckIn(_id);
    } else {

      if (!selectedFloor || !selectedSeat) {
      } else {
        await onSeatBooking(_id, selectedFloor, selectedSeat);
      }
    }

    setIsSubmitting(false);
  };

  return (
    <RowBuilder
      header={header}
      columns={{
        image: {
          primary: <Avatar src={image || ""} alt={name} sx={{ width: 60, height: 60 }} />,
        },
        name: {
          primary: (
            <Tooltip title={email}>
              <Typography variant="body1" noWrap>
                {name}
              </Typography>
            </Tooltip>
          ),
          secondary: <Typography variant="body2" color="textSecondary" noWrap>{formattedContactNumber(contactNumber)}</Typography>,
        },
        type: {
          primary: (
            <Chip
              label={status}
              color={statusColor}
              size="small"
              sx={{ width: "70%", color: "white" }}
            />
          ),
          secondary: isReserved || isSeatAlreadyBooked
            ? <FloorSeat
              floorName={isReserved ? subscription.floorName : booking.floorName}
              seatNumber={isReserved ? subscription.seatNumber : booking.seatNumber} />
            : null,
        },
        floor: {
          primary: (
            <DropDownList
              label="Floor"
              value={selectedFloor}
              onChange={handleFloorChange}
              disabled={disableDropdowns}
              items={
                floors
                  .filter((floor) => !!mySeats[floor._id]?.length)
                  .map((floor) => ({ value: floor._id, label: floor.name }))
              }
            />
          ),
        },
        seat: {
          primary: (
            <DropDownList
              label="Seat"
              value={selectedSeat}
              disabled={!selectedFloor || disableDropdowns}
              onChange={handleSeatChange}
              items={mySeats[selectedFloor]?.map((seat) => ({ value: seat._id, label: seat.number })) || []}
            />
          ),
        },
        action: {
          primary: (
            <Button
              variant="contained"
              size="small"
              color={(isSeatAlreadyBooked || isReserved ? "success" : "primary")}
              onClick={handleBookSeat}
              sx={{ textTransform: "capitalize", boxShadow: 2 }}
              disabled={disableTheButton}
            >
              {isSubmitting && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <CircularProgress size={20} />
                </Box>
              )}

              {(isSeatAlreadyBooked || isReserved ? "Check In" : "Assign")}
            </Button>
          ),
        },
      }}

    />
  );
};

export default StudentItemForAttendance;
