import React, { useRef } from "react";
import { Box } from "@mui/material";
import Breadcrumbs from "./Breadcrumb";
import constents from "../../../config/constents";
import InitialSetupModal from "../../Models/initialSetup";
import PermissionDenied from "../../Models/PermissionDenied";
import UpgradePlan from "../../Models/UpgradePlan";
import ExtraAssets from "../../Models/ExtraAssets";

const MainContent = ({ children, headerContent = null }) => {
  const { pageContainerHeight, pageContainerWidht, headerHight } = constents();
  const contentRef = useRef();

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        mt: `${headerHight}px`, // Offset for header height
        height: pageContainerHeight, // Fixed height
        width: pageContainerWidht, // Fixed width
        display: "flex",
        flexDirection: "column",
        overflow: "hidden", // Prevent container resizing
        position: "relative", // Required for the toggle button placement
      }}
    >
      {/* Breadcrumb Section */}
      <Box
        sx={{
          px: { xs: 2, sm: 4 },
          pt: 2,
          display: "flex",
          justifyContent: "space-between",
          flexShrink: 0, // Prevent resizing
        }}
      >
        <Breadcrumbs />
        <Box sx={{ display: "flex" }}>
          {headerContent}
        </Box>
      </Box>

      {/* Main Content Section */}
      <Box
        ref={contentRef}
        elevation={3}
        sx={{
          mx: { xs: 0, sm: 2 },
          mt: 1,
          // px: { xs: 2, sm: 0 },
          height: pageContainerHeight, // Adjusted height
          flexGrow: 1,
          overflowY: "auto",
          overflowX: "auto",
          position: "relative",
          padding: 2
        }}
      >
        {children}
      </Box>

      <InitialSetupModal />
      <PermissionDenied />
      <UpgradePlan />
      <ExtraAssets />
    </Box>
  );
};

export default MainContent;
