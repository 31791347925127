import React, { useState, useCallback, useMemo } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Badge, useMediaQuery, Box, Menu, MenuItem, Drawer, Button } from '@mui/material';
import { AccountCircle, Menu as MenuIcon, ArrowDropDown } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import reduxData from '../../../utils/useReduxData';
import { checkLocation } from '../../../utils/location';
import { useNavigate } from 'react-router-dom';
import logoIcon from "../../../assets/icons/APP ICON.svg";
import charA from "../../../assets/icons/A.svg";
import constents from '../../../config/constents';

const TopBar = ({ toggleDrawer }) => {
  const navigate = useNavigate(); // React Router's navigation hook
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md')); // Check for mobile view
  const { headerHight } = constents();

  // Redux selectors
  const library = useSelector((state) => state.library);
  const locations = useSelector((state) => state.locations);
  const currentLocation = useSelector((state) => state.currentLocation);

  // Memoize library name
  const libraryName = useMemo(() => library?.name || 'Library', [library?.name])?.toUpperCase();
  const libraryNameFontSize = Math.min(25, (300 / libraryName.length));

  const showLibraryName = () => {
    if (libraryName.includes('A')) {
      const splitedName = libraryName.split('');
      const svgSize = libraryNameFontSize - (libraryNameFontSize / 3.8);

      return splitedName.map((char, index) => {
        let item = char;

        if (char === "A") {
          item = <Box key={Math.random()} component="img" src={charA} alt="A" sx={{ width: { xs: svgSize, sm: 27 } }} />
        } else if (char === " ") {
          item = <Box key={Math.random()} sx={{ display: "inline-block", width: `${svgSize}px` }}> {" "} </Box>
        }

        return item;
      });
    } else {
      return libraryName;
    }
  };

  // State for badge content and dropdown menu
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false); // State for mobile drawer
  const open = Boolean(anchorEl);

  // Memoize badge content based on invalid locations
  const badgeContent = useMemo(() => {
    return locations.reduce((count, location) => {
      const { isLocationValid } = checkLocation(location);
      return isLocationValid ? count : count + 1;
    }, 0);
  }, [locations]);

  // Handlers
  const handleMenuOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLocationSelect = useCallback(
    (location) => async () => {
      setAnchorEl(null); // Close the menu

      if (location._id !== currentLocation?._id) {
        reduxData('currentLocation', 'set')({ _id: location._id, name: location.name });
        setTimeout(() => {
          navigate(0); // Smooth reload of the current page
        }, 200);
      }
    },
    [currentLocation, navigate]
  );

  const toggleLocationDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        bgcolor: 'primary.main', // Using theme's primary color
      }}
    >
      <Toolbar sx={{ minHeight: { xs: 50, sm: 50 } }}>
        {isMobile && (
          <IconButton color="inherit" onClick={toggleDrawer} sx={{ marginRight: 2 }}>
            <Badge badgeContent={badgeContent} color='error'>
              <MenuIcon />
            </Badge>
          </IconButton>
        )}

        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: { xs: "center", md: "start" },
          }}
        >
          <Box component="img" src={logoIcon} sx={{ width: { xs: 25, sm: 35 }, marginRight: 1 }} />
          <Typography
            variant="caption"
            noWrap
            sx={{
              width: "auto",
              minWidth: "50%",
              maxWidth: "400px",
              fontSize: { xs: libraryNameFontSize, sm: 35 },
              lineHeight: 1,
              textTransform: "uppercase",
              display: "flex",
              alignItems: "center",
            }}
          >
            {showLibraryName()}
          </Typography>

          {/* Desktop Location Dropdown */}
          {!isMobile && (
            <Box display="flex" alignItems="center" marginLeft={2} width="100%" justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={handleMenuOpen}
                sx={{ borderRadius: '20px', padding: '6px 12px', marginRight: 1 }}
              >
                {currentLocation?.name || "Select Location"}
                <ArrowDropDown />
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                MenuListProps={{
                  'aria-labelledby': 'locations-menu',
                }}
              >
                {locations.map((location) => (
                  <MenuItem
                    key={location._id}
                    onClick={handleLocationSelect(location)}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    {location.name}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </Box>

        {/* Mobile Account Circle Icon */}
        <IconButton color="inherit" onClick={toggleLocationDrawer}>
          <AccountCircle />
        </IconButton>

        {/* Mobile Drawer for Library and Location */}
        <Drawer anchor="right" open={drawerOpen && isMobile} onClose={toggleLocationDrawer}>
          <Box
            // role="presentation"
            onClick={toggleLocationDrawer}
            onKeyDown={toggleLocationDrawer}
            sx={{
              width: { xs: 200 },
              mt: `${headerHight}px`,
            }}
          >
            <Typography variant="subtitle1" sx={{ padding: 1 }}>
              Locations:
            </Typography>
            {locations.map((location) => (
              <Button
                key={location._id}
                onClick={handleLocationSelect(location)}
                sx={{
                  width: '100%',
                  justifyContent: 'flex-start',
                  backgroundColor: currentLocation?._id === location._id ? 'lightgray' : 'transparent',
                  borderRadius: '20px',
                  padding: '6px 12px',
                  margin: '4px 0',
                  '&:hover': {
                    backgroundColor: 'lightgray',
                  },
                }}
              >
                {location.name}
              </Button>
            ))}
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;

