import React, { useRef } from "react";

const InfiniteScroll = ({ children, loadMore, hasMore, loader }) => {
  const observer = useRef();
  const scrollContainerRef = useRef();

  const lastElementRef = (node) => {
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        const { scrollHeight, scrollTop } = scrollContainerRef.current;
        loadMore();
        requestAnimationFrame(() => {
          if (scrollContainerRef.current?.scrollTop) {
            scrollContainerRef.current.scrollTop = scrollTop + (scrollContainerRef.current?.scrollHeight - scrollHeight);
          }
        });
      }
    });

    if (node) observer.current.observe(node);
  };

  return (
    <div ref={scrollContainerRef}>
      {children}
      {hasMore && <div ref={lastElementRef}>{loader}</div>}
    </div>
  );
};

export default InfiniteScroll;
