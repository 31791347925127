import React, { useState } from "react";
import PageContainer from "../components/Containers/PageContainer";
import QueryListContainer from "../components/Containers/QueryContainer/Main";
import LocationReviewSummary from "../components/Location/ReviewSummary";
import LocationReviewSummarySkeleton from "../components/Location/ReviewSummarySkeleton";
import { reviewApi } from "../services/api";
import LocationReviewRow from "../components/Location/ReviewRow";
import { useLocation } from "react-router-dom";

const LocationReviews = () => {
  const location = useLocation();
  const [ratingSummary, setRatingSummary] = useState({});
  const [isLoading, setLoading] = useState(true);

  const locationId = location.state?.locationId || "";

  const { getAll } = reviewApi();

  const sortOptions = [
    { key: "createdAt", label: "Created At" },
  ];

  const filters = {
    rating: [
      { value: "", label: "All" },
      { value: 1, label: "1" },
      { value: 2, label: "2" },
      { value: 3, label: "3" },
      { value: 4, label: "4" },
      { value: 5, label: "5" },
    ],
  };

  const header = [
    { size: 1, align: "start", key: "image", label: "Image" },
    { size: 2, align: "start", key: "name", label: "Name" },
    { size: 9, align: "start", key: "ratingAndReview", label: "Rating And Review" },
  ];

  return (
    <PageContainer>

      {
        isLoading
          ? <LocationReviewSummarySkeleton />
          : <LocationReviewSummary ratingSummary={ratingSummary} />
      }

      <QueryListContainer
        fetchData={async (query) => {
          const res = await getAll({ ...query, locationId, type: "location" });
          setRatingSummary(res.summary);
          setLoading(false);

          return res;
        }}
        filters={filters}
        sortOptions={sortOptions}
        header={header}
        getItems={(reviews, refetch) => {
          return reviews.map((review, index) => {

            return <LocationReviewRow
              review={review}
              header={header}
            />
          })
        }}
        noDataFound={{
          title: 'No Student Reviews Found',
        }}
      />
    </PageContainer>
  );
};

export default LocationReviews;
