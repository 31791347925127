import React from "react";
import { Box, Tooltip, Rating } from "@mui/material";

const RatingStar = ({ rating, onClick = null }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Tooltip title={`Rating: ${rating}`} arrow>
      <Box
        sx={{
          cursor: onClick ? "pointer" : "default",
          transition: "transform 0.2s ease-in-out",
          "&:hover": { transform: "scale(1.1)" },
        }}
        onClick={handleClick}
      >
        <Rating value={Number(rating)} precision={0.1} readOnly />
      </Box>
    </Tooltip>
  );
};

export default RatingStar;
