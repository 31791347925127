import config from "../config/config";
import reduxData from "./useReduxData";
import { toast } from "react-toastify";

export const clearStore = (message = "") => {
    const slicesArray = config.slices;

    for (let slice of slicesArray) {
        reduxData(slice.name, "clear");
    }

    if (message) {
        toast.error(message + " Please Refresh the Page.");
        setTimeout(() => {
            window.location.href = "/";
        }, 5000);
    }
};