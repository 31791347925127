import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import PageContainer from "../components/Containers/PageContainer";
import { planApi } from "../services/api";
import { useQuery } from "react-query";
import PricingTable from "../components/Plan/PricingTable";
import DurationToggle from "../components/Plan/DurationToggle";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import reduxData from "../utils/useReduxData";

const Pricing = () => {
  const navigate = useNavigate();
  const { changePlan, getAll } = planApi();
  const [selectedDuration, setSelectedDuration] = useState({ value: 0, unit: "" });
  const [paidPlans, setPaidPlans] = useState([]);
  const [freePlans, setFreePlans] = useState({});

  const [plans, setPlans] = useState([]);
  const [durationAndDiscounts, setDurationAndDiscounts] = useState([]);
  const [featuresKeyName, setFeaturesKeyName] = useState([]);

  useQuery(
    ["plans"],
    async () => await getAll(),
    {
      retry: false, // Disable retries on error
      onSuccess: ({ dbPlans, plans, durationAndDiscounts, featuresKeyName }) => {
        setPlans(plans);
        setDurationAndDiscounts(durationAndDiscounts);
        setFeaturesKeyName(featuresKeyName);

        setSelectedDuration(durationAndDiscounts[0].duration);
        setPaidPlans(dbPlans.filter(plan => plan.key !== "free"));
        setFreePlans(dbPlans.find(plan => plan.key === "free"));
      }
    }
  );

  if (plans && !plans?.length) {
    return;
  }

  const getPlans = () => {
    const filter = paidPlans
      .sort((a, b) => a.finalPrice - b.finalPrice)
      .filter(p => selectedDuration.value === p.duration.value && selectedDuration.unit === p.duration.unit);

    return [freePlans, ...filter];
  }

  const handleChangePlan = async (planId) => {
    try {
      const res = await changePlan(planId);

      if (res.hasExtraAssets) {
        reduxData("extraAssets", "set")(res.extraAssets);
      } else if (res.razorpayOrder) {
        navigate("confirm-payment", {
          state: {
            ...res,
          }
        });
      } else {
        navigate("/settings", {
          state: { tabNumber: 3 }
        });
      }
    } catch (error) {
      console.error("🚀 ~ handleChangePlan ~ error:", error)
    }
  }

  const goBack = (
    <Button
      variant="outlined"
      size="small"
      color="primary"
      onClick={() => navigate(-1)}
      startIcon={<ArrowBack />}
    >
      Back
    </Button>
  );

  return (
    <PageContainer headerContent={goBack}>
      <Typography variant="h4" fontWeight="bold">Simple, transparent pricing</Typography>
      <Typography variant="subtitle1" color="gray">No contracts. No surprise fees.</Typography>

      <DurationToggle
        selectedDuration={selectedDuration}
        setSelectedDuration={setSelectedDuration}
        durationAndDiscounts={durationAndDiscounts}
      />
      <Box height={40} />

      <PricingTable plans={getPlans()} featuresKeyName={featuresKeyName} onChange={handleChangePlan} />

    </PageContainer>
  );
};

export default Pricing;
