import React from "react";
import { Typography, Chip, Stack, Grid2, useTheme } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import * as moment from "moment";
import CustomCard from "../Containers/Card";
import { Apartment, Chair } from "@mui/icons-material";

const StudentBookingCard = ({ booking }) => {
  const theme = useTheme(); // Access the current theme

  const bookingDate = moment(booking.bookedAt).format("MMM DD, YYYY");
  const checkInTime = booking.checkedInAt
    ? moment(booking.checkedInAt).format("hh:mm A")
    : "Coming Soon";
  const checkOutTime = booking.checkedOutAt
    ? moment(booking.checkedOutAt).format("hh:mm A")
    : booking.checkedInAt
      ? "Still in Library"
      : "Coming Soon";

  const statusColor =
    booking.status === "completed"
      ? "success.main"
      : booking.status === "in-progress"
        ? "warning.main"
        : "error.main";

  return (
    <CustomCard showAsList>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 6, sm: 3, md: 2 }} order={{ xs: 1, sm: 1 }}>
          <Typography variant="subtitle2" fontWeight="bold" color="text.primary">
            {bookingDate}
          </Typography>
          <Chip
            label={booking.status}
            size="small"
            sx={{
              bgcolor: statusColor,
              color: "white",
              textTransform: "capitalize",
              fontWeight: "bold",
              mt: 1,
            }}
          />
        </Grid2>

        <Grid2 size={{ xs: 12, sm: 6, md: 8 }} order={{ xs: 3, sm: 2 }}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ mb: 1 }}
          >
            <AccessTimeIcon color="primary" fontSize="small" />
            <Typography variant="body2" fontWeight="500" color="text.secondary">
              Check-In:
            </Typography>
            <Typography variant="body2" fontWeight="500" color="text.primary">
              {checkInTime}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ mt: 0.5 }}
          >
            <AccessTimeIcon color="error" fontSize="small" />
            <Typography variant="body2" fontWeight="500" color="text.secondary">
              Check-Out:
            </Typography>
            <Typography variant="body2" fontWeight="500" color="text.primary">
              {checkOutTime}
            </Typography>
          </Stack>
        </Grid2>

        <Grid2 size={{ xs: 6, sm: 3, md: 2 }} order={{ xs: 2, sm: 3 }}>
          <Stack direction="column" sx={{ textAlign: "end", display: "flex", alignItems: "end" }}>
            <Typography
              variant="body1" color="text.secondary"
              sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
            >
              <Apartment fontSize="small" sx={{ color: theme.palette.text.secondary }} />
              {booking.floorName || "Floor 1"}
            </Typography>

            <Typography
              variant="body1" color="text.secondary"
              sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
            >
              <Chair fontSize="small" sx={{ color: theme.palette.text.secondary }} />
              {booking.seatNumber}
            </Typography>
          </Stack>
        </Grid2>
      </Grid2>
    </CustomCard>
  );
};

export default StudentBookingCard;
