import React from "react";
import { Box, Skeleton, useTheme } from "@mui/material";
import CustomCard from "../Containers/Card";

const FloorCardSkeleton = () => {
  const theme = useTheme();

  return (
    <CustomCard sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: theme.spacing(2),
    }}>
      {/* Title Skeleton */}
      <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Skeleton variant="text" width="50%" height={24} />
        <Skeleton
          variant="circular"
          width={24}
          height={24}
          sx={{ marginLeft: theme.spacing(2) }}
        />
      </Box>

      {/* Floor Layout Image Skeleton */}
      <Skeleton
        variant="rectangular"
        width="50%"
        height={150}
        sx={{ borderRadius: theme.shape.borderRadius }}
      />

      {/* Action Buttons Skeleton */}
      <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
        <Skeleton variant="circular" width={40} height={40} />
        <Skeleton variant="circular" width={40} height={40} />
        <Skeleton variant="circular" width={40} height={40} />
      </Box>
    </CustomCard>
  );
};

export default FloorCardSkeleton;
