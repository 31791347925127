import React from "react";
import { Box } from "@mui/material";
import { useDraggable } from "@dnd-kit/core";
import TableWithNumber from "./TableWithNumber";

const RotatableTable = ({ item, tableNumber, onDragStart, cellSize }) => {
  const { attributes, listeners, setNodeRef } = useDraggable({ id: item.id });

  return (
    <Box
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      draggable
      onDragStart={onDragStart}
      sx={{
        cursor: "grab",
        position: "relative",
        transform: `rotate(${item.rotation}deg)`,
        width: cellSize,
        height: cellSize,
      }}
    >
      <TableWithNumber seatNumber={tableNumber} rotation={item.rotation} status={item.status} cellSize={cellSize} />
    </Box>
  );
};

export default RotatableTable;
