import myCustomSlices from "../redux/slices";
import { store } from "../redux/store";

const reduxData = (slice, method) => {
  const { set, add, updateObj, clear, remove } = myCustomSlices(slice);

  switch (method) {
    case "set": {
      return (data) => {
        store.dispatch(set(data));
      };
    }
    case "add": {
      return (data) => {
        store.dispatch(add(data));
      };
    }
    case "updateObj": {
      return (data) => {
        return store.dispatch(updateObj(data));
      };
    }
    case "remove": {
      return (key, value) => {
        return store.dispatch(remove({ key, value }));
      };
    }
    case "clear": {
      return store.dispatch(clear());
    }
    default: {
      throw new Error("Invalid method");
    }
  }
};

export default reduxData;
