import moment from "moment";
import { store } from "../redux/store";

export const checkTokensValidation = () => {
  const tokens = store.getState().tokens;

  const access = tokens && tokens.access ? tokens.access.token : null;
  const refresh = tokens && tokens.refresh ? tokens.refresh.token : null;

  let accessExpires = false;
  if (access) {
    accessExpires = moment(tokens.access.expires).toISOString() < moment().toISOString();
  }
  let refreshExpires = false;
  if (refresh) {
    refreshExpires = moment(tokens.refresh.expires).toISOString() < moment().toISOString();
  }
  // if it returns 1 -> requires, 0 -> not requires, -1 -> redirect to HOME page retry to login
  const isShouldRefresh = !refreshExpires ? (accessExpires ? 1 : 0) : -1;

  return {
    tokens,
    access,
    refresh,
    accessExpires,
    refreshExpires,
    isShouldRefresh,
    isLoggedIn: refresh && !refreshExpires,
  };
};