import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import PageContainer from "../components/Containers/PageContainer";
import AccountSettings from "../components/Setting/AccountSettings";
// import NotificationPreferences from "../components/Setting/NotificationPreferences";
// import SecuritySettings from "../components/Setting/SecuritySettings";
import BillingSettings from "../components/Setting/BillingSettings";
import UnderDevelopment from "../components/UnderDevelopment";
import { useLocation } from "react-router-dom";
import { hasPermission } from "../middleware/permission";
import { PERMISSION_CODE } from "../config/permissionCodes";

const SettingsPage = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (location.state?.tabNumber) {
      setCurrentTab(location.state.tabNumber);
    }
  }, [location]);

  const tabsItems = [
    { title: "Account Settings", component: <AccountSettings />, hasPermission: true },
    { title: "Notifications", component: <UnderDevelopment />, hasPermission: true },
    { title: "Security", component: <UnderDevelopment />, hasPermission: true },
    { title: "Billing", component: <BillingSettings />, hasPermission: hasPermission(PERMISSION_CODE.PLAN.VIEW_MY_SUBSCRIPTION) },
  ].filter(({ hasPermission }) => hasPermission);

  const renderTabContent = () => tabsItems.find((item, index) => index === currentTab)?.component || <>NO Tab Found</>;

  return (
    <PageContainer>
      <Tabs
        value={currentTab}
        onChange={(e, newValue) => setCurrentTab(newValue)}
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          "& .MuiTab-root": {
            textTransform: "none",
            fontSize: "0.9rem",
            "&.Mui-selected": {
              color: "primary.main",
            },
          },
          "& .MuiTab-root:hover": {
            color: "primary.main",
          },
        }}
      >
        {
          tabsItems.map(({ title }) => <Tab label={title} />)
        }
      </Tabs>
      <Box mt={3}>{renderTabContent()}</Box>
    </PageContainer>
  );
};

export default SettingsPage;
