import React, { useEffect, useState } from "react";
import { Box, Grid2, TextField, Button, Typography, Autocomplete, FormControlLabel, Switch, Tooltip, CircularProgress } from "@mui/material";
import TimeSelection from "./Library/Timings";
import CollapsableSection from "./Library/SectionContainer";
import { locationsApi } from "../../services/api";
import reduxData from "../../utils/useReduxData";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const defaultFeatures = [
  { title: "Wi-Fi Connectivity", code: 101 },
  { title: "Proper Lighting", code: 102 },
  { title: "Air Conditioning (AC)", code: 103 },
  { title: "Clean Washrooms", code: 104 },
  { title: "CCTV Security", code: 105 },
  { title: "Heaters", code: 106 },
  { title: "Ceiling Fans or Coolers", code: 107 },
  { title: "Drinking Water Facility (Water Purifier/Dispenser)", code: 108 },
  { title: "Lockers for Personal Belongings", code: 109 },
  { title: "Comfortable Furniture", code: 110 },
  { title: "Noise-Controlled Quiet Zones", code: 111 },
  { title: "Group Discussion Rooms", code: 112 },
  { title: "Parking Facility", code: 113 },
  { title: "Cafeteria or Snack Corner", code: 114 },
  { title: "Child-Friendly Zones", code: 115 },
  { title: "Printing/Scanning Services", code: 116 },
  { title: "Power Outlets/Charging Stations", code: 117 },
  { title: "Green Spaces or Garden Area", code: 118 },
  { title: "Emergency Medical Kit", code: 119 },
  { title: "Accessibility Features (Ramps, Elevators, Braille Signage)", code: 120 }
];

const LocationSettings = ({ locationId }) => {
  const navigate = useNavigate();
  const [timings, setTimings] = useState([]);
  const [features, setFeatures] = useState(defaultFeatures);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [seatPricing, setSeatPricing] = useState({ amount: "", unit: "hour" });
  const [libraryStatus, setLibraryStatus] = useState({ isActive: true, isOpen: true });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [lastFeatureCode, setLastFeatureCode] = useState(120);
  // const priceUnits = ["hour", "day", "week", "month"];

  const locations = useSelector((state) => state.locations);

  useEffect(() => {
    const currentLocationDetails = locations.find((location) => location._id === locationId);
    const setting = currentLocationDetails?.setting;
    const seatPrice = setting?.seatPricing?.find((price) => price.name === "individual");

    if (setting) {
      setTimings(setting.openCloseTiming);
      setLibraryStatus({ isActive: setting.isActive, isOpen: setting.isOpen });
      const newFeature = setting.features.filter(({ code }) => !features.map(({ code }) => code.toString()).includes(code));
      setFeatures([...features, ...newFeature]);
      setSelectedFeatures(setting.features);
      seatPrice && setSeatPricing(seatPrice.price);
    }
    // eslint-disable-next-line
  }, []);

  const handleTimingChange = (selectedTimeSlots = timings) => {
    // const isCloseTimeIsLessThanOpenTime = moment(closingHour, "HH:mm").isBefore(openingHour);
    setTimings(selectedTimeSlots);
  };

  const handleSeatPricingChange = (e) => {
    const { name, value } = e.target;
    setSeatPricing((prev) => ({ ...prev, [name]: value }));
  };

  const handleToggleChange = (name) => {
    setLibraryStatus((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const handleSave = async () => {
    setIsSubmitting(true);
    try {
      const setting = await locationsApi().updateLibrarySettings(locationId, {
        ...libraryStatus,
        openCloseTiming: timings,
        seatPricing: [{
          name: "individual",
          price: {
            amount: seatPricing.amount,
            unit: seatPricing.unit,
          },
        }],
        features: selectedFeatures.map(({ title, code }) => ({ title, code: code?.toString() })),
        gracePeriodMinutes: 30,
      });

      const updatedLocations = locations.map((location) => {
        if (location._id === locationId) {
          return {
            ...location,
            setting,
          };
        }
        return location;
      });

      reduxData("locations", "set")(updatedLocations);
      setIsSubmitting(false);
      navigate(-1);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error["message"] || "Something went wrong",
      });
    }
  };

  return (
    <Box>
      {/* Max Delivery Distance Section */}
      {timings?.length ? <TimeSelection daysOfWeek={timings} onTimingChange={handleTimingChange} /> : ""}

      <CollapsableSection defaultOpen title="Pricing and Features">
        <Grid2 container spacing={2}>

          <Grid2 size={{ xs: 6, md: 6 }} order={{ xs: 1, md: 1 }}>
            <Typography variant="subtitle1" color="textDisabled">
              Seat Pricing
            </Typography>
          </Grid2>

          <Grid2 size={{ xs: 6, md: 6 }} order={{ xs: 2, md: 2 }}>
            <Typography variant="subtitle1" color="textDisabled">
              Status
            </Typography>
          </Grid2>

          {/* Seat Pricing */}
          <Grid2 size={{ xs: 6, md: 6 }} order={{ xs: 3, md: 3 }} display="flex" alignItems="center" justifyContent="space-between">
            {/* Price Input Field */}
            <TextField
              label="Price (per Hour)"
              name="amount"
              value={seatPricing.amount}
              onChange={handleSeatPricingChange}
              type="number"
              variant="outlined"
              size="small"
              sx={{ flexGrow: 1, marginRight: 1 }}
            />
          </Grid2>
          {/* <Grid2 size={{ xs: 6, md: 3 }} order={{ xs: 3, md: 4 }}>
            <TextField
              label="Unit"
              name="unit"
              select
              value={seatPricing.unit}
              onChange={handleSeatPricingChange}
              fullWidth
            >
              {priceUnits.map((unit) => (
                <MenuItem key={unit} value={unit}>
                  {unit}
                </MenuItem>
              ))}
            </TextField>
          </Grid2> */}

          {/* Toggle Buttons */}
          <Grid2 size={{ xs: 6, md: 3 }} order={{ xs: 4, md: 4 }}>
            <Tooltip title="Enable or disable the library status">
              <FormControlLabel
                control={
                  <Switch
                    checked={libraryStatus.isActive}
                    onChange={() => handleToggleChange("isActive")}
                  />
                }
                label={libraryStatus.isActive ? "Active" : "Inactive"}
              />
            </Tooltip>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 3 }} order={{ xs: 5, md: 5 }}>
            <Tooltip title="Toggle library opening or closing status">
              <FormControlLabel
                control={
                  <Switch
                    checked={!libraryStatus.isOpen}
                    onChange={() => handleToggleChange("isOpen")}
                  />
                }
                label="Temporarily Closed"
              />
            </Tooltip>
          </Grid2>

          {/* Features */}
          <Grid2 size={{ xs: 12 }} order={{ xs: 7, md: 7 }}>
            <Typography variant="subtitle1" color="textDisabled">
              Features
            </Typography>

            <Autocomplete
              multiple
              options={features.map(feature => feature.title)}
              value={selectedFeatures.map(feature => feature.title)}
              disableCloseOnSelect
              onChange={(event, newValue) => {
                const updatedFeatures = newValue.map(title => {
                  const existingFeature = features.find(feature => feature.title === title);

                  if (existingFeature) {
                    return existingFeature;
                  } else {
                    const newCode = lastFeatureCode + 1;
                    setLastFeatureCode(newCode);
                    const newFeature = { title, code: newCode };
                    setFeatures([...features, newFeature]);
                    return newFeature;
                  }
                });
                setSelectedFeatures(updatedFeatures);
              }}
              filterOptions={(options, state) => {
                const filtered = options.filter(option =>
                  option.toLowerCase().includes(state.inputValue.toLowerCase())
                );
                if (state.inputValue !== "" && !options.includes(state.inputValue)) {
                  filtered.push(state.inputValue); // Show input value as a new option
                }
                return filtered;
              }}
              renderOption={(props, option) => {
                const isNewOption = !features.some(feature => feature.title === option);
                return (
                  <li {...props} key={option}>
                    {isNewOption ? (
                      <Box display="flex" justifyContent="space-between" width="100%">
                        <span>Add "{option}"</span>
                      </Box>
                    ) : (
                      option
                    )}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Features" fullWidth />
              )}
            />
          </Grid2>
        </Grid2>
      </CollapsableSection>
      {/* Save Button */}
      <Box mt={1} textAlign="right">
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={handleSave}
          type="button"
          disabled={isSubmitting}
          sx={{ display: "block", mx: "auto", mt: 2 }}
        >
          {isSubmitting && (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <CircularProgress size={20} />
            </Box>
          )}
          Save location settings
        </Button>
      </Box>
    </Box>
  );
};

export default LocationSettings;
