import React, { useState } from "react";
import { Box, Button, Avatar, Grid2, TextField } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LocationSelectField from "../Location/LocationSelectField";
import EmailField from "../Fields/Email"; // Import Reusable EmailField
import PhoneNumberField from "../Fields/ContactNumber"; // Import Reusable PhoneNumberField
import { ArrowBack, Person } from "@mui/icons-material";
import CustomCard from "../Containers/Card";

// Validation Schema
const memberSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters"),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email address"),
  contactNumber: Yup.string()
    .required("Contact number is required")
    .matches(/^\d{10}$/, "Contact number must be 10 digits"),
  // image: Yup.mixed()
  //   .test("fileSize", "File size is too large (max 5MB)", (value) => {
  //     return value ? value.size <= 5 * 1024 * 1024 : true;
  //   })
  //   .test(
  //     "fileType",
  //     "Unsupported file format. Only JPG, PNG, or JPEG are allowed.",
  //     (value) => {
  //       return value
  //         ? ["image/jpeg", "image/png", "image/jpg"].includes(value.type)
  //         : true;
  //     }
  //   ),
});

const MemberForm = ({ initialData = {}, mode = "add", onSubmit }) => {
  const navigate = useNavigate();
  const locations = useSelector((state) => state.locations);

  const initialValues = {
    name: initialData.name || "",
    email: initialData.email || "",
    contactNumber: initialData.contactNumber?.phoneNumber || initialData.contactNumber || "",
    image: initialData.image || null,
    locationId: initialData.locationId || "",
  };

  const [previewImage, setPreviewImage] = useState(initialData.image || null);

  const handleFormSubmit = async (values, { resetForm }) => {
    try {
      await onSubmit(values);
    } catch (error) {
      console.log("🚀 ~ handleFormSubmit ~ error:", error);
    }
  };

  const handleCancel = () => navigate(-1);

  return (
    <CustomCard>
      <Formik initialValues={initialValues} validationSchema={mode !== "view" ? memberSchema : null} onSubmit={handleFormSubmit}>
        {({ errors, setErrors, touched, setFieldValue, isSubmitting, values, isValid, dirty }) => (
          <Form>
            <Grid2 container spacing={2}>
              {/* Left Side: Image Upload */}
              <Grid2 size={{ xs: 12, md: 12, lg: 3 }} textAlign="center">
                <Avatar
                  alt="Staff Image"
                  variant="circular"
                  component="label"
                  htmlFor="image-upload"
                  sx={{ width: 200, height: 200, margin: "auto", cursor: mode === "view" ? "default" : "pointer" }}
                  src={previewImage && typeof previewImage !== "string" ? URL.createObjectURL(previewImage) : previewImage}
                />
                {mode !== "view" && (
                  <input
                    id="image-upload"
                    name="image"
                    type="file"
                    accept="image/jpeg,image/png"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      setFieldValue("image", file);
                      setPreviewImage(file);
                    }}
                  />
                )}
                {errors.image && touched.image && <Box sx={{ color: "error.main", mt: 1 }}>{errors.image}</Box>}
              </Grid2>

              {/* Right Side: Form Fields */}
              <Grid2 size={{ xs: 12, md: 12, lg: 9 }}>
                <Grid2 container spacing={2}>
                  <Grid2 size={{ xs: 12, sm: 6 }}>
                    <Field
                      as={TextField}
                      fullWidth
                      label="Full Name"
                      name="name"
                      variant="standard"
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      InputProps={{ startAdornment: <Person sx={{ mr: 1 }} /> }}
                      required
                      disabled={mode === "view"}
                      sx={{ mb: 2 }}
                    />
                  </Grid2>

                  <Grid2 size={{ xs: 12, sm: 6 }}>
                    <PhoneNumberField
                      name="contactNumber"
                      errors={errors}
                      setErrors={setErrors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      disabled={mode === "view"}
                    />
                  </Grid2>

                  <Grid2 size={{ xs: 12, sm: 6 }}>
                    <EmailField
                      errors={errors}
                      setErrors={setErrors}
                      touched={touched}
                      disabled={mode === "view"}
                      setFieldValue={setFieldValue}
                    />
                  </Grid2>

                  <Grid2 size={{ xs: 12, sm: 6 }}>
                    <LocationSelectField
                      name="locationId"
                      locations={locations}
                      errors={errors}
                      touched={touched}
                      disabled={mode === "view"}
                    />
                  </Grid2>

                  {/* Action Buttons */}
                  <Grid2 size={{ xs: 6, sm: 6 }}>
                    {mode !== "view" && <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      color="primary"
                      disabled={!isValid || !dirty || isSubmitting} // Dynamically disables the button
                    >
                      {mode === "add" ? "Add User" : "Save Changes"}
                    </Button>}
                  </Grid2>

                  <Grid2 size={{ xs: 6, sm: 6 }}>
                    <Button
                      startIcon={mode === "view" ? <ArrowBack /> : null}
                      variant="outlined"
                      fullWidth
                      onClick={handleCancel}
                    >
                      {mode !== "view" ? "Cancel" : "Back"}
                    </Button>
                  </Grid2>

                </Grid2>
              </Grid2>
            </Grid2>
          </Form>
        )}
      </Formik>
    </CustomCard>
  );
};

export default MemberForm;
