import React, { useEffect, useState } from "react";
import { Field } from "formik";
import { TextField, Box } from "@mui/material";
import { Phone } from "@mui/icons-material";
import { authApi } from "../../services/api";

const PhoneNumberField = ({ name = "contactNumber", label = "Phone Number", errors, setErrors, touched, setFieldValue, endAdornment, disabled }) => {
  const [numberError, setNumberError] = useState("");
  const { checkNumber } = authApi();

  useEffect(() => {
    setErrors({ ...errors, [name]: numberError });
    // eslint-disable-next-line
  }, [numberError]);

  return (
    <Field
      as={TextField}
      fullWidth
      label={label}
      name={name}
      variant="standard"
      disabled={disabled}
      error={(touched?.[name] && Boolean(errors?.[name])) || Boolean(numberError)}
      helperText={(touched?.[name] && errors?.[name]) || numberError}
      InputProps={{
        startAdornment: (
          <Box sx={{ display: "flex", mr: 1 }}>
            <Phone sx={{ mr: 1 }} /> +91
          </Box>
        ),
        endAdornment
      }}
      inputProps={{ maxLength: 10 }}
      onChange={async (e) => {
        setFieldValue(name, e.target.value);
        setNumberError("");
        if (e.target.value.length === 10) {
          try {
            await checkNumber(e.target.value);
          } catch (error) {
            setNumberError(error.message);
          }
        }
      }}
      sx={{ mb: 2 }}
    />
  );
};

export default PhoneNumberField;
