import moment from "moment";

export const calculatePrice = (price, discount) => {
  const getDiscountAmount = () => {
    switch (discount.type) {
      case "fixed":
        return discount.value;
      case "percentage":
        return price * (discount.value / 100);
      default:
        return 0;
    }
  }

  const discountAmount = getDiscountAmount();
  const priceAfterDiscount = price - discountAmount;

  const getMonthly = (duration) => {
    const totalMonths = moment.duration(duration.value, duration.unit).asMonths();

    return calculatePrice((price / totalMonths), discount);
  }

  return {
    price: (price).toFixed(2),
    priceAfterDiscount: (priceAfterDiscount).toFixed(2),
    discountAmount: (discountAmount).toFixed(2),
    getMonthly,
  }
}