import React, { useEffect, useState } from "react";
import { pdf } from "@react-pdf/renderer";
import CheckInOutPdf from "./CheckInOutPdf";
import { generateQrCode } from "../../utils/qrCode";
import { Button } from "@mui/material";

const PdfGenerator = ({ libraryName, branchName, libraryId, locationId }) => {
  const [qrCodeImage, setQrCodeImage] = useState("");
  const qrCodeData = libraryId + "_" + locationId;

  useEffect(() => {
    const generateQr = async () => {
      const qrImage = await generateQrCode(qrCodeData);
      setQrCodeImage(qrImage);
    };

    generateQr();
  }, [qrCodeData]);

  const handleOpenPdf = async () => {
    const doc = <CheckInOutPdf libraryName={libraryName} branchName={branchName} qrCodeData={qrCodeImage} />;
    const blob = await pdf(doc).toBlob(); // Convert PDF to Blob
    const url = URL.createObjectURL(blob); // Create a Blob URL
    window.open(url, "_blank"); // Open in a new tab
  };

  return qrCodeImage && (
    <Button
      onClick={handleOpenPdf}
      variant="outlined"
      color="primary"
      size="small"
    >
      View QrCode
    </Button>
  )
};

export default PdfGenerator;
