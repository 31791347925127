import React from "react";
import { Avatar, Typography, Tooltip, Stack, IconButton } from "@mui/material";
import { RowBuilder } from "../Containers/QueryContainer/RowBuilder";
import RatingStar from "../Common/RatingStar";
import { Delete, Edit } from "@mui/icons-material";

const LocationReviewRow = ({ review, header, onEdit = null, onDelete = null }) => {
  const { studentName, rating, image, comment } = review;

  return <RowBuilder
    header={header}
    columns={{
      image: {
        primary: <Avatar src={image || ""} alt={studentName} sx={{ width: 60, height: 60 }} />,
      },
      name: {
        primary: <Typography variant="body1" noWrap> {studentName} </Typography>,
      },
      ratingAndReview: {
        primary: <Stack
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <RatingStar rating={rating} />
          {onEdit && <IconButton
            color="primary"
            sx={{ padding: 0, paddingX: 2 }}
            onClick={onEdit}
          >
            <Edit />
          </IconButton>}
          {onDelete && <IconButton
            color="error"
            sx={{ padding: 0 }}
            onClick={onDelete}
          >
            <Delete />
          </IconButton>}
        </Stack>,
        secondary:
          <Tooltip title={comment}>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}> {comment.slice(0, 150)} </Typography>
          </Tooltip>
      },
    }}
  />
};

export default LocationReviewRow;