import React from "react";
import MemberForm from "../../components/Staffs/MemberForm";
import PageContainer from "../../components/Containers/PageContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { membersApi } from "../../services/api";
import { useSelector } from "react-redux";
import reduxData from "../../utils/useReduxData";

const AddMember = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const assetsCount = useSelector(state => state.assetsCount);

  const handleSubmit = async (data) => {
    try {
      const { role } = location.state;

      await membersApi().create(role._id, data);
      navigate(`/staffs/members`, { state: { role } });
      reduxData("assetsCount", "updateObj")({ members: assetsCount.members + 1 });
    } catch (error) {
    }
  };

  return (
    <PageContainer>
      <MemberForm
        mode="add"
        onSubmit={handleSubmit}
      />
    </PageContainer>
  );
};

export default AddMember;
