import React, { useState } from "react";
import { Box, CircularProgress, ListItemIcon, ListItemText, Menu, MenuItem, Skeleton, Typography } from "@mui/material";
import Swal from "sweetalert2";
import Seat from "./Seat";
import TableWithNumber from "./TableWithNumber";
import { CheckCircle, DoNotDisturb, Person } from "@mui/icons-material";

const SeatArrangementPanel = ({
  rows,
  cols,
  items,
  onDropItem,
  onCellClick,
  mode = "view",
  onAddRow,
  onAddCol,
  onDeleteRow,
  onDeleteCol,
  isLoading,
  onUpdateStatus,
}) => {
  const [rowColActionMenuAnchor, setRowColActionMenuAnchor] = useState(null);
  const [seatActionMenuAnchor, setSeatActionMenuAnchor] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [menuType, setMenuType] = useState(null); // "row" or "col"
  const [seatActionMenuItems, setSeatActionMenuItems] = useState([]);
  const [isSeatUpdating, setIsSeatUpdating] = useState({ isUpdating: false, seatId: null });

  const isViewMode = mode === "view";
  const cellSize = isViewMode ? 80 : 80;

  const getItemInCell = (row, col) => items.find((item) => item.row === row && item.col === col);

  const handleOnDragStart = (itemId) => (event) => {
    event.dataTransfer.setData("seatId", itemId);
  };

  const handleBorderClick = (event, index, type) => {
    setSelectedIndex(index);
    setMenuType(type);
    setRowColActionMenuAnchor(event.currentTarget);
  };

  const handleSeatClick = (event, status) => {
    setSeatActionMenuItems(seatStatusActions(status));
    setSeatActionMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setRowColActionMenuAnchor(null);
    setSeatActionMenuAnchor(null);
    setSelectedIndex(null);
    setMenuType(null);
  };

  const handleAddRowAbove = () => {
    onAddRow(selectedIndex, "above");
    handleCloseMenu();
  };

  const handleAddRowBelow = () => {
    onAddRow(selectedIndex, "below");
    handleCloseMenu();
  };

  const getAvailableSeats = (type, index) => items.map((item) => item[type] === index ? item.seatNumber : null).filter(Boolean);

  const handleDeleteRow = () => {
    handleCloseMenu();
    const availableSeats = getAvailableSeats("row", selectedIndex);
    const isAnySeatExists = availableSeats?.length;

    if (isAnySeatExists) {
      Swal.fire({
        title: "Are you sure?",
        text: `This will delete row ${selectedIndex + 1}.\nAvailable seats: ${availableSeats.join(", ")}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it",
        cancelButtonText: "No, keep it",
      }).then((result) => {
        if (result.isConfirmed) {
          onDeleteRow(selectedIndex);
          handleCloseMenu();
        }
      });
    } else {
      onDeleteRow(selectedIndex);
      handleCloseMenu();
    }
  };

  const handleAddColLeft = () => {
    onAddCol(selectedIndex, "left");
    handleCloseMenu();
  };

  const handleAddColRight = () => {
    onAddCol(selectedIndex, "right");
    handleCloseMenu();
  };

  const handleDeleteCol = () => {
    handleCloseMenu();
    const availableSeats = getAvailableSeats("col", selectedIndex);
    const isAnySeatExists = availableSeats?.length;

    if (isAnySeatExists) {
      Swal.fire({
        title: "Are you sure?",
        text: `This will delete column ${selectedIndex + 1}.\nAvailable seats: ${availableSeats.join(", ")}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it",
        cancelButtonText: "No, keep it",
      }).then((result) => {
        if (result.isConfirmed) {
          onDeleteCol(selectedIndex);
          handleCloseMenu();
        }
      });
    } else {
      onDeleteCol(selectedIndex);
      handleCloseMenu();
    }
  };

  const handleUpdateStatus = async (seatId, newStatus) => {
    setIsSeatUpdating({ isUpdating: true, seatId });
    handleCloseMenu();
    await onUpdateStatus(seatId, newStatus);
    setIsSeatUpdating({ isUpdating: false, seatId: null });
  }

  const seatStatusActions = (seat) => {
    const allStatusWithIcon = [
      {
        value: "available",
        title: "Available",
        icon: <CheckCircle color="success" />,
        onClick: () => handleUpdateStatus(seat.id, "available"),
      },
      {
        value: "unavailable",
        title: "Unavailable",
        icon: <DoNotDisturb color="disabled" />,
        onClick: () => handleUpdateStatus(seat.id, "unavailable"),
      },
      {
        value: "occupied",
        title: "Occupied",
        icon: <Person color="warning" />,
        onClick: () => handleUpdateStatus(seat.id, "occupied"),
      },
      {
        value: "reserved",
        title: "Reserved",
        icon: <DoNotDisturb color="error" />,
        onClick: () => handleUpdateStatus(seat.id, "reserved"),
      },
    ]

    return allStatusWithIcon.filter(status => status.value !== seat.status);
  }

  return (
    <Box sx={{ position: "relative", margin: "30px" }}>
      {/* Menu for Row and Column Actions */}
      <Menu
        anchorEl={rowColActionMenuAnchor}
        open={Boolean(rowColActionMenuAnchor)}
        onClose={handleCloseMenu}
      >
        {menuType === "row" && (
          <>
            <MenuItem onClick={handleAddRowAbove}>Add Row Above</MenuItem>
            <MenuItem onClick={handleAddRowBelow}>Add Row Below</MenuItem>
            <MenuItem onClick={handleDeleteRow}>Delete Row</MenuItem>
          </>
        )}
        {menuType === "col" && (
          <>
            <MenuItem onClick={handleAddColLeft}>Add Column Left</MenuItem>
            <MenuItem onClick={handleAddColRight}>Add Column Right</MenuItem>
            <MenuItem onClick={handleDeleteCol}>Delete Column</MenuItem>
          </>
        )}
      </Menu>

      <Menu
        anchorEl={seatActionMenuAnchor}
        open={Boolean(seatActionMenuAnchor)}
        onClose={handleCloseMenu}
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: 3,
            minWidth: 200,
          },
        }}
      >
        {
          seatActionMenuItems.map((status) => (
            <MenuItem key={status.value} onClick={status.onClick}>
              <ListItemIcon>
                {status.icon}
              </ListItemIcon>
              <ListItemText primary={status.title} />
            </MenuItem>
          ))
        }
      </Menu>
      {/* Row Borders and Numbers */}
      {Array.from({ length: rows }).map((_, rowIndex) => (
        <Box
          key={`row-border-${rowIndex}`}
          onClick={(event) => isViewMode ? null : handleBorderClick(event, rowIndex, "row")}
          sx={{
            position: "absolute",
            top: rowIndex * cellSize,
            left: 0,
            height: cellSize,
            cursor: isViewMode ? "default" : "pointer",
          }}
        >
          <Typography
            sx={{
              position: "absolute",
              left: -40,
              top: "50%",
              transform: "translateY(-50%)",
              fontSize: "15px",
              padding: "0px 10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
          >
            {rowIndex + 1}
          </Typography>
        </Box>
      ))}

      {/* Column Borders and Numbers */}
      {Array.from({ length: cols }).map((_, colIndex) => (
        <Box
          key={`col-border-${colIndex}`}
          onClick={(event) => isViewMode ? null : handleBorderClick(event, colIndex, "col")}
          sx={{
            position: "absolute",
            top: -10,
            left: colIndex * cellSize,
            width: cellSize,
            cursor: isViewMode ? "default" : "pointer",
          }}
        >
          <Typography
            sx={{
              position: "absolute",
              top: -24,
              left: "50%",
              transform: "translateX(-50%)",
              fontSize: "15px",
              padding: "0px 10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
          >
            {colIndex + 1}
          </Typography>
        </Box>
      ))}

      {/* Main Grid */}
      <Box
        sx={{
          display: "grid",
          gridTemplateRows: `repeat(${rows}, ${cellSize}px)`,
          gridTemplateColumns: `repeat(${cols}, ${cellSize}px)`,
          gap: 0,
        }}
      >
        {Array.from({ length: rows * cols }).map((_, index) => {
          const row = Math.floor(index / cols);
          const col = index % cols;
          const item = getItemInCell(row, col);

          if (isLoading) return (<Skeleton variant="rectangular" width={47} height={47} />);

          return (
            <Box
              key={`${row}-${col}`}
              onClick={() => isViewMode ? null : onCellClick(row, col)}
              onDragOver={(event) => isViewMode ? null : event.preventDefault()}
              onDrop={(event) => isViewMode ? null : onDropItem(event, row, col)}
              sx={{
                width: "100%",
                height: "100%",
                border: isViewMode ? "" : "1px solid #ccc",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxSizing: "border-box",
                cursor: isViewMode ? "default" : "pointer",
                "&:hover": {
                  backgroundColor: isViewMode ? "" : "#e0e0e0",
                },
              }}
            >
              {item && (
                isViewMode
                  ? <Box
                    sx={{
                      position: "relative",
                      transform: `rotate(${item.rotation}deg)`,
                      width: cellSize,
                      height: cellSize,
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#e0e0e0",
                      },
                    }}
                    onClick={(event) => handleSeatClick(event, item)}
                  >
                    <TableWithNumber seatNumber={item.seatNumber} rotation={item.rotation} status={item.status} cellSize={cellSize} />
                    {
                      isSeatUpdating.seatId === item.id && isSeatUpdating.isUpdating
                        ? <CircularProgress size={cellSize / 2} sx={{ position: "absolute", top: "20%", left: "20%", }} />
                        : ""
                    }
                  </Box>
                  : <Seat
                    item={item}
                    onDragStart={handleOnDragStart(item.id)}
                    tableNumber={item.seatNumber}
                    cellSize={cellSize}
                  />
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default SeatArrangementPanel;
