import React, { useState } from "react";
import { Grid2, IconButton, Box, useTheme, Collapse, useMediaQuery, Tooltip } from "@mui/material";
import { ReactComponent as SortIcon } from "../../../assets/icons/sort.svg"; // Use your uploaded SVG file here.
import MenuListComponent from "./MenuList";
import SearchWithDebounce from "./Search";
import { ExpandMore, ExpandLess, Refresh } from "@mui/icons-material"; // Import icons for collapse
import CustomCard from "../Card";

const Query = ({ query, onQueryChange, onReset, sortOptions, filters }) => {
  const theme = useTheme(); // Get the theme
  const { sortKey, sortOrder } = query;
  const [sortMenuAnchor, setSortMenuAnchor] = useState(null);
  const [filterMenuAnchor, setFilterMenuAnchor] = useState({}); // Track filter menu anchors per key
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [isCollapsed, setIsCollapsed] = useState(isMobile); // State for collapse

  const handleSortKeyChange = (value) => {
    onQueryChange({ ...query, page: 1, sortKey: value });
    setSortMenuAnchor(null); // Close menu after selection
  };

  const handleSortOrderToggle = () => {
    onQueryChange({
      ...query,
      page: 1,
      sortOrder: sortOrder === "ASC" ? "DESC" : "ASC",
    });
  };

  const handleSearchChange = (value) => {
    onQueryChange({ ...query, page: 1, search: value });
  };

  const handleSortMenuOpen = (event) => {
    setSortMenuAnchor(event.currentTarget);
  };

  const handleSortMenuClose = () => {
    setSortMenuAnchor(null);
  };

  const handleFilterMenuOpen = (key, event) => {
    setFilterMenuAnchor({ ...filterMenuAnchor, [key]: event.currentTarget });
  };

  const handleFilterMenuClose = (key) => {
    setFilterMenuAnchor({ ...filterMenuAnchor, [key]: null });
  };

  const handleFilterChange = (key, value) => {
    onQueryChange({
      ...query,
      page: 1,
      [key]: value
    });
    handleFilterMenuClose(key);
  };

  return (
    <CustomCard
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 1000,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[2],
        borderRadius: 2,
        padding: 1,
      }}
    >
      {
        isMobile && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 1,
              backgroundColor: theme.palette.background.paper,
              borderRadius: theme.shape.borderRadius,
              flexWrap: "wrap",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <SearchWithDebounce
                searchQuery={query.search}
                onSearch={handleSearchChange}
                placeholder="Search"
              />
              <IconButton onClick={() => setIsCollapsed(!isCollapsed)} >
                {isCollapsed ? <ExpandMore /> : <ExpandLess />}
              </IconButton>
            </Box>
          </Box>
        )
      }

      <Collapse in={!isCollapsed}>
        <Grid2
          container
          spacing={2}
          alignItems="center"
          sx={{
            padding: 1,
            backgroundColor: theme.palette.background.paper, // Use theme background color
            borderRadius: theme.shape.borderRadius, // Use theme border radius
            flexWrap: "wrap",
          }}
        >
          {
            !isMobile && (
              <Grid2>
                <SearchWithDebounce
                  searchQuery={query.search}
                  onSearch={handleSearchChange}
                  placeholder="Search"
                />
              </Grid2>
            )
          }
          {/* Sort Options with Sort Order */}
          <Grid2>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <MenuListComponent
                items={sortOptions.map((option) => ({
                  key: option.key,
                  label: option.label,
                  checked: sortKey === option.key,
                }))}
                anchorEl={sortMenuAnchor}
                onOpen={handleSortMenuOpen}
                onClose={handleSortMenuClose}
                onSelect={handleSortKeyChange}
                selectedItem={sortOptions.find((option) => option.key === sortKey)?.label || "Default"}
                label="Sort By"
              />
              <Tooltip title={sortOrder !== "ASC" ? "DESC" : "ASC"}>
                <IconButton
                  onClick={handleSortOrderToggle}
                  sx={{
                    backgroundColor: theme.palette.background.default, // Use theme background color
                    border: `1px solid ${theme.palette.divider}`, // Use theme divider color
                    "&:hover": { backgroundColor: theme.palette.action.hover }, // Use theme hover color
                    rotate: sortOrder === "ASC" ? "180deg" : "0deg",
                    transition: "all 0.2s ease-in-out",
                  }}
                >
                  <SortIcon style={{ width: 20, height: 20, color: theme.palette.primary.main }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid2>

          {/* Filters */}
          {Object.entries(filters).map(([key, options]) => (
            <Grid2 key={key}>
              <MenuListComponent
                items={options.map((option) => ({
                  key: option.value,
                  label: option.label,
                  checked: filters[key] === option.value,
                }))}
                anchorEl={filterMenuAnchor[key]}
                onOpen={(e) => handleFilterMenuOpen(key, e)}
                onClose={() => handleFilterMenuClose(key)}
                onSelect={(value) => handleFilterChange(key, value)}
                selectedItem={filters[key]?.find((option) => option.value === query[key])?.label || filters[key][0].label}
                label={key.charAt(0).toUpperCase() + key.slice(1)}
              />
            </Grid2>
          ))}

          {/* Reset Button */}
          <Grid2>
            <Tooltip title="Reset">
              <IconButton
                onClick={onReset}
                size="small"
                color="primary"
                sx={{
                  backgroundColor: theme.palette.background.default, // Use theme background color
                  border: `1px solid ${theme.palette.divider}`, // Use theme divider color
                  "&:hover": { backgroundColor: theme.palette.action.hover }, // Use theme hover color
                }}
              >
                <Refresh />
              </IconButton>
            </Tooltip>
          </Grid2>
        </Grid2>
      </Collapse>
    </CustomCard>
  );
};

export default Query;
