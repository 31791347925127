import React, { useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { floorsApi, offersApi, studentApi } from "../../services/api";
import Swal from "sweetalert2";
import { Button } from "@mui/material";
import { Add, ArrowBack } from "@mui/icons-material";
import PageContainer from "../../components/Containers/PageContainer";
import QueryContainer from "../../components/Containers/QueryContainer/Main";
import NoRecordsFound from "../../components/NoRecordFound";
import StudentItemForReservation from "../../components/Offers/StudentItemForReservation";
import { hasFeature, showPlanUpgrade } from "../../middleware/subscription";
import { PlanUpgrade } from "../../components/Icons/PlanUpgrade";

const AddSubscriber = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [floors, setFloors] = useState([]);
  const [availableSeats, setAvailableSeats] = useState({});

  const { refetch: floorRefetch } = useQuery(
    ["floors"],
    async () => await floorsApi().getAllWithSeats(),
    {
      retry: false, // Disable retries on error
      onSuccess: async ({ results }) => {
        const updatedSeats = {};
        const newFloors = [];

        results.forEach((floorWithSeats) => {
          const { seats, ...floor } = floorWithSeats;

          newFloors.push(floor);
          updatedSeats[floor._id] = seats.filter((seat) => seat.status === 'available').map((seat) => ({ _id: seat._id, number: seat.number }));
        });

        setAvailableSeats(updatedSeats);
        setFloors(newFloors);
      }
    }
  );

  const hasFeaturePermission = hasFeature("addStudent");

  const offerId = location.state?.offerId;
  if (!offerId) {
    return <NoRecordsFound />;
  }

  const handleStudentSelection = (studentRefetch) => async (studentId, type, floorId, seatId) => {
    try {
      let floorName = null;
      let seatNumber = null;

      if (type === "fixed") {
        const selectedFloor = floors.find((floor) => floor._id === floorId);
        const selectedSeat = availableSeats[floorId].find((seat) => seat._id === seatId);


        floorName = selectedFloor.name;
        seatNumber = selectedSeat.number;
      }

      await offersApi().addSubscriber(offerId, { studentId, floorId, type, seatId, floorName, seatNumber });

      studentRefetch && studentRefetch();
      floorRefetch();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error['message'],
      });
    }
  };

  // Toggle student form
  const handleToggleStudentForm = () => {
    if (!hasFeaturePermission) return showPlanUpgrade();

    navigate(`/students/add`);
  };

  const sortOptions = [
    { key: "name", label: "Name" },
    { key: "email", label: "Email" },
  ];

  const filters = {};

  const header = [
    { size: 1, align: "start", key: "image", label: "Image" },
    { size: 2, align: "start", key: "name", label: "Name" },
    { size: 3, align: "center", key: "type", label: "Subscription Type" },
    { size: 2, align: "center", key: "floor", label: "Floor" },
    { size: 2, align: "center", key: "seat", label: "Seat" },
    { size: 1, align: "end", key: "action", label: "Action" },
  ];

  const goBack = (
    <Button
      variant="outlined"
      size="small"
      color="primary"
      onClick={() => navigate(-1)}
      startIcon={<ArrowBack />}
    >
      Back
    </Button>
  );

  const defaultFilter = {}

  return (
    <PageContainer headerContent={goBack}>
      <QueryContainer
        fetchData={studentApi().getAll}
        defaultFilter={defaultFilter}
        filters={filters}
        header={header}
        sortOptions={sortOptions}
        getItems={(students, refetch) => {
          return students.map((student) => {
            const isFixedSubs = student?.subscription?.status === "active";

            if (isFixedSubs) {
              return false;
            }

            return (
              <StudentItemForReservation
                student={student}
                header={header}
                floors={floors}
                availableSeats={availableSeats}
                onReserve={handleStudentSelection(refetch)}
              />
            );
          }).filter(Boolean);
        }}
        noDataFound={{
          title: 'No Students Found',
          subTitle: "Click on the button below to add a new students.",
          buttonText: "Add Students",
          ButtonIcon: hasFeaturePermission ? Add : PlanUpgrade,
          onRefresh: handleToggleStudentForm,
        }}
      />
    </PageContainer>
  );
};

export default AddSubscriber;
