import React from "react";
import { Typography, Divider, List, ListItem, ListItemText, Avatar } from "@mui/material";
import NoRecordsFound from "../NoRecordFound";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const ResentUsers = ({ students }) => {
  const navigate = useNavigate();

  return (
    <>
      <Typography variant="h6">
        Recent Users
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <List sx={{ backgroundColor: "action.hover", borderRadius: 4 }}>
        {
          students.length
            ? students.map((notification) => (
              <ListItem key={notification.id}>
                <Avatar sx={{ mr: 2, bgcolor: "primary.main" }}>N</Avatar>
                <ListItemText
                  primary={notification.text}
                  secondary={notification.time}
                />
              </ListItem>
            ))
            : <NoRecordsFound
              title={"No Active Students Found"}
              subTitle={"It looks like there are no active students in your library."}
              buttonText={"Add Student"}
              ButtonIcon={Add}
              onRefresh={() => {
                navigate("/students");
                navigate("/students/add");
              }}
            />
        }
      </List>
    </>
  );
};

export default ResentUsers;
