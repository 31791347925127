import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import PageContainer from "../../components/Containers/PageContainer";
import { offersApi } from "../../services/api";
import NoRecordsFound from "../../components/NoRecordFound";
import OfferDetailCard from "../../components/Offers/OfferDetailCard";
import QueryListContainer from "../../components/Containers/QueryContainer/Main";
import SubscriberRow from "../../components/Offers/SubscriberRow";
import { hasPermission } from "../../middleware/permission";
import { PERMISSION_CODE } from "../../config/permissionCodes";
import { hasFeature, showPlanUpgrade } from "../../middleware/subscription";
import { PlanUpgrade } from "../../components/Icons/PlanUpgrade";

const OfferSubscribers = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const offerDetails = location.state?.offerDetails;
  if (!offerDetails) {
    return <NoRecordsFound />;
  }

  const hasAddSubscriberPermission = hasPermission(PERMISSION_CODE.OFFER.SUBSCRIBE);
  const hasFeaturePermission = hasFeature("addSubscriber")

  const offerId = offerDetails._id;

  const handleAddSubscrberButton = () => {
    if (!hasFeaturePermission) return showPlanUpgrade();

    navigate('/offers/subscribers/add', { state: { offerId } });
  };

  const sortOptions = [
    { key: "startDate", label: "Start Date" },
    { key: "endDate", label: "End Date" },
    { key: "name", label: "Name" },
    { key: "email", label: "Email" },
    { key: "canceledAt", label: "Canceled Date" },
    { key: "contactNumber.phoneNumber", label: "Contact Number" },
  ];

  const filters = {
    type: [
      { value: "", label: "All" },
      { value: "fixed", label: "Fixed" },
      { value: "flexible", label: "Flexible" },
    ],
    status: [
      { value: "", label: "All" },
      { value: "pending", label: "Pending" },
      { value: "active", label: "Active" },
      { value: "completed", label: "Completed" },
      { value: "cancelled", label: "Cancelled" },
    ],
  };

  const header = [
    { size: 1, align: "start", key: "image", label: "Image" },
    { size: 2, align: "start", key: "name", label: "Name" },
    { size: 3, align: "center", key: "type", label: "Subscription Type" },
    { size: 3, align: "center", key: "status", label: "Status" },
    { size: 3, align: "end", key: "dates", label: "Dates" },
  ];

  const bookASeatButton = (
    <Button
      variant="outlined"
      size="small"
      color="primary"
      onClick={handleAddSubscrberButton}
      startIcon={<Add />}
      endIcon={!hasFeaturePermission ? <PlanUpgrade /> : null}
      disabled={!hasAddSubscriberPermission}
    >
      Subscriber
    </Button>
  );

  return (
    <PageContainer headerContent={bookASeatButton}>
      <OfferDetailCard {...offerDetails} totalSubscribers={offerDetails['subscribers']?.length} />
      <QueryListContainer
        fetchData={offersApi().getSubscribers(offerId)}
        filters={filters}
        sortOptions={sortOptions}
        header={header}
        getItems={(subscribers, refetch) => subscribers.map((subscriber) => (
          <SubscriberRow
            subscriber={subscriber}
            header={header}
          />
        ))}
        noDataFound={{
          title: 'No Subscribers Found',
          subTitle: hasAddSubscriberPermission ? "Click on the button below to add a new subscriber." : "Please Refresh the page",
          buttonText: hasAddSubscriberPermission ? "Add Subscriber" : "",
          ButtonIcon: hasAddSubscriberPermission ? (hasFeaturePermission ? Add : PlanUpgrade) : "",
          onRefresh: hasAddSubscriberPermission ? handleAddSubscrberButton : "",
        }}
      />
    </PageContainer>
  );
};

export default OfferSubscribers;
