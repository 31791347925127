import { store } from "../redux/store";
import reduxData from "../utils/useReduxData";
import PageContainer from "../components/Containers/PageContainer";

export const checkPermission = (Component, permissionCode) => ({ propes }) => {

  if (!hasPermission(permissionCode)) {
    reduxData("showPermissionDenied", "set")(true);
    return <PageContainer> Permission Denied </PageContainer>
  }

  return <Component {...propes} />;
};

export const hasPermission = (permissionCode) => {
  const staffRole = store.getState().staffRole;

  return !(staffRole && staffRole?.permissions && permissionCode && !staffRole?.permissions?.includes(permissionCode));
};