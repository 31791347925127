import QRCode from "qrcode";

/**
 * Generate a QR code as a Base64 string
 * @param {string} data - The data to encode in the QR code
 * @returns {Promise<string>} - A Promise that resolves to the Base64 QR code image
 */
export const generateQrCode = async (data) => {
  try {
    const qrCode = await QRCode.toDataURL(data, { width: 200, margin: 2 });
    return qrCode;
  } catch (err) {
    console.error("Error generating QR code:", err);
    throw err;
  }
};

