import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, MenuItem, Button, CircularProgress, Grid2, useMediaQuery } from "@mui/material";
import CustomCard from "../Containers/Card";

const OfferForm = ({ onSubmit, onClose, errors }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const validationSchema = Yup.object({
    duration: Yup.number().integer("Must be a whole number").min(1, "Required").positive("Must be greater than zero").required("Required"),
    durationUnit: Yup.string().oneOf(["days", "weeks", "months", "years"], "Invalid Unit").required("Required"),
    price: Yup.number().min(0, "Required").required("Required").positive("Must be greater than zero"),
    discountType: Yup.string().oneOf(["percentage", "fixed"], "Invalid Type").required("Required"),
    discountValue: Yup.number().required("Discount is required").min(0, "Cannot be negative")
      .when('discountType', {
        is: 'percentage',
        then: (schema) => schema.max(100, "Percentage cannot exceed 100%"),
      }),
  });

  const titleStyle = isMobile
    ? {
      backgroundColor: "primary.main",
      color: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      px: 2,
      py: 1,
      borderRadius: 2,
    }
    : {
      color: "primary.main",
      display: "flex",
      alignItems: "center",
    };

  const formik = useFormik({
    initialValues: {
      duration: "",
      durationUnit: "days",
      discountValue: "",
      discountType: "percentage",
      price: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const offer = {
          duration: { value: values.duration, unit: values.durationUnit },
          discount: { value: values.discountValue, type: values.discountType },
          price: values.price,
          finalPrice: calculateFinalPrice(values.price, values.discountValue, values.discountType),
        };

        await onSubmit(offer);
      } catch (error) {
        console.error(error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const calculateFinalPrice = (price, discountValue, discountType) => {
    if (!price || isNaN(price)) return "";
    return discountType === "percentage"
      ? price - (price * discountValue) / 100
      : price - discountValue;
  };

  const getDiscountLabel = () => {
    return formik.values.discountType === "percentage" ? `${formik.values.discountValue}%` : `₹${formik.values.discountValue}`;
  }

  return (
    <CustomCard>
      <form onSubmit={formik.handleSubmit}>
        <Grid2 container spacing={2}>
          {/* Duration */}
          <Grid2 size={{ xs: 12, sm: 2 }} sx={titleStyle}> Duration </Grid2>
          <Grid2 size={{ xs: 12, sm: 5 }}>
            <TextField
              label="Duration"
              type="number"
              variant="standard"
              size="small"
              {...formik.getFieldProps("duration")}
              error={formik.touched.duration && Boolean(formik.errors.duration)}
              helperText={formik.touched.duration && formik.errors.duration}
              fullWidth
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 5 }}>
            <TextField
              label="Unit"
              select
              variant="standard"
              size="small"
              {...formik.getFieldProps("durationUnit")}
              error={formik.touched.durationUnit && Boolean(formik.errors.durationUnit)}
              helperText={formik.touched.durationUnit && formik.errors.durationUnit}
              fullWidth
            >
              {['days', 'weeks', 'months', 'years'].map((unit) => (
                <MenuItem key={unit} value={unit}>{unit}</MenuItem>
              ))}
            </TextField>
          </Grid2>

          {/* Discount Section */}
          <Grid2 size={{ xs: 12, sm: 2 }} sx={titleStyle}> Discount </Grid2>
          <Grid2 size={{ xs: 12, sm: 5 }}>
            <TextField
              label="Discount Value"
              type="number"
              variant="standard"
              size="small"
              {...formik.getFieldProps("discountValue")}
              error={formik.touched.discountValue && Boolean(formik.errors.discountValue)}
              fullWidth
              helperText={formik.touched.discountValue && formik.errors.discountValue}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 5 }}>
            <TextField
              fullWidth
              label="Discount Type"
              select
              variant="standard"
              size="small"
              {...formik.getFieldProps("discountType")}
              error={formik.touched.discountType && Boolean(formik.errors.discountType)}
              helperText={formik.touched.discountType && formik.errors.discountType}
            >
              {['percentage', 'fixed'].map((type) => (
                <MenuItem key={type} value={type}>{type}</MenuItem>
              ))}
            </TextField>
          </Grid2>

          {/* Price */}
          <Grid2 size={{ xs: 12, sm: 2 }} sx={titleStyle}> Prices </Grid2>
          <Grid2 size={{ xs: 12, sm: 5 }}>
            <TextField
              label={`One Seat Price for ${formik.values.duration} ${formik.values.durationUnit}`}
              placeholder={`Enter Your One Seat Price for ${formik.values.duration} ${formik.values.durationUnit}`}
              type="number"
              variant="standard"
              size="small"
              {...formik.getFieldProps("price")}
              error={formik.touched.price && Boolean(formik.errors.price)}
              fullWidth
              helperText={formik.touched.price && formik.errors.price}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 5 }}>
            <TextField
              // label="Seat Price after Discount"
              label={`One Seat Price for ${formik.values.duration} ${formik.values.durationUnit} after ${getDiscountLabel()} Discount`}
              placeholder={`Enter Your One Seat Price for ${formik.values.duration} ${formik.values.durationUnit}`}
              type="number"
              variant="standard"
              size="small"
              value={calculateFinalPrice(formik.values.price, formik.values.discountValue, formik.values.discountType)}
              fullWidth
              disabled
            />
          </Grid2>

          {/* Submit and Cancel Buttons */}
          <Grid2 size={{ xs: 6 }} mt={2}>
            <Button
              type="reset"
              color="error"
              fullWidth
              onClick={onClose}
            >
              Cancel
            </Button>
          </Grid2>
          <Grid2 size={{ xs: 6 }} mt={2}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={!formik.isValid || formik.isSubmitting}
            >
              {formik.isSubmitting ? <CircularProgress size={24} /> : "Add Offer"}
            </Button>
          </Grid2>
        </Grid2>
      </form>
    </CustomCard>
  );
};

export default OfferForm;
