import React, { useEffect, useState } from "react";
import { TextField, Box } from "@mui/material";
import { Email, AccountBox, Phone } from "@mui/icons-material";

const EmailPhoneField = ({ onChange, initalValue = "", isPhone = false }) => {
  const [inputValue, setInputValue] = useState("");
  const [isPhoneNumber, setIsPhoneNumber] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (isPhone) {
      setIsPhoneNumber(isPhone);
    }

    if (initalValue) {
      setInputValue(initalValue);
    }
  }, [initalValue, isPhone]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setInputValue(value);

    let error = "";
    const isNumber = /^[0-9]+$/.test(value);
    const isValidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
    const valueLength = value.trim().length;

    if (isNumber) {   // It's a phone number
      if (valueLength > 10) {
        error = "Phone number cannot exceed 10 digits.";
      } else if (valueLength < 10 && valueLength > 0) {
        error = "";
      }
    } else if (valueLength > 0 && isValidEmail) {
      error = "";
    }

    const isButtonEnable = !error && (isNumber ? valueLength === 10 : (valueLength && isValidEmail));
    setErrorMessage(error);
    setIsPhoneNumber(isNumber); // Only digits -> phone number
    onChange(value, isNumber, isButtonEnable);
  };

  return (
    <TextField
      fullWidth
      label={
        inputValue
          ? (isPhoneNumber ? "Phone Number" : "Email Address")
          : "Phone Number or Email"
      }
      placeholder="Phone Number or Email"
      variant="standard"
      value={inputValue}
      onChange={handleInputChange}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      InputProps={{
        startAdornment: (
          <Box sx={{ mr: 1, display: "flex" }}>
            {
              inputValue
                ? (isPhoneNumber ? <Phone sx={{ mr: 1 }} /> : <Email sx={{ mr: 1 }} />)
                : <AccountBox sx={{ mr: 1 }} />
            }
            {isPhoneNumber ? "+91" : null}
          </Box>
        )
      }}
    />
  );
};

export default EmailPhoneField;
