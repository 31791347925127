import React from "react";
import availableSeat from "../../assets/svgs/available_seat.svg";
import unavailableSeat from "../../assets/svgs/unavailable_seat.svg";
import occupiedSeat from "../../assets/svgs/occupied_seat.svg";
import reservedSeat from "../../assets/svgs/reserved_seat.svg";

const TableWithNumber = ({ seatNumber, status = "available", cellSize = 50 }) => {
  // Map statuses to corresponding SVG file paths
  const statusSvgMap = {
    available: availableSeat,
    unavailable: unavailableSeat,
    occupied: occupiedSeat,
    reserved: reservedSeat,
  };

  // Fallback SVG in case of invalid status
  const svgPath = statusSvgMap[status] || statusSvgMap["available"];

  return (
    <div style={{ position: "relative", width: cellSize, height: cellSize }}>
      {/* Seat Number */}
      <div
        style={{
          position: "absolute",
          top: "0",
          left: seatNumber < 9 ? "30%" : (seatNumber < 99 ? "20%" : "10%"),
          fontWeight: "bold",
          fontSize: "20px",
          color: "#000",
        }}
      >
        {seatNumber}
      </div>

      {/* SVG Image */}
      <img
        src={svgPath}
        alt={`${status} table`}
        style={{
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  );
};

export default TableWithNumber;
