import { MenuItem, TextField } from "@mui/material";

// Dropdown component
export const DropDownList = ({ label, value, disabled, items, onChange }) => (
  <TextField
    select
    fullWidth
    label={label}
    value={value}
    onChange={(e) => onChange(e.target.value)}
    variant="outlined"
    disabled={disabled}
    size="small"
    InputLabelProps={{ style: { fontSize: "0.9rem" } }}
    sx={{ width: "80%", borderRadius: 1, borderColor: "divider" }}
  >
    {items.map((item) => (
      <MenuItem key={item.value} value={item.value}>
        {item.label}
      </MenuItem>
    ))}
  </TextField>
);