import React, { useState } from "react";
import { Typography } from "@mui/material";
import PageContainer from "../../components/Containers/PageContainer";
import { offersApi } from "../../services/api";
import { useNavigate } from "react-router-dom";
import OfferForm from "../../components/Offers/AddOfferFrom";

const AddOffer = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const validate = (offer) => {
    let newErrors = {};
    if (!offer.duration.value || isNaN(offer.duration.value) || offer.duration.value <= 0) newErrors.duration = "Duration is required";
    if (!offer.price || isNaN(offer.price) || offer.price <= 0) newErrors.price = "Valid price is required";
    if (!offer.discount.value || isNaN(offer.discount.value) || offer.discount.value < 0) {
      newErrors.discount = "Valid discount is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (offer) => {
    if (!validate(offer)) return;
    try {
      await offersApi().create(offer);
      navigate("/offers");
    } catch (error) {
    }
  };

  const handleCancel = () => {
    navigate(-1);
  }

  return (
    <PageContainer>
      <Typography variant="h4" fontWeight="600" gutterBottom textAlign="center">
        Add Offer
      </Typography>
      <OfferForm onSubmit={handleSubmit} errors={errors} onClose={handleCancel} />
    </PageContainer>
  );
};

export default AddOffer;
