import React, { useEffect, useState } from "react";
import { Typography, Button, CircularProgress, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { studentApi } from "../../services/api";
import EmailPhoneField from "../Fields/EmailPhone";
import CustomCard from "../Containers/Card";
import { Person } from "@mui/icons-material";
import formattedContactNumber from "../../utils/formateContactNumber";
import { useSelector } from "react-redux";

const StudentForm = ({ initial = {}, mode = "add" }) => {
  const navigate = useNavigate();
  const { create } = studentApi();
  const isModeEdit = mode === "edit";

  const [studentName, setStudentName] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [isPhoneNumber, setIsPhoneNumber] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const currentLocation = useSelector((state) => state.currentLocation);

  useEffect(() => {
    if (isModeEdit && Object.keys(initial)) {
      const isEmailExist = !!initial.email;
      const initialValue = isEmailExist ? initial.email : formattedContactNumber(initial.contactNumber);

      setStudentName(initial.name);
      setInputValue(initialValue);
      setIsPhoneNumber(!isEmailExist);
    }
  }, [initial, isModeEdit])

  const handleInputChange = (value, isPhone, isButtonEnable) => {
    setErrorMessage("");
    setInputValue(value);
    setIsPhoneNumber(isPhone);
    setIsButtonEnabled(isButtonEnable);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setErrorMessage("");

    const formattedInput = isPhoneNumber ? `+91${inputValue}` : inputValue;

    try {
      // Call your API
      if (isModeEdit) {
        await create({ value: formattedInput, name: studentName, studentId: initial._id });
      } else {
        await create({ value: formattedInput, name: studentName });
      }
      navigate(-1);
    } catch (err) {
      setErrorMessage(err.message || "Failed to send OTP.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <CustomCard sx={{
      p: 2,
      display: "flex",
      width: "500px",
      flexDirection: "column",
      gap: 2,
      alignItems: "center",
      justifyContent: "center",
      margin: "auto"
    }}>

      {/* Title */}
      <Typography variant="h5" fontWeight="bold" textAlign="center">
        Add Student
      </Typography>

      <Typography variant="body1" textAlign="center" mb={3} color="text.secondary">
        Enter a student's name and email or contact number to add them to {currentLocation.name} Branch.
      </Typography>

      <TextField
        fullWidth
        label="Student Name"
        placeholder="Enter Student Name"
        variant="standard"
        value={studentName}
        onChange={(event) => setStudentName(event.target.value)}
        InputProps={{
          startAdornment: <Person sx={{ mr: 1 }} />
        }}
      />

      <EmailPhoneField onChange={handleInputChange} initalValue={inputValue} isPhone={isPhoneNumber} />

      {errorMessage && <Typography variant="caption" color="error">{errorMessage}</Typography>}

      {/* Send OTP Button */}
      <Button
        fullWidth
        variant="contained"
        disabled={!isButtonEnabled || isSubmitting}
        onClick={handleSubmit}
        sx={{ py: 1.2, borderRadius: 2 }}
      >
        {isSubmitting ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : (isModeEdit ? "Save" : "Add")}
      </Button>
    </CustomCard>
  );
}

export default StudentForm;
