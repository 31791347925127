import React from "react";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import config from "../../config/config";
import { TextField } from "@mui/material";

const googleMapsApiKey = config.googleApiKey;
const mapLibraries = config.mapLibraries;

const AddressSearchBox = ({ onLoad, value, onChange, sx = {} }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey,
    libraries: mapLibraries,
    language: "en",
    region: "IN",
  });

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <StandaloneSearchBox onLoad={onLoad}>
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        placeholder="Search for a location"
        label="Search Address"
        name="address"
        value={value}
        onChange={onChange}
        sx={{ ...sx }}
        InputLabelProps={{
          shrink: value !== "" && value !== undefined, // Ensures label floats if there's a value
        }}
      />
    </StandaloneSearchBox>
  )
}

export default AddressSearchBox;