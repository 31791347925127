export const PERMISSION_CODE = {
  PROFILE: {
    UPDATE: 100,
    DELETE: 101,
  },

  STUDENT: {
    VIEW: 111,
    CREATE: 112,
    UPDATE: 113,
    DELETE: 114,
    VIEW_ATTENDANCE: 115,
  },

  ATTENDANCE: {
    VIEW: 121,
    ASSIGN_SEAT_OR_CHECK_IN: 122,
    RELEASE_SEAT_OR_CHECK_OUT: 123,
  },

  LOCATION: {
    VIEW: 131,
    CREATE: 132,
    UPDATE: 133,
    DELETE: 134,
    UPDATE_SETTINGS: 135,
  },

  FLOOR: {
    VIEW: 141,
    CREATE: 142,
    UPDATE: 143,
    DELETE: 144,
    MANAGE_SEATS: 145,
  },

  STAFF_ROLE: {
    VIEW: 151,
    CREATE: 152,
    UPDATE: 153,
    DELETE: 154,
  },

  MEMBER: {
    VIEW: 161,
    CREATE: 162,
    UPDATE: 163,
    DELETE: 164,
  },

  WALLET: {
    VIEW: 171,
    ADD_MONEY: 172,
    WITHDRAW_MONEY: 173,
    MANAGE_TRANSACTIONS: 174,
  },

  OFFER: {
    VIEW: 181,
    CREATE: 182,
    UPDATE: 183,
    DELETE: 184,
    SUBSCRIBE: 185,
  },

  SUBSCRIPTION: {
    VIEW: 191,
    CONFIRM_PAYMENT: 192,
    CANCEL: 193,
    RENEW: 194,
  },

  PLAN: {
    VIEW: 201,
    CHANGE: 202,
    VIEW_MY_SUBSCRIPTION: 203,
  },
};