import React from "react";
import { Grid2, Typography, Box, Chip } from "@mui/material";
import CustomCard from "../Containers/Card";
import { EventAvailable } from "@mui/icons-material";

const OfferDetailCard = ({ duration = {}, price = "", finalPrice = "", discount = {}, totalSubscribers = 0 }) => {
  return (
    <CustomCard>
      <Grid2 container spacing={3}>
        <Grid2 size={{ xs: 12 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "primary.main",
              color: "primary.contrastText",
              py: 1.5,
              borderRadius: 2,
            }}
          >
            <EventAvailable fontSize="small" sx={{ mr: 1 }} />
            <Typography variant="h6" fontWeight="bold">
              {duration?.value} {duration?.unit}
            </Typography>
          </Box>
        </Grid2>

        {/* Price and Discount */}
        <Grid2 size={{ xs: 12 }} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingX: 2 }}>
          <Typography
            variant="h5"
            fontWeight="bold"
            color="text.primary"
          >
            <Typography
              component="span"
              sx={{
                textDecoration: "line-through",
                color: "text.secondary",
                mr: 1,
              }}
            >
              ₹{price}
            </Typography>
            ₹{finalPrice}
          </Typography>
          <Chip
            label={
              discount?.type === "fixed"
                ? `₹${discount?.value} OFF`
                : `${discount?.value}% OFF`
            }
            color="success"
            size="small"
          />
        </Grid2>

        <Grid2 size={{ xs: 12 }} sx={{
          paddingY: 1,
          paddingX: 2,
          borderBottom: "1px solid",
          borderColor: "divider",
          backgroundColor: "action.hover"
        }}>
          <Typography
            variant="caption"
            fontWeight="bold"
            color="text.primary"
          >
            Total Seats Reserved: {totalSubscribers || 0}
          </Typography>
        </Grid2>
      </Grid2>
    </CustomCard>
  );
};

export default OfferDetailCard;
