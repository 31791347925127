import React from "react";
import { Grid2 } from "@mui/material";
import Kpis from "../components/Dashboard/Kpis";
import StudentsSection from "../components/Dashboard/StudentsSection";
import PageContainer from "../components/Containers/PageContainer";
import RevenueChart from "../components/Dashboard/RevenueChart";

const Dashboard = () => {
  const revenueData = [
    { month: "Jan", revenue: 0 },
    { month: "Feb", revenue: 0 },
    { month: "Mar", revenue: 0 },
    { month: "Apr", revenue: 0 },
    { month: "May", revenue: 0 },
    { month: "Jun", revenue: 0 },
    { month: "Jul", revenue: 0 },
    { month: "Aug", revenue: 0 },
    { month: "Sep", revenue: 0 },
    { month: "Oct", revenue: 0 },
    { month: "Nov", revenue: 0 },
    { month: "Dec", revenue: 0 },
  ];

  const students = [];

  return (
    <PageContainer>
      <Grid2 container spacing={3}>
        <Grid2 size={{ xs: 12, md: 12 }}>
          <Kpis />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <RevenueChart data={revenueData} />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <StudentsSection students={students} />
        </Grid2>
      </Grid2>
    </PageContainer>
  );
};

export default Dashboard;
