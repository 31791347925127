import React from "react";
import { Box, Typography, Button, Divider, Link, Grid2 } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { planApi } from "../../services/api";
import moment from "moment";
import config from "../../config/config";
import CustomCard from "../Containers/Card";
import { useQuery } from "react-query";
import reduxData from "../../utils/useReduxData";
import { checkPermission, hasPermission } from "../../middleware/permission";
import { PERMISSION_CODE } from "../../config/permissionCodes";

const { termsAndConditionUrl } = config;

const SubscriptionPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { getActiveSubscription } = planApi();

  const hasViewPermission = hasPermission(PERMISSION_CODE.PLAN.VIEW)

  const { data: myPlan, isLoading } = useQuery(
    ["my-active-plan"],
    async () => await getActiveSubscription(),
    {
      retry: false, // Disable retries on error
    }
  );

  reduxData("subscription", "set")(myPlan);
  const formatDate = (dateString) => moment(dateString).format("DD MMM YYYY");

  if (isLoading) {
    return null;
  } else if (!myPlan || !Object.keys(myPlan).length) {
    navigate("/pricing");
    return null;
  }

  const totalMonths = moment.duration(myPlan.planInfo?.duration?.value, myPlan.planInfo?.duration?.unit).asMonths();
  const perMonthPrice = (myPlan.planInfo?.finalPrice / totalMonths).toFixed(2);


  return (
    <CustomCard>
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Active Subscription
      </Typography>
      <Typography variant="body2" color="text.secondary" gutterBottom>
        Manage your current subscription plan. View our{" "}
        <Link href={termsAndConditionUrl} target="_blank" underline="hover">
          Terms of Service
        </Link>
        {" "} and {" "}
        <Link href={termsAndConditionUrl} target="_blank" underline="hover">
          Privacy Policy
        </Link>
        .
      </Typography>

      <Divider sx={{ my: 2 }} />

      {/* Plan Details */}
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, lg: 3 }}>
          <Typography variant="subtitle1">
            Plan Name:
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {myPlan?.planInfo?.title}
          </Typography>
        </Grid2>

        <Grid2 size={{ xs: 12, lg: 3 }}>
          <Typography variant="subtitle1">
            Price:
          </Typography>
          <Typography variant="body2" color="text.secondary">
            ₹{perMonthPrice} / Month  {totalMonths > 1 ? ` * ${totalMonths}` : ""}
          </Typography>
        </Grid2>

        <Grid2 size={{ xs: 12, lg: 3 }}>
          <Typography variant="subtitle1">
            Status:
          </Typography>
          <Typography
            variant="body2"
            fontWeight="bold"
            sx={{
              color: myPlan.status === "active" ? theme.palette.success.main : theme.palette.error.main,
            }}
          >
            {myPlan.status.toUpperCase()}
          </Typography>
        </Grid2>

        <Grid2 size={{ xs: 12, lg: 3 }}>
          <Typography variant="subtitle1">
            Subscription Period:
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {formatDate(myPlan.startDate)} - {formatDate(myPlan.endDate)}
          </Typography>
        </Grid2>
      </Grid2>

      <Divider sx={{ my: 3 }} />

      {/* Features Section */}
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Plan Features
      </Typography>
      <Grid2 container spacing={2}>
        {Object.entries(myPlan?.planInfo?.features).map(([feature, value]) => (
          <Grid2 size={{ xs: 12, sm: 6 }} key={feature}>
            <Typography
              variant="body2"
              display="flex"
              alignItems="center"
              color="text.secondary"
            >
              {typeof value === "boolean" ? (
                value ? <CheckIcon color="success" sx={{ mr: 1 }} /> : <CloseIcon color="error" sx={{ mr: 1 }} />
              ) : (
                <Typography component="span" fontWeight="bold" sx={{ mr: 1 }}>
                  {value}
                </Typography>
              )}
              {feature.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}
            </Typography>
          </Grid2>
        ))}
      </Grid2>

      <Divider sx={{ my: 3 }} />

      {/* Actions */}
      <Box display="flex" justifyContent="space-between">
        <Button
          variant="contained"
          color="primary"
          sx={{ borderRadius: 3, fontWeight: "bold" }}
          onClick={() => navigate("/pricing")}
          disabled={!hasViewPermission}
        >
          Change Plan
        </Button>
      </Box>
    </CustomCard>
  );
};

export default checkPermission(SubscriptionPage, PERMISSION_CODE.PLAN.VIEW_MY_SUBSCRIPTION);
