import * as moment from "moment";

export const extractSetting = ({ setting = {} }) => {
  const { openCloseTiming = [], isOpen } = setting;

  // Get current weekday and corresponding timings
  const currentWeekDay = moment().format("dddd");
  const currentDayStatus = openCloseTiming.find((item) => item.day === currentWeekDay);

  const openingTime = currentDayStatus?.openTime || "00:00";
  const closingTime = currentDayStatus?.closeTime || "00:00";

  // Split time strings for comparison
  const [openingHour, openingMinute] = openingTime.split(":").map(Number);
  const [closingHour, closingMinute] = closingTime.split(":").map(Number);

  // Check if closing time is greater than or equal to opening time
  const isCloseTimeGreaterThanOpenTime = closingHour > openingHour || (closingHour === openingHour && closingMinute > openingMinute);

  const openingMoment = moment(openingTime, "HH:mm");
  const closingMoment = moment(closingTime, "HH:mm").add(
    isCloseTimeGreaterThanOpenTime ? 0 : 1,
    "day"
  );

  const totalWorkingHours = closingMoment.diff(openingMoment, "hours");
  const totalWorkingMinutes = closingMoment.diff(openingMoment, "minutes");
  const totalWorkingSeconds = closingMoment.diff(openingMoment, "seconds");

  const currentlyOpen = isOpen && moment().isBetween(openingMoment, closingMoment);

  return {
    ...setting,
    openingTime,
    closingTime,
    totalWorkingHours,
    totalWorkingMinutes,
    totalWorkingSeconds,
    currentlyOpen,
  };
};