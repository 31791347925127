import React from "react";
import { Skeleton, Box } from "@mui/material";
import CustomCard from "../Containers/Card";

const StaffCardSkeleton = () => {
  return (
    <CustomCard>
      {/* Header Section */}
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

        <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
          <Skeleton variant="text" width={100} height={30} />

          <Skeleton variant="text" width={20} height={30} />
        </Box>

        <Skeleton variant="text" width={20} height={30} />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Skeleton variant="text" width={150} height={20} />

        <Skeleton variant="text" width={20} height={20} />
      </Box>
    </CustomCard>
  );
};

export default StaffCardSkeleton;
