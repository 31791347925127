import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import PublicLayout from "../components/Layouts/Public";
import config from "../config/config";

const contactUsLink = config.contactUsUrl;

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <PublicLayout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          px: 2,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: 'primary.main',
            fontSize: { xs: '4rem', sm: '5rem', md: '6rem' }, // Responsive font size
            fontWeight: 'bold',
            mb: 2, // Margin below the heading
          }}
        >
          404
        </Typography>

        <Typography
          variant="h4"
          sx={{
            mb: 2,
            fontSize: { xs: '1.5rem', sm: '2rem' }, // Responsive font size for smaller screens
            fontWeight: 500,
          }}
        >
          Oops! The page you're looking for doesn't exist.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            mb: 4,
            maxWidth: '500px',
            fontSize: { xs: '0.875rem', sm: '1rem' }, // Adjust text size for readability
          }}
        >
          It seems you've hit a broken link or entered a URL that doesn't exist. Please check the address or return to the homepage.
        </Typography>

        <Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center' }}>
          <Button
            variant="contained"
            sx={{
              textTransform: 'none',
              width: { xs: '100%', sm: 'auto' }, // Button should take full width on small screens
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            Go to Home
          </Button>

          <Button
            component={Link}
            to={contactUsLink}
            variant="outlined"
            color="secondary"
            target='_blank'
            sx={{
              textTransform: 'none',
              width: { xs: '100%', sm: 'auto' }, // Button should take full width on small screens
            }}
          >
            Contact Support
          </Button>
        </Box>
      </Box>
    </PublicLayout>
  );
};

export default NotFound;
