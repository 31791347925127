import React, { useEffect, useState } from "react";
import { Field } from "formik";
import { TextField } from "@mui/material";
import { Email } from "@mui/icons-material";
import { authApi } from "../../services/api";

const EmailField = ({ name = "email", label = "Email", errors, setErrors, touched, setFieldValue, endAdornment, disabled }) => {
  const [emailError, setEmailError] = useState("");
  const { checkEmail } = authApi();

  useEffect(() => {
    setErrors({ ...errors, [name]: emailError });
    // eslint-disable-next-line
  }, [emailError]);

  return (
    <Field
      as={TextField}
      fullWidth
      label={label}
      name={name}
      variant="standard"
      disabled={disabled}
      error={(touched?.[name] && Boolean(errors?.[name])) || emailError}
      helperText={(touched?.[name] && errors?.[name]) || emailError}
      InputProps={{
        startAdornment: <Email sx={{ mr: 1 }} />,
        endAdornment,
        // endAdornment:(
        //   <InputAdornment sx={{ marginBottom: "5px" }}>@gmail.com</InputAdornment>
        // ),
      }}
      onChange={async (e) => {
        setFieldValue(name, e.target.value);
        setEmailError("");
        if (e.target.value.includes(".co")) {
          try {
            await checkEmail(e.target.value);
          } catch (error) {
            setEmailError(error.message);
          }
        }
      }}
      sx={{ mb: 2 }}
    />
  );
};

export default EmailField;
