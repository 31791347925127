import React from "react";
import { Box, Button, Grid2, Typography, Chip, IconButton, Divider, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BusinessIcon from "@mui/icons-material/Business";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Add, Delete, QrCode } from "@mui/icons-material";
import CustomCard from "../Containers/Card";
import { useSelector } from "react-redux";
import { getCompleteAddress } from "../../utils/location";
import { useNavigate } from "react-router-dom";
import PdfGenerator from "../Pdf";
import Swal from "sweetalert2";
import { profileApi } from "../../services/api";
import { clearStore } from "../../utils/clearStore";
import { hasPermission } from "../../middleware/permission";
import { PERMISSION_CODE } from "../../config/permissionCodes";
import { hasFeature, showPlanUpgrade } from "../../middleware/subscription";
import { PlanUpgrade } from "../Icons/PlanUpgrade";

const SettingsPage = () => {
  const navigate = useNavigate();
  const profile = useSelector(state => state.profile);
  const library = useSelector(state => state.library);
  const locations = useSelector(state => state.locations);
  const currentLocation = useSelector(state => state.currentLocation);

  const hasAddPermission = hasPermission(PERMISSION_CODE.LOCATION.CREATE);
  const accountCanDelete = hasPermission(PERMISSION_CODE.PROFILE.DELETE);
  const hasFeaturePermission = hasFeature("locations");

  const { deleteAccount } = profileApi();

  const contactNumber = "+" + profile?.contactNumber?.countryCode + " " + profile?.contactNumber?.phoneNumber

  const handleAddLocation = () => {
    if (!hasFeaturePermission) return showPlanUpgrade();

    navigate("/locations/add")
  }

  const handleDeleteAccount = () => {
    //  show sweet alert for delete account confirmation
    Swal.fire({
      type: "warning",
      title: "Delete Account",
      text: "Are you sure you want to delete your account?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteAccount();

          Swal.fire({
            type: "success",
            title: "Account Deleted",
            text: "Your account has been deleted successfully.",
            icon: "success",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK"
          })
          navigate("/");
          clearStore();
        } catch (error) {
          Swal.fire({
            type: "error",
            title: "Error",
            text: error.message,
            icon: "error",
            confirmButtonColor: "#3085d6",
            cancelButtonText: "Cancel"
          })
        }
      }
    })
  }

  return (
    <Box>
      {/* Account Details */}
      <CustomCard>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            Account Detail
          </Typography>
          <IconButton color="primary" disabled={true} >
            <EditIcon />
          </IconButton>
        </Stack>
        <Divider sx={{ mb: 2 }} />
        <Grid2 container spacing={2} alignItems="center">
          <Grid2 size={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
            <Box display="flex" alignItems="center" gap={1}>
              <AccountCircleIcon />
              <Typography>{profile?.name}</Typography>
            </Box>
            <Typography variant="caption" color="text.secondary">
              Full Name
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
            <Box display="flex" alignItems="center" gap={1}>
              <EmailIcon />
              <Typography>{profile?.email}</Typography>
            </Box>
            <Typography variant="caption" color="text.secondary">
              Email
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
            <Box display="flex" alignItems="center" gap={1}>
              <PhoneIcon />
              <Typography>{contactNumber}</Typography>
            </Box>
            <Typography variant="caption" color="text.secondary">
              Phone Number
            </Typography>
          </Grid2>
        </Grid2>
      </CustomCard>

      {/* Business Details */}
      <CustomCard>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Business Detail
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Grid2 container spacing={2} alignItems="center">
          <Grid2 size={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
            <Box display="flex" alignItems="center" gap={1}>
              <BusinessIcon />
              <Typography>{library?.name}</Typography>
            </Box>
            <Typography variant="caption" color="text.secondary">
              Library Name
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
            <Box display="flex" alignItems="center" gap={1}>
              <LocationOnIcon />
              <Typography>{currentLocation?.name}</Typography>
            </Box>
            <Typography variant="caption" color="text.secondary">
              Location Name
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
            <Box display="flex" alignItems="center" gap={1}>
              <QrCode />
              <PdfGenerator
                libraryName={library.name}
                branchName={currentLocation.name}
                libraryId={library._id}
                locationId={currentLocation._id}
              />
            </Box>
            <Typography variant="caption" color="text.secondary">
              Qr Code
            </Typography>
          </Grid2>
        </Grid2>
      </CustomCard>

      {/* Location Details */}
      <CustomCard>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Location Details
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          Used on student seat booking confirmations and your S3 Library bill.
        </Typography>
        <Divider sx={{ mb: 1 }} />
        {
          locations?.map((location, index) => (
            <CustomCard key={`${location?.name}_${index}`} showAsList>
              <Grid2 container spacing={2} alignItems="center">
                <Grid2 size={{ xs: 12, sm: 4, md: 4 }}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <BusinessIcon />
                    <Typography>{location.name}</Typography>
                    {currentLocation._id === location._id ? <Chip label="Selected" color="warning" size="small" /> : ""}
                  </Box>
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 8, md: 8 }}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <LocationOnIcon />
                    <Typography color="textDisabled">{getCompleteAddress(location)}</Typography>
                  </Box>
                </Grid2>
              </Grid2>
            </CustomCard>
          ))
        }
        <Box mt={3} textAlign="right">
          <Button variant="contained" color="success" endIcon={!hasFeaturePermission ? <PlanUpgrade /> : null} startIcon={<Add />} onClick={handleAddLocation} disabled={!hasAddPermission}>
            Another Location
          </Button>
        </Box>
      </CustomCard>

      {/* Delete account */}
      <CustomCard sx={{ xs: 12 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            Delete Account
          </Typography>
          <IconButton color="error" onClick={handleDeleteAccount} disabled={!accountCanDelete} >
            <Delete />
          </IconButton>
        </Stack>
        <Divider sx={{ mb: 2 }} />
        <Typography variant="body2" color="text.secondary" gutterBottom>
          Deleting your account will remove all your data from our system.
        </Typography>
      </CustomCard>
    </Box>
  );
};

export default SettingsPage;
