import React, { useState } from "react";
import StudentItemForAttendance from "../../components/Booking/StudentItemForAttendance";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { bookingsApi, floorsApi, studentApi } from "../../services/api";
import Swal from "sweetalert2";
import { Button } from "@mui/material";
import { Add, ArrowBack } from "@mui/icons-material";
import PageContainer from "../../components/Containers/PageContainer";
import QueryContainer from "../../components/Containers/QueryContainer/Main";
import { hasFeature, showPlanUpgrade } from "../../middleware/subscription";
import { PlanUpgrade } from "../../components/Icons/PlanUpgrade";

const AddAttendance = () => {
  const navigate = useNavigate();
  const [floors, setFloors] = useState([]);
  const [availableSeats, setAvailableSeats] = useState({});
  const hasFeaturePermission = hasFeature("addStudent");

  const { refetch: floorRefetch } = useQuery(
    ["floors"],
    async () => await floorsApi().getAllWithSeats(),
    {
      retry: false, // Disable retries on error
      onSuccess: async ({ results }) => {
        const updatedSeats = {};
        const newFloors = [];

        results.forEach((floorWithSeats) => {
          const { seats, ...floor } = floorWithSeats;

          newFloors.push(floor);
          updatedSeats[floor._id] = seats.filter((seat) => seat.status === 'available').map((seat) => ({ _id: seat._id, number: seat.number }));
        });

        setAvailableSeats(updatedSeats);
        setFloors(newFloors);
      }
    }
  );

  const handleSeatBooking = (studentRefetch) => async (studentId, floorId, seatId) => {
    const selectedFloor = floors.find((floor) => floor._id === floorId);
    const selectedSeat = availableSeats[floorId].find((seat) => seat._id === seatId);

    const floorName = selectedFloor?.name;
    const seatNumber = selectedSeat?.number;

    return await handleAssignSeatAndCheckIn({ studentId, studentRefetch, floorId, floorName, seatId, seatNumber });
  };

  const handleCheckIn = (studentRefetch) => async (studentId) => await handleAssignSeatAndCheckIn({ studentId, studentRefetch });

  const handleAssignSeatAndCheckIn = async ({ studentId, studentRefetch, floorId = null, floorName = null, seatId = null, seatNumber = null, forceAssign = false, forceBooking = false }) => {
    try {
      await bookingsApi().assignSeat_or_checkIn({ studentId, floorId, floorName, seatId, seatNumber, forceAssign, forceBooking });
      studentRefetch && studentRefetch();
      floorRefetch();
    } catch (error) {
      if (error['statusCode'] === 402) {
        Swal.fire({
          icon: "error",
          title: "Sorry, This Student doesn't have a subscription",
          text: error["message"] || "Something went wrong",
          showCancelButton: true,
          confirmButtonText: "Yes, I want to assign this seat",
          cancelButtonText: "No, I want to cancel",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await handleAssignSeatAndCheckIn({ studentId, studentRefetch, floorId, floorName, seatId, seatNumber, forceAssign: true, forceBooking });
          }
        });
        return;
      } else if (error['statusCode'] === 409) {
        Swal.fire({
          icon: "error",
          title: "Seat Already booked or occupied",
          text: error["message"] || "Something went wrong",
          showCancelButton: true,
          confirmButtonText: "Force assign the seat",
          cancelButtonText: "cancel",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await handleAssignSeatAndCheckIn({ studentId, studentRefetch, floorId, floorName, seatId, seatNumber, forceAssign, forceBooking: true });
          }
        });
        return;
      }

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error["message"] || "Something went wrong",
      });
    }
  };

  const handleAddStudent = () => {
    if (!hasFeaturePermission) return showPlanUpgrade();

    navigate(`/students/add`);
  }

  const sortOptions = [
    { key: "name", label: "Name" },
  ];

  const filters = {
    // subscriptionType: [
    //   { value: "", label: "All" },
    //   { value: "fixed", label: "Subscribed" },
    //   { value: "flexible", label: "Guest" },
    //   { value: "flexible", label: "Reserved" },
    //   { value: "flexible", label: "Booked" },
    // ]
  };

  const header = [
    { size: 1, align: "start", key: "image", label: "Image" },
    { size: 2.5, align: "start", key: "name", label: "Name" },
    { size: 2.5, align: "center", key: "type", label: "Subscription Type" },
    { size: 2.5, align: "center", key: "floor", label: "Floor" },
    { size: 2.5, align: "center", key: "seat", label: "Seat" },
    { size: 1, align: "end", key: "action", label: "Action" },
  ];

  const goBack = (
    <Button
      variant="outlined"
      size="small"
      color="primary"
      onClick={() => navigate(-1)}
      startIcon={<ArrowBack />}
    >
      Back
    </Button>
  );

  return (
    <PageContainer headerContent={goBack}>
      <QueryContainer
        fetchData={studentApi().getAll}
        filters={filters}
        sortOptions={sortOptions}
        header={header}
        getItems={(students, refetch) => {
          return students.map((student) => {
            const isCheckedIn = student?.booking?.status === "running";

            if (isCheckedIn) {
              return null;
            }

            return (
              <StudentItemForAttendance
                student={student}
                header={header}
                floors={floors}
                availableSeats={availableSeats}
                onSeatBooking={handleSeatBooking(refetch)}
                onCheckIn={handleCheckIn(refetch)}
              />
            )
          });
        }}
        noDataFound={{
          title: 'No Students Found',
          subTitle: "Click on the button below to add a new students.",
          buttonText: "Add Students",
          ButtonIcon: hasFeaturePermission ? Add : PlanUpgrade,
          onRefresh: handleAddStudent,
        }}
      />
    </PageContainer>
  );
};

export default AddAttendance;
