import React, { useState } from "react";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import config from "../../config/config";
import { Box, IconButton } from "@mui/material";
import MyLocationIcon from "@mui/icons-material/MyLocation";

const googleMapsApiKey = config.googleApiKey;
const mapLibraries = config.mapLibraries;

const CustomMap = ({ location, onClick, onLoad }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey,
    libraries: mapLibraries,
    language: "en",
    region: "IN",
  });

  const [map, setMap] = useState(null);

  const handleCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          if (map) {
            map.panTo(currentLocation); // Pan the map to the current location
            onClick({ latLng: { lat: () => currentLocation.lat, lng: () => currentLocation.lng } }); // Simulate map click to reposition marker
          }
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              alert("Location permission denied. Please allow access to use this feature.");
              break;
            case error.POSITION_UNAVAILABLE:
              alert("Location information is unavailable. Please try again.");
              break;
            case error.TIMEOUT:
              alert("The request to get your location timed out. Please try again.");
              break;
            default:
              alert("An unknown error occurred while fetching your location.");
              break;
          }
          console.error("Geolocation error:", error);
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
      );
    } else {
      alert("Geolocation is not supported by your browser. Please use a different browser or device.");
    }
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <Box sx={{ height: { xs: "400px", md: "100%" }, borderRadius: "8px", position: "relative", overflow: "hidden" }}>
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "100%" }}
        center={location}
        zoom={15}
        onClick={onClick}
        onLoad={(mapInstance) => {
          setMap(mapInstance);
          onLoad(mapInstance);
        }}
        options={{
          streetViewControl: false,
          rotateControl: false,
          cameraControl: false,
        }}
      >
        <MarkerF position={location} />
      </GoogleMap>

      {/* Current Location Icon */}
      <IconButton
        aria-label="current location"
        onClick={handleCurrentLocation}
        sx={{
          position: "absolute",
          bottom: 116,
          right: 10,
          backgroundColor: "white",
          color: "#3f51b5",
          borderRadius: "50%",
          padding: "8px",
          zIndex: 1,
          boxShadow: "0 2px 5px rgba(0,0,0,0.3)",
          "&:hover": { backgroundColor: "#f5f5f5" },
        }}
      >
        <MyLocationIcon />
      </IconButton>
    </Box>
  );
};

export default React.memo(CustomMap);
