import React, { useState } from "react";
import { Box, Typography, Collapse, IconButton, useTheme } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const CollapsableSection = ({ children, title, bgcolor, color, defaultOpen = false }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(defaultOpen);

  bgcolor = theme.palette.primary.main;
  color = theme.palette.common.white;

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
        onClick={() => setExpanded(!expanded)}
        bgcolor={bgcolor}
        py={1}
        px={2}
        borderRadius={1}
        cursor="pointer"
        sx={{
          "&:hover": {
            bgcolor: "primary.dark",
          },
        }}
      >
        <Typography variant="body1" sx={{ color }}>{title}</Typography>
        <IconButton size="small" sx={{ color }} onClick={() => setExpanded(!expanded)}>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>

      <Collapse
        in={expanded}
        timeout={300}
        sx={{ paddingY: 1 }}
      >
        {children}
      </Collapse>
    </>
  );
};

export default CollapsableSection;
