import React from "react";
import { Box, Card, Typography } from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

const KpiCard = ({ Icon, title, value, trend, up }) => {
  const ArrowIcon = up ? (
    <ArrowUpward color="success" fontSize="small" />
  ) : (
    <ArrowDownward color="error" fontSize="small" />
  );

  return (
    <Card sx={{ padding: 2 }}>
      <Box display="flex" alignItems="center" mb={1}>
        <Box mr={1}>
          <Icon fontSize="small" color="primary" />
        </Box>
        <Typography variant="h6" color="primary.main">
          {title}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">{value}</Typography>
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            {ArrowIcon}
          </Box>
          <Typography variant="h6" color="text.secondary">
            {`${trend} %`}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default KpiCard;
