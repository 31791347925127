import React from 'react';
import { Box, Typography } from '@mui/material';
import { Brightness4, WbSunny } from '@mui/icons-material';

const ThemeSwitching = ({ themeMode }) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: themeMode === 'light' ? 'white' : 'black',
        color: 'primary.main',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1300,
        transition: 'background-color 0.5s ease',
        flexDirection: 'column',
      }}
    >
      {themeMode === 'dark' ? <Brightness4 sx={{ fontSize: 80 }} /> : <WbSunny sx={{ fontSize: 80 }} />}
      <Typography variant="h5">Switching to {themeMode === 'light' ? 'Light' : 'Dark'} mode</Typography>
    </Box>
  );
};

export default React.memo(ThemeSwitching);
