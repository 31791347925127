import { ToggleButton, ToggleButtonGroup, Chip, useTheme, Box } from "@mui/material";

const DurationToggle = ({ selectedDuration, setSelectedDuration, durationAndDiscounts }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        overflowX: "auto",
        paddingX: 1, // Add some padding on sides
      }}
    >
      <ToggleButtonGroup
        value={selectedDuration}
        exclusive
        size="small"
        onChange={(event, newValue) => {
          if (!newValue) return;
          setSelectedDuration(newValue);
        }}
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          gap: 1, // Space between buttons
          overflowX: "auto",
          maxWidth: "100%",
          "&::-webkit-scrollbar": { display: "none" } // Hide scrollbar for mobile
        }}
      >
        {durationAndDiscounts.map(({ duration, discount }) => (
          <ToggleButton
            key={duration.value}
            value={duration}
            sx={{
              flexShrink: 0, // Prevents shrinking
              minWidth: "90px", // Ensures touch-friendly size
              paddingX: 2,
              textTransform: "capitalize",
              fontWeight: "bold",
              transition: "all 0.2s ease-in-out",
              color: theme.palette.text.primary,
              "&.Mui-selected": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                "&:hover": { backgroundColor: theme.palette.primary.dark }
              },
            }}
          >
            {duration.value} {duration.unit}

            {discount.value > 0 && (
              <Chip
                label={`${discount.value}% Off`}
                size="small"
                color="success"
                sx={{ ml: 1 }}
              />
            )}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
};

export default DurationToggle;
