import React from "react";
import { Badge, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const MenuItem = ({ Icon, text, link, badgeContent = 0, toggleDrawer, onClick = null }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const paths = location.pathname.split("/").filter((x) => x);

  const sanitizeString = (str) => str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase(); // Remove all symbols and convert to lowercase

  const handleClick = () => {
    setTimeout(() => {
      navigate(link);
    }, 300);
    toggleDrawer();
  };

  const isSelected = sanitizeString(text) === sanitizeString(paths[0] || "");

  return (
    <ListItem
      button
      onClick={onClick || handleClick}
      sx={{
        typography: "body1",
        color: "text.primary",
        cursor: "pointer",
        marginY: 0.5,
        borderRadius: 50,
        backgroundColor: isSelected ? "action.hover" : "",
        boxShadow: isSelected ? 2 : 0,
      }}
    >
      <ListItemIcon sx={{ color: "inherit" }}>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={text} />
      <Badge badgeContent={badgeContent} color="error" />
    </ListItem>
  );
};

export default React.memo(MenuItem);
