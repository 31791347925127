import React from "react";
import { Chip, Box, IconButton } from "@mui/material";
import { Avatar, Typography, Tooltip } from "@mui/material";
import formattedContactNumber from "../../utils/formateContactNumber";
import { RowBuilder } from "../Containers/QueryContainer/RowBuilder";
import { Delete, Edit } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { hasPermission } from "../../middleware/permission";
import { PERMISSION_CODE } from "../../config/permissionCodes";

const StaffMembers = ({ member, header, locationName, onView, onEdit, onDelete }) => {
  const { name, email, image, contactNumber, isActive, isOwner } = member;
  const myRole = useSelector(state => state.staffRole);

  const canView = !isOwner || myRole.isOwner;

  const hasEditPermission = hasPermission(PERMISSION_CODE.MEMBER.UPDATE);
  const hasDeletePermission = hasPermission(PERMISSION_CODE.MEMBER.DELETE);

  const handleEdit = (member) => (e) => {
    e.stopPropagation(); // Prevent ListItem's onClick from firing
    onEdit(member);
  }

  const handleDelete = (member) => (e) => {
    e.stopPropagation(); // Prevent ListItem's onClick from firing
    isOwner || onDelete(member);
  }

  return <RowBuilder
    onClick={canView ? () => onView(member) : null}
    header={header}
    columns={{
      image: {
        primary: <Avatar src={image || ""} alt={name} sx={{ width: 60, height: 60 }} />,
      },
      name: {
        primary: (
          <Tooltip title={email}>
            <Typography variant="body1" noWrap>
              {name}
            </Typography>
          </Tooltip>
        ),
        secondary: <Typography variant="body2" color="textSecondary" noWrap>{email}</Typography>,
      },
      contact: {
        primary: <Typography variant="body2" noWrap>{formattedContactNumber(contactNumber)}</Typography>,
      },
      status: {
        primary: (
          <Chip
            label={isActive ? "Active" : "Inactive"}
            color={isActive ? "success" : "error"}
            size="small"
            sx={{ width: "50%", color: "white" }}
          />
        ),
      },
      location: {
        primary: <Typography variant="body2" noWrap>{locationName}</Typography>,
      },
      actions: {
        primary: (
          <Box sx={{ flex: 0.5, display: "flex", gap: 1 }}>
            <IconButton
              size="small"
              onClick={canView ? handleEdit(member) : null}
              color="primary"
              disabled={!canView || !hasEditPermission}
            >
              <Edit />
            </IconButton>
            <IconButton
              size="small"
              onClick={!isOwner ? handleDelete(member) : null}
              color="error"
              disabled={isOwner || !hasDeletePermission}
            >
              <Delete />
            </IconButton>
          </Box>
        ),
      },
    }}
  />
};

export default StaffMembers;
