import React from "react";
import { Grid2, useMediaQuery, Divider } from "@mui/material";
import CustomCard from "../Containers/Card";
import StudentInfo from "./StudentInfo";
import SubscriptionInfo from "./SubscriptionInfo";

const StudentCard = ({ name, profilePicture, email, isActive, contactNumber, subscription }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <CustomCard>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, sm: subscription ? 7 : 12 }} alignItems={"center"}>
          <StudentInfo
            name={name}
            profilePicture={profilePicture}
            email={email}
            isActive={isActive}
            contactNumber={contactNumber}
            isSubscribed={!!subscription}
          />
        </Grid2>

        {(isMobile && subscription) && <Grid2 size={{ xs: 12 }} >  <Divider /> </Grid2>}

        {
          subscription &&
          <Grid2 size={{ xs: 12, sm: 5 }}>
            <SubscriptionInfo {...subscription} />
          </Grid2>
        }

      </Grid2>
    </CustomCard>
  );
};

export default StudentCard;
