import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
  typography: {
    fontFamily: 'Quicksand, League Spartan, Jaldi, Numans, Dongle, Inter, Onest, Lexend', // Global fonts
    h1: { fontFamily: 'League Spartan', fontWeight: 700, fontSize: '3.5rem' },
    h2: { fontFamily: 'League Spartan', fontWeight: 600, fontSize: '2.25rem' },
    h3: { fontFamily: 'League Spartan', fontWeight: 600, fontSize: '1.75rem' },
    h4: { fontFamily: 'League Spartan', fontWeight: 600, fontSize: '1.5rem' },
    h5: { fontFamily: 'League Spartan', fontWeight: 500, fontSize: '1.25rem' },
    h6: { fontFamily: 'League Spartan', fontWeight: 500, fontSize: '1.125rem' },
    body1: { fontFamily: 'Lexend', fontSize: '1rem', fontWeight: 400 },
    body2: { fontFamily: 'Numans', fontSize: '0.95rem', fontWeight: 400 },
    subtitle1: { fontFamily: 'Dongle', fontSize: '1.45rem', fontWeight: 400 },
    subtitle2: { fontFamily: 'Quicksand', fontSize: '1.125rem', fontWeight: 400 },
    button: { fontFamily: 'Inter', fontWeight: 600, fontSize: '1rem' },
    caption: { fontFamily: 'Lexend', fontSize: '0.875rem', fontWeight: 400 },
    overline: { fontFamily: 'Dongle', fontSize: '0.75rem', fontWeight: 400 },
  },
  palette: {
    primary: {
      main: '#08518b',
      light: '#E0F7FA', // Adjust as needed
    },
    secondary: {
      main: '#00b3de',
      light: '#FFFDE7', // Adjust as needed
    },
    background: {
      default: '#ffffff',
      paper: '#f9f9f9',
    },
    text: {
      primary: '#000000',
      secondary: '#5a5a5a',
    },
    action: {
      hover: '#E8EAF6',
    },
  },
});

export default lightTheme;
