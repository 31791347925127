export const set = (state, action) => {
  return action.payload; // Return a new state
};

export const add = (state, action) => {
  if (state?.length) {
    return [action.payload, ...state]; // Return a new state
  } else {
    return [action.payload];
  }
};

export const updateObj = (state, action) => {
  if (state) {
    return { ...state, ...action.payload };
  } else {
    return action.payload;
  }
};

/* The `remove` function removes an item from the state based on a specific key-value pair.*/
export const remove = (state, action) => {
  const { key, value } = action.payload;

  const newData = state?.filter((row) => value !== row[key]);

  return [...newData];
};

export const clear = (initialValue) => (state, action) => {
  return initialValue;
};