import React from "react";
import { Field } from "formik";
import { TextField, MenuItem, InputAdornment } from "@mui/material";
import { LocationOn } from "@mui/icons-material";

const LocationSelectField = ({ name = "locationId", label = "Select Location", locations, errors, touched, disabled, ...props }) => {
  return (
    <Field
      as={TextField}
      select
      fullWidth
      label={label}
      name={name}
      variant="standard"
      disabled={disabled}
      error={touched?.[name] && Boolean(errors?.[name])}
      helperText={touched?.[name] && errors?.[name]}
      InputProps={{
        startAdornment: <InputAdornment position="start" sx={{ color: "inherit" }} ><LocationOn /></InputAdornment>,
      }}
      sx={{ mb: 2 }}
      {...props}
    >
      {locations?.length > 0 ? (
        locations.map((location) => (
          <MenuItem key={location._id} value={location._id}>
            {location.name}
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled>No locations available</MenuItem>
      )}
    </Field>
  );
};

export default LocationSelectField;
