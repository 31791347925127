import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "@mui/material";
import { Edit } from "@mui/icons-material";

import PageContainer from "../../components/Containers/PageContainer";
import MemberForm from "../../components/Staffs/MemberForm";
import { PERMISSION_CODE } from "../../config/permissionCodes";
import { hasPermission } from "../../middleware/permission";

const MemberDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { member, role } = location.state;

  const hasEditPermission = hasPermission(PERMISSION_CODE.MEMBER.UPDATE);

  // Edit member
  const handleEditMember = () => {
    navigate(`/staffs/members/edit`, { state: { member, role } });
  };

  const editMemberButton = (
    <Button
      variant="contained"
      startIcon={<Edit />}
      onClick={handleEditMember}
      size="small"
      disabled={!hasEditPermission}
    >
      Edit
    </Button>
  );

  return (
    <PageContainer headerContent={editMemberButton}>
      <MemberForm
        mode="view"
        initialData={member}
      />
    </PageContainer>
  );
};

export default MemberDetail;
