import React from "react";
import { Box, IconButton, Drawer, Typography, Button, CircularProgress } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Clear, Redo, Save, Undo } from "@mui/icons-material";

const ToolBar = ({ undo, redo, clear, save, drawerOpen, toggleDrawer, discardChanges, isSubmitting }) => {
  const menuItems = [
    { onClick: undo, content: <Undo />, disabled: !undo },
    { onClick: redo, content: <Redo />, disabled: !redo },
    { onClick: clear, content: <>Clear <Clear /></>, disabled: false },
    { onClick: save, content: <>Save {isSubmitting ? <CircularProgress /> : <Save />}</>, disabled: !save || isSubmitting },
    { onClick: discardChanges, content: <>Discard Changes <Clear /></>, disabled: false, btnColor: "" },
  ];

  return (
    <>
      {/* For larger screens: Toolbar in a row */}
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          gap: 1,
        }}
      >
        {menuItems.map(({ onClick, content, disabled, btnColor }, index) => (
          <IconButton
            key={index}
            color="primary"
            size="small"
            onClick={onClick}
            disabled={disabled}
          >
            {content}
          </IconButton>
        ))}
      </Box>

      {/* For smaller screens: Toolbar as a drawer */}
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <IconButton onClick={toggleDrawer} color="primary">
          <MenuIcon />
        </IconButton>
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
          <Box sx={{ width: 250, padding: 2 }}>
            <Typography variant="h6" gutterBottom>
              Menu
            </Typography>
            {menuItems.map(({ onClick, content, disabled }, index) => (
              <Button
                key={index}
                fullWidth
                color="primary"
                onClick={onClick}
                disabled={disabled}
              >
                {content}
              </Button>
            ))}
          </Box>
        </Drawer>
      </Box>
    </>
  );
};

export default ToolBar;
