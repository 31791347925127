import React from "react";
import { Box, Typography, IconButton, Chip } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import CustomCard from "../Containers/Card";
import { extractSetting } from "../../utils/locationSetting.js";
import PdfGenerator from "../Pdf/index.js";
import { hasPermission } from "../../middleware/permission.js";
import { PERMISSION_CODE } from "../../config/permissionCodes.js";
import RatingStar from "../Common/RatingStar.js";
import { useNavigate } from "react-router-dom";

const LocationCard = ({ _id, name, address, setting = {}, reviews = {}, contactNumber, onDelete, onEdit, canDelete, isLocationValid, libraryName, libraryId }) => {
  const navigate = useNavigate();
  const { currentlyOpen, openingTime, closingTime } = extractSetting({ setting })

  // Calculate status and timing
  const status = `${openingTime} - ${closingTime}`;

  const hasEditPermission = hasPermission(PERMISSION_CODE.LOCATION.UPDATE);
  const hasDeletePermission = hasPermission(PERMISSION_CODE.LOCATION.DELETE);

  // Format contact number if it's an object
  const formattedContactNumber =
    typeof contactNumber === "object"
      ? `+${contactNumber?.countryCode} ${contactNumber?.phoneNumber}`
      : contactNumber || "Not Available";

  const handleViewReviews = () => navigate("reviews", { state: { locationId: _id } });

  // Render component
  return (
    <CustomCard>
      {/* Header Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h4" sx={{ marginRight: 1 }}>
            {name || "Unnamed Location"}
          </Typography>
          <Chip
            label={currentlyOpen ? "Open" : "Closed"}
            color={currentlyOpen ? "success" : "error"}
            size="small"
          />
        </Box>
        {!canDelete && (
          <Chip label="Selected Location" color="warning" size="small" />
        )}
      </Box>

      {/* Address Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="body2" color={isLocationValid ? "textPrimary" : "error"}>
          {isLocationValid ? address : "No Address Available. Please update the location"}
        </Typography>
      </Box>

      {/* Status and Phone Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2">
            Status:  {status}
          </Typography>
          <Typography variant="body2">
            Phone:  {formattedContactNumber}
          </Typography>

          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="caption" color="text.secondary">
              Qr Code:
            </Typography>
            <PdfGenerator
              libraryName={libraryName}
              libraryId={libraryId}
              branchName={name}
              locationId={_id}
            />
          </Box>

          <RatingStar rating={reviews['rating'] || 0} onClick={handleViewReviews} />
        </Box>

        <Box sx={{ display: "flex" }}>
          <IconButton
            color="primary"
            sx={{ padding: 0, paddingX: 2 }}
            onClick={onEdit}
            disabled={!hasEditPermission}
          >
            <Edit />
          </IconButton>
          <IconButton
            color="error"
            sx={{ padding: 0 }}
            onClick={onDelete}
            disabled={!hasDeletePermission || !canDelete}
          >
            <Delete />
          </IconButton>
        </Box>
      </Box>
    </CustomCard>
  );
};

export default LocationCard;
