import { Apartment, Chair } from "@mui/icons-material"
import { Box, Typography, useTheme } from "@mui/material"

export const FloorSeat = ({ floorName, seatNumber }) => {
  const theme = useTheme();

  return (
    <Box display='flex' gap={2}>
      <Typography
        variant="body1" color="text.secondary"
        sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
      >
        <Apartment fontSize="small" sx={{ color: theme.palette.text.secondary }} />
        {floorName}
      </Typography>

      <Typography
        variant="body1" color="text.secondary"
        sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
      >
        <Chair fontSize="small" sx={{ color: theme.palette.text.secondary }} />
        {seatNumber}
      </Typography>
    </Box>
  )
}
