/**
 * Configuration settings for the application.
 */
const config = {
  base_url: process.env.REACT_APP_BASE_URL,   // Base URL for the API

  googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  mapLibraries: ["places"],

  contactUsUrl: process.env.REACT_APP_CONTACT_US_URL,
  termsAndConditionUrl: "https://s3library.com/privacy-policy",

  guestEmail: "guest.library@s3library.com",

  seatStatusColor: {
    available: "success",
    booked: "warning",
    reserved: "info",
    occupied: "error",
  },

  bookingStatusColor: {
    pending: "warning",
    running: "info",
    completed: "success",
    cancelled: "error",
  },

  subscriptionStatusColor: {
    active: "success",
    inactive: "error",
    pending: "warning",
    expired: "error",
    suspended: "error",
    cancelled: "error",
    completed: "primary",
  },

  // Redux slices configuration
  slices: [
    { name: "tokens", initialValue: {} },          // Token storage
    { name: "library", initialValue: {} },         // Library Details
    { name: "locations", initialValue: [] },       // Locations 
    { name: "currentLocation", initialValue: {} }, // Library Details
    { name: "profile", initialValue: {} },         // User profile information
    { name: "device", initialValue: {} },          // Device information
    { name: "subscription", initialValue: {} },
    { name: "staffRole", initialValue: {} },
    { name: "permissions", initialValue: [] },
    { name: "assetsCount", initialValue: {} },
    { name: "isServerConnected", initialValue: false }, // Server connection status
    { name: "clientId", initialValue: null },      // Client ID for socket connection
    { name: "callInfo", initialValue: null },      // Information about API calls
    { name: "theme", initialValue: "system" },     // Application theme
    { name: "showPermissionDenied", initialValue: false },
    { name: "showPlanUpgrade", initialValue: false },
    { name: "extraAssets", initialValue: {} },
  ]
};

export default config;
