import { Box } from "@mui/material";
import underDevelopmentIcon from "../../assets/svgs/Subscription.svg";

export const PlanUpgrade = () => {

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <img src={underDevelopmentIcon} alt="Under Development" style={{ width: "20px", height: "20px" }} />
    </Box>
  );
}