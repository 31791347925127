import React from "react";
import Grid2 from "@mui/material/Grid2"; // Import Grid2 for responsive layout
import PageContainer from "../components/Containers/PageContainer";
import FloorCard from "../components/Floor/FloorCard";
import FloorCardSkeleton from "../components/Floor/FloorCardSkeleton";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import Swal from "sweetalert2";
import { floorsApi } from "../services/api";
import { useQuery } from "react-query";
import NoRecordFound from "../components/NoRecordFound";
import { Add } from "@mui/icons-material";
import { hasPermission } from "../middleware/permission";
import { PERMISSION_CODE } from "../config/permissionCodes";
import { hasFeature, showPlanUpgrade } from "../middleware/subscription";
import reduxData from "../utils/useReduxData";
import { PlanUpgrade } from "../components/Icons/PlanUpgrade";

const Floors = () => {
  const navigate = useNavigate();
  const hasAddPermission = hasPermission(PERMISSION_CODE.FLOOR.CREATE);

  // React Query to fetch locations
  const { data: queryData, refetch, isLoading } = useQuery(
    ["floors"],
    async () => await floorsApi().getAll(),
    {
      retry: false, // Disable retries on error
    }
  );

  const floors = queryData?.results || [];
  reduxData("assetsCount", "updateObj")({ floors: floors.length });
  const hasFeaturePermission = hasFeature("floors");

  const handleEditName = (id) => (event) => {
    event.stopPropagation();
    Swal.fire({
      title: "Edit Floor",
      input: "text",
      inputLabel: "Floor Name",
      inputPlaceholder: "Enter floor name",
      inputValue: floors.find((floor) => floor._id === id).name,
      confirmButtonText: "Save",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      inputValidator: (value) => {
        if (!value) {
          return "Floor name is required";
        }
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const floorName = result.value;
          await floorsApi().update(id, { name: floorName });
          refetch();
        } catch (error) {
        }
      }
    });
  };

  const handleViewSeats = (id, mode) => (event) => {
    event.stopPropagation();
    navigate(`/floors/${id}`, { state: { mode } });
  };

  const handleDelete = (id) => (event) => {
    event.stopPropagation();
    // Handle delete logic here
    Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await floorsApi().deleteFloor(id);
          refetch();
        } catch (error) {
        }
      }
    });
  };

  const handleAddFloor = () => {
    if (!hasFeaturePermission) return showPlanUpgrade();

    Swal.fire({
      title: "Add Floor",
      input: "text",
      inputLabel: "Floor Name",
      inputPlaceholder: "Enter floor name. Ex: F1, 1st, FF, ...",
      confirmButtonText: "Add",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      inputValidator: (value) => {
        if (!value) {
          return "Floor name is required";
        }
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const floorName = result.value;
          const res = await floorsApi().create({ name: floorName });
          const newFloorId = res._id;
          navigate(`/floors/${newFloorId}`, { state: { mode: "edit" } });
        } catch (error) {
        }
      }
    });
  };

  const AddFloorButton = () => (
    <Button
      startIcon={<Add />}
      endIcon={!hasFeaturePermission ? <PlanUpgrade /> : null}
      variant="outlined"
      color="primary"
      size="small"
      onClick={handleAddFloor}
      disabled={!hasAddPermission}
    >
      Floor
    </Button>
  );

  return (
    <PageContainer headerContent={<AddFloorButton />}>
      <Grid2 container spacing={2} justifyContent={{ xs: "space-around", sm: "space-evenly", md: "left" }}>
        {
          isLoading
            ? <>
              <Grid2 size={{ xs: 12, sm: 6, md: 4 }} > <FloorCardSkeleton /> </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, md: 4 }} > <FloorCardSkeleton /> </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, md: 4 }} > <FloorCardSkeleton /> </Grid2>
            </>
            : (
              floors.length
                ? floors.map((floor) => (
                  <Grid2 size={{ xs: 12, sm: 6, md: 4 }} key={floor._id}>
                    <FloorCard
                      name={floor.name}
                      seats={floor.totalSeats}
                      onViewSeats={handleViewSeats(floor._id, "view")}
                      onEditSeats={handleViewSeats(floor._id, "edit")}
                      onEditName={handleEditName(floor._id)}
                      onDelete={handleDelete(floor._id)}
                      canDelete={floors.length > 1}
                    />
                  </Grid2>
                ))
                : <NoRecordFound
                  title="No Floors Found"
                  subTitle={hasAddPermission ? "Click on the button below to add a new floor." : "Please Refresh the Page"}
                  buttonText={hasAddPermission ? "Add Floor" : ""}
                  ButtonIcon={hasAddPermission ? Add : ""}
                  onRefresh={hasAddPermission ? handleAddFloor : ""}
                />
            )
        }
      </Grid2>
    </PageContainer>
  );
};

export default Floors;
