/**
 * OtpVerificationPage.js
 */
import React, { useState, useEffect } from "react";
import { Box, Typography, Button, CircularProgress, IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ReactCodeInput from "react-code-input"; // npm install react-code-input
import { authApi } from "../services/api";
import { Edit } from "@mui/icons-material";
import reduxData from "../utils/useReduxData";
import { useQuery } from "react-query";
import config from "../config/config";

export default function OtpVerification() {
  const location = useLocation();
  const navigate = useNavigate();
  const { sendOtpForLogin, checkOtpForLogin } = authApi();

  const { value, token, isPhoneNumber, isGuest } = location.state || {};

  const [otp, setOtp] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [resendTimer, setResendTimer] = useState(30);
  const [canResend, setCanResend] = useState(false);
  const [otpToken, setOtpToken] = useState(token);

  useQuery(
    ["guest", isGuest],
    async () => isGuest && await handleVerifyOtp("123456"),
    {
      retry: false, // Disable retries on error
    }
  );

  // Start a timer for 30s to enable Resend
  useEffect(() => {
    const timer =
      resendTimer > 0 &&
      setInterval(() => setResendTimer((prev) => prev - 1), 1000);

    if (resendTimer === 0) {
      setCanResend(true);
    }

    return () => clearInterval(timer);
  }, [resendTimer]);

  // Navigate back to Login page to edit phone/email
  const handleEdit = () => {
    navigate(-1); // Go back one step in the history
  };

  const handleOtpChange = (val) => {
    setErrorMessage("");
    setOtp(val);

    if (val.length === 6) {
      handleVerifyOtp(val);
    }
  };

  const handleVerifyOtp = async (val) => {
    if (val.length !== 6) return;

    setIsSubmitting(true);
    setErrorMessage("");

    try {
      // Call check OTP API
      const response = await checkOtpForLogin({ token: otpToken, otp: val });

      const { tokens, user, device, library, permissions } = response;
      const { locations, subscription, role, ...libraryInfo } = library;

      const guestEmail = config.guestEmail;

      // Save data using redux
      reduxData("tokens", "set")(tokens);
      reduxData("profile", "set")(user);
      reduxData("device", "set")(device);
      reduxData("library", "set")(libraryInfo);
      reduxData("locations", "set")(locations);
      reduxData("subscription", "set")(subscription);
      reduxData("permissions", "set")(permissions);
      reduxData("staffRole", "set")(role);
      reduxData("isGuest", "set")(isGuest || guestEmail === user?.email);
      reduxData("currentLocation", "set")({ _id: locations[0]?._id, name: locations[0]?.name });

      navigate("/dashboard");
    } catch (err) {
      setErrorMessage(err.message || "Failed to verify OTP.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleResendOtp = async () => {
    setOtp("");
    setResendTimer(30);
    setCanResend(false);
    setErrorMessage("");

    try {
      const newResponse = await sendOtpForLogin({ value });
      setOtpToken(newResponse.token);
    } catch (err) {
      setErrorMessage(err.message || "Failed to resend OTP.");
    }
  };

  if (isGuest) {
    return <CircularProgress size={24} sx={{ color: "#fff" }} />
  }

  return (
    <Box
      sx={{
        py: 2,
        px: 0,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        alignItems: "center",
      }}
    >
      <Typography variant="h6" fontWeight="bold" textAlign="center">
        Verify Your {isPhoneNumber ? "Number" : "Email"}
      </Typography>

      <Typography variant="body1" textAlign="center">
        We sent a 6-digit code to
      </Typography>

      {/* Row with user value + Edit Icon */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          justifyContent: "center",
        }}
      >
        <Typography variant="body1" fontWeight="bold" noWrap>
          {value}
        </Typography>
        <IconButton size="small" onClick={handleEdit}>
          <Edit fontSize="small" color="primary" />
        </IconButton>
      </Box>

      {/* OTP Input */}
      <ReactCodeInput
        type="text" // Change to text to remove the up/down buttons
        inputMode="numeric" // Ensures numeric keyboard on mobile
        pattern="[0-9]*" // Ensures only numbers are entered
        fields={6}
        autoFocus
        value={otp}
        onChange={handleOtpChange}
        inputStyle={{
          margin: "2px",
          width: "35px",
          height: "35px",
          fontSize: "20px",
          borderRadius: "8px",
          border: "1px solid #ccc",
          textAlign: "center",
          MozAppearance: "textfield", // Removes arrows in Firefox
        }}
      />

      {/* Error Message */}
      {errorMessage && (
        <Typography variant="body2" color="error" textAlign="center">
          {errorMessage}
        </Typography>
      )}

      {/* Resend */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="body2">
          Didn&apos;t receive code?
        </Typography>
        <Button
          variant="text"
          disabled={!canResend}
          onClick={handleResendOtp}
        >
          {canResend ? "Resend" : `Resend in ${resendTimer}`}
        </Button>
      </Box>

      {/* Verify OTP Button */}
      <Button
        variant="contained"
        fullWidth
        disabled={otp.length !== 6 || isSubmitting}
        onClick={() => handleVerifyOtp(otp)}
        sx={{ borderRadius: 2, py: 1.2 }}
      >
        {
          isSubmitting ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Verify OTP"
        }
      </Button>
    </Box>
  );
}
