import React, { useState } from "react";
import { Typography, IconButton, Box, Collapse, List, ListItem, ListItemText, useTheme } from "@mui/material";
import { Edit, ExpandMore, ExpandLess, Delete, People } from "@mui/icons-material";
import CustomCard from "../Containers/Card";
import { hasPermission } from "../../middleware/permission";
import { PERMISSION_CODE } from "../../config/permissionCodes";

const StaffCard = ({ name, totalMembers, permissions, onView, onEdit, onDelete }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const hasViewPermission = hasPermission(PERMISSION_CODE.MEMBER.VIEW);
  const hasEditPermission = hasPermission(PERMISSION_CODE.STAFF_ROLE.UPDATE);
  const hasDeletePermission = hasPermission(PERMISSION_CODE.STAFF_ROLE.DELETE);

  const handleExpandToggle = (e) => {
    e.stopPropagation(); // Prevent triggering the stack's onClick
    setExpanded((prev) => !prev);
  };

  const handleEdit = (e) => {
    e.stopPropagation(); // Prevent triggering the stack's onClick
    onEdit();
  };

  const handleDelete = (e) => {
    e.stopPropagation(); // Prevent triggering the stack's onClick
    onDelete();
  };

  return (
    <CustomCard
      onClick={hasViewPermission ? onView : null}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing(2),
        cursor: hasViewPermission ? "pointer" : "default",
        ":hover": {
          transform: hasViewPermission ? "scale(0.98)" : ""
        },
        ":active": {
          transform: hasViewPermission ? "scale(0.95)" : ""
        },
        transition: "transform 0.2s ease-in-out",
      }}
    >
      {/* Header Section */}
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          {/* Staff Name */}
          <Typography variant="h5" gutterBottom sx={{ margin: 0 }}>
            {name}
          </Typography>

          {/* Edit Icon */}
          <IconButton size="small" color="primary" onClick={handleEdit} disabled={!hasEditPermission} >
            <Edit />
          </IconButton>
        </Box>

        <IconButton size="small" color="error" onClick={handleDelete} disabled={!hasDeletePermission} >
          <Delete />
        </IconButton>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {/* Total Members */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <People fontSize="small" color="info" />
          <Typography variant="body1" color="text.secondary">
            Total Members: {totalMembers}
          </Typography>
        </Box>

        {/* Expand/Collapse Icon */}
        <IconButton size="small" onClick={handleExpandToggle}>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>

      {/* Permissions List (Collapsible) */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box>
          <Typography variant="subtitle2" gutterBottom>
            Permissions:
          </Typography>
          <List dense>
            {permissions.map((permission, index) => (
              <ListItem key={index}>
                <ListItemText primary={permission.title} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Collapse>
    </CustomCard>
  );
};

export default StaffCard;
