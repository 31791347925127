import React from "react";
import PageContainer from "../components/Containers/PageContainer";
import { subscriptionsApi } from "../services/api";
import SubscriptionRow from "../components/Offers/SubscriptionRow";
import QueryContainer from "../components/Containers/QueryContainer/Main";
import Swal from "sweetalert2";

const Subscriptions = () => {
  const sortOptions = [
    { key: "startDate", label: "Start Date" },
    { key: "price", label: "Price" },
    { key: "endDate", label: "End Date" },
    { key: "student.name", label: "Name" },
    { key: "student.email", label: "Email" },
    // { key: "durationInDays", label: "Duration" },
    { key: "canceledAt", label: "Canceled Date" },
    { key: "discountInFixedAmount", label: "Discount" },
    { key: "student.contactNumber", label: "Contact Number" },
  ];

  const filters = {
    type: [
      { value: "", label: "All" },
      { value: "fixed", label: "Fixed" },
      { value: "flexible", label: "Flexible" },
    ],
    status: [
      { value: "", label: "All" },
      { value: ["pending"], label: "Pending" },
      { value: ["active"], label: "Active" },
      { value: ["completed"], label: "Completed" },
      { value: ["cancelled"], label: "Cancelled" },
    ],
  };

  const handleConfirmPayment = (subscriptionId, refetch) => async () => {
    try {
      await subscriptionsApi().confirmPayment(subscriptionId);
      refetch && refetch();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message || "Something went wrong",
      });
    }
  }

  const handleCancelSubscription = (subscriptionId, refetch) => async (reason) => {
    try {
      await subscriptionsApi().cancel(subscriptionId, reason);
      refetch && refetch();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message || "Something went wrong",
      });
    }
  }

  const header = [
    { size: 0.8, align: "start", key: "image", label: "Image" },
    { size: 1.7, align: "start", key: "name", label: "Name" },
    { size: 1.5, align: "center", key: "type", label: "Type" },
    { size: 2, align: "center", key: "status", label: "Status" },
    { size: 2, align: "center", key: "offer_details", label: "Price" },
    { size: 2, align: "center", key: "dates", label: "Dates" },
    { size: 2, align: "end", key: "actions", label: "Actions" },
  ];

  return (
    <PageContainer>
      <QueryContainer
        fetchData={subscriptionsApi().getAll}
        filters={filters}
        sortOptions={sortOptions}
        header={header}
        getItems={(subscriptions, refetch) => (
          subscriptions.map((reservation, index) => (<SubscriptionRow
            key={index}
            subscription={reservation}
            header={header}
            onConfirmPayment={handleConfirmPayment(reservation._id, refetch)}
            onCancelSubscription={handleCancelSubscription(reservation._id, refetch)}
          />))
        )}
        noDataFound={{
          buttonText: "Refresh",
          title: "No Subscriptions found",
          subTitle: "Their is no subscriptions found in you prefred filters",
        }}
      />
    </PageContainer>
  );
};

export default Subscriptions;
