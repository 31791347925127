// redux/slices.js
import { createSlice } from "@reduxjs/toolkit";
import { set, add, updateObj, remove, clear } from "./commonReducers";

const myCustomSlices = (sliceName, initialValue) => {
    const slice = createSlice({
        initialState: initialValue,
        name: sliceName,
        reducers: {
            set,
            add,
            updateObj,
            remove,
            clear: clear(initialValue)
        },
    });

    return {
        ...slice.actions,
        reducer: slice.reducer,
    };
};

export default myCustomSlices;
