import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ArrowBack, Dashboard } from '@mui/icons-material';
import reduxData from '../../utils/useReduxData';
import permissionDeniedIcon from "../../assets/svgs/PermissionDenied.svg";

const PermissionDenied = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showPermissionDenied = useSelector(state => state.showPermissionDenied);

  useEffect(() => {
    setIsModalOpen(showPermissionDenied);
  }, [showPermissionDenied]);

  const onClose = () => {
    setIsModalOpen(false)
    reduxData("showPermissionDenied", "set")(false);
  };

  const handleBack = () => {
    onClose();
    navigate(-1);
  }

  const handleDashboard = () => {
    onClose();
    navigate("/dashboard");
  }

  return (
    <Dialog open={isModalOpen} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ fontSize: '1.5rem', fontWeight: 'bold', textAlign: 'center' }}>
        <img src={permissionDeniedIcon} alt="Permission Denied" style={{ width: "100px" }} />
      </DialogTitle>

      <DialogTitle sx={{ fontSize: '1.5rem', fontWeight: 'bold', textAlign: 'center' }}>
        Permission Denied
      </DialogTitle>

      <DialogContent sx={{ textAlign: 'center', padding: '20px' }}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Sorry, You don't have permission to perform this action.
        </Typography>
        <Typography variant="body2" sx={{ color: 'gray' }}>
          Please contact to library Owner
        </Typography>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'space-evenly', pb: 2 }}>
        <Button
          variant='outlined'
          onClick={handleBack}
          startIcon={<ArrowBack />}
        >
          Back
        </Button>

        <Button
          variant='contained'
          onClick={handleDashboard}
          startIcon={<Dashboard />}
        >
          Dashboard
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PermissionDenied;
