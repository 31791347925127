import React from "react";
import { Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";

// Updated PDF styles
const styles = StyleSheet.create({
  page: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f4f8ff00",
    padding: 0,
  },
  card: {
    width: 500,
    borderRadius: 15,
    overflow: "hidden",
    backgroundColor: "#fff",
    textAlign: "center",
    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
  },
  header: {
    backgroundColor: "#d2e6ff",
    height: 100,
    padding: 15,
  },
  logoText: {
    fontSize: 34,
    fontWeight: "bold",
    color: "#08518b",
  },
  title: {
    fontSize: 37,
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: 25,
    color: "#555",
    marginTop: 4,
  },
  qrSection: {
    backgroundColor: "#08518b",
    padding: 25,
    alignItems: "center",
  },
  qrText: {
    color: "white",
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 30,
  },
  qrContainer: {
    backgroundColor: "white",
    padding: 12,
    borderRadius: 10,
  },
  qrImage: {
    width: 400,
    height: 400,
  },
  footerText: {
    fontSize: 25,
    color: "white",
    marginTop: 30,
  },
  footer: {
    paddingTop: 40,
    backgroundColor: "white",
    fontSize: 20,
    fontWeight: "bold",
    color: "#08518b",
  },
});

// PDF Component
const CheckInOutPdf = ({ libraryName, branchName, qrCodeData }) => (
  <Document>
    <Page style={styles.page}>

      {/* Card Container */}
      <View style={styles.card}>

        {/* Header */}
        <View style={styles.header}>
          <Text style={styles.title}>{libraryName.toUpperCase()}</Text>
          <Text style={styles.subtitle}>{branchName}</Text>
        </View>

        {/* QR Code Section */}
        <View style={styles.qrSection}>
          <Text style={styles.qrText}>SCAN FOR CHECK-IN-OUT</Text>
          <View style={styles.qrContainer}>
            <Image src={qrCodeData} style={styles.qrImage} />
          </View>
          <Text style={styles.footerText}>Manage Your Attendance</Text>
        </View>

        {/* Footer */}
        <View style={styles.footer}>
          <Text>S3LIBRARY.COM</Text>
        </View>

      </View>

    </Page>
  </Document>
);

export default CheckInOutPdf;
