// API endpoint configuration
const apiRoutes = {
  auth: {
    sendOtpForLogin: {
      url: "/auth/send-otp-for-login",
      method: "post",
      successCode: 200,
      isAuthApi: true,
      showSuccessToast: false,
      showErrorToast: false,
    },
    checkOtpForLogin: {
      url: "/auth/check-otp-for-login",
      method: "post",
      successCode: 200,
      isAuthApi: true,
      showSuccessToast: false,
      showErrorToast: false,
    },
    sendOtp: {
      url: "/auth/send-otp",
      method: "post",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
    checkOtp: {
      url: "/auth/check-otp",
      method: "post",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
    googleSignIn: {
      url: "auth/google-signin",
      method: "post",
      successCode: 201,
      isAuthApi: true,
      showSuccessToast: false,
      showErrorToast: false,
    },
    logout: {
      url: "/auth/logout",
      method: "post",
      successCode: 200,
      isAuthApi: true,
      showSuccessToast: false,
      showErrorToast: false,
    },
    refreshTokens: {
      url: "/auth/refresh-tokens",
      method: "post",
      successCode: 200,
      isAuthApi: true,
      showSuccessToast: false,
      showErrorToast: false,
    },
    forgotPassword: {
      url: "/auth/forgot-password",
      method: "post",
      successCode: 200,
      isAuthApi: true,
      showSuccessToast: false,
      showErrorToast: false,
    },
    resetPassword: {
      url: "/auth/reset-password",
      method: "post",
      successCode: 200,
      isAuthApi: true,
      showSuccessToast: false,
      showErrorToast: false,
    },
    checkCredentials: {
      url: "/auth/is-credentials-already-used",
      method: "post",
      successCode: 200,
      isAuthApi: true,
      showSuccessToast: false,
      showErrorToast: false,
    },
  },
  locations: {
    getAll: {
      url: "/locations",
      method: "post",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
    getById: (id) => ({
      url: `/locations/${id}`,
      method: "get",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    }),
    create: {
      url: "/locations/add",
      method: "post",
      successCode: 201,
      isAuthApi: false,
      showSuccessToast: true,
      showErrorToast: true,
    },
    update: (id) => ({
      url: `/locations/${id}`,
      method: "put",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: true,
      showErrorToast: true,
    }),
    delete: (id) => ({
      url: `/locations/${id}`,
      method: "delete",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: true,
      showErrorToast: true,
    }),
    getLibrarySettings: (id) => ({
      url: `/locations/${id}/settings`,
      method: "get",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    }),
    updateLibrarySettings: (id) => ({
      url: `/locations/${id}/settings`,
      method: "put",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: true,
      showErrorToast: true,
    }),
  },
  floors: {
    getAll: {
      url: "/floors",
      method: "post",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
    getAllWithSeats: {
      url: "/floors/with-seats",
      method: "post",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
    create: {
      url: "/floors/add",
      method: "post",
      successCode: 201,
      isAuthApi: false,
      showSuccessToast: true,
      showErrorToast: true,
    },
    update: (id) => ({
      url: `/floors/${id}`,
      method: "put",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: true,
      showErrorToast: true,
    }),
    delete: (id) => ({
      url: `/floors/${id}`,
      method: "delete",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: true,
      showErrorToast: true,
    }),

    getFloorSeats: (id) => ({
      url: `/floors/${id}/seats`,
      method: "get",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    }),

    updateFloorSeats: (id) => ({
      url: `/floors/${id}/seats`,
      method: "put",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: true,
      showErrorToast: true,
    }),
    updateSeatStatus: (id) => ({
      url: `/floors/${id}/seats/status`,
      method: "put",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    }),
  },
  staffs: {
    getAll: {
      url: "/staffs",
      method: "post",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
    create: {
      url: "/staffs/add",
      method: "post",
      successCode: 201,
      isAuthApi: false,
      showSuccessToast: true,
      showErrorToast: true,
    },
    update: (id) => ({
      url: `/staffs/${id}`,
      method: "put",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: true,
      showErrorToast: true,
    }),
    delete: (id) => ({
      url: `/staffs/${id}`,
      method: "delete",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: true,
      showErrorToast: true,
    }),
    getAllPermissions: {
      url: '/staffs/permissions',
      method: "get",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    }
  },
  members: {
    getAll: {
      url: "/members",
      method: "post",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
    create: {
      url: "/members/add",
      method: "post",
      successCode: 201,
      isAuthApi: false,
      showSuccessToast: true,
      showErrorToast: true,
    },
    update: (id) => ({
      url: `/members/${id}`,
      method: "put",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: true,
      showErrorToast: true,
    }),
    delete: (id) => ({
      url: `/members/${id}`,
      method: "delete",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: true,
      showErrorToast: true,
    }),
  },
  offers: {
    getAll: {
      url: "/offers",
      method: "post",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
    create: {
      url: "/offers/add",
      method: "post",
      successCode: 201,
      isAuthApi: false,
      showSuccessToast: true,
      showErrorToast: true,
    },
    getById: (id) => ({
      url: `/offers/${id}`,
      method: "get",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    }),
    update: (id) => ({
      url: `/offers/${id}`,
      method: "put",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: true,
      showErrorToast: true,
    }),
    delete: (id) => ({
      url: `/offers/${id}`,
      method: "delete",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: true,
      showErrorToast: true,
    }),
    addSubscriber: (id) => ({
      url: `/offers/${id}/add-subscriber`,
      method: "post",
      successCode: 201,
      isAuthApi: false,
      showSuccessToast: true,
      showErrorToast: true,
    }),
    getSubscribers: (id) => ({
      url: `/offers/${id}/subscribers`,
      method: "post",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    }),
  },
  students: {
    getOtherStudents: {
      url: "/students/other",
      method: "post",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
    getAll: {
      url: "/students",
      method: "post",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
    create: {
      url: "/students/add",
      method: "post",
      successCode: 201,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
    getById: (id) => ({
      url: `/students/${id}`,
      method: "get",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    }),
    getBookings: (id) => ({
      url: `/students/${id}/bookings`,
      method: "post",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    }),
    deleteStudent: (id) => ({
      url: `/students/${id}`,
      method: "delete",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    }),
  },
  bookings: {
    getAll: {
      url: "/bookings",
      method: "post",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
    assignSeat_or_checkIn: {
      url: "/bookings/assign-seat-or-check-in",
      method: "post",
      successCode: 201,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
    releaseSeat_or_checkOut: {
      url: `/bookings/release-seat-or-check-out`,
      method: "post",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
    getById: (id) => ({
      url: `/bookings/${id}`,
      method: "get",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    }),
    update: (id) => ({
      url: `/bookings/${id}`,
      method: "put",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: true,
      showErrorToast: true,
    }),
  },
  reservations: {
    getAll: {
      url: "/subscriptions",
      method: "post",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
    confirmPayment: {
      url: "/subscriptions/confirm-payment",
      method: "post",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
    cancel: {
      url: `/subscriptions/cancel`,
      method: "post",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
  },
  profile: {
    updateProfile: {
      url: "/users/update-profile",
      method: "put",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
    updateLibrary: {
      url: "/libraries",
      method: "put",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
    changePassword: {
      url: "/users/change-password",
      method: "put",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
    deleteAccount: {
      url: "/users/profile",
      method: "delete",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    }
  },
  plans: {
    getAll: {
      url: "/plans",
      method: "get",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
    changePlan: (planId) => ({
      url: `/plans/change-plan/${planId}`,
      method: "post",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    }),
    getActiveSubscription: {
      url: `/plans/my-subscription`,
      method: "get",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
  },
  reviews: {
    getAll: {
      url: "/reviews",
      method: "post",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
    create: {
      url: "/reviews/post",
      method: "post",
      successCode: 201,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
    getMyReview: {
      url: `/reviews/my-review`,
      method: "post",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
    editMyReview: {
      url: `/reviews/my-review`,
      method: "put",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
    deleteMyReview: {
      url: `/reviews/my-review`,
      method: "delete",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
  },
  wallet: {
    fetchBalance: {
      url: "/wallet",
      method: "get",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    },
  },
  // Add other API paths and methods as needed
  "other-api-path-example": {
    getExampleById: (id) => ({
      url: `/other-api-path-example/${id}`,
      method: "get",
      successCode: 200,
      isAuthApi: false,
      showSuccessToast: false,
      showErrorToast: false,
    }),
  },
};

export default apiRoutes;
