import React from "react";
import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const Breadcrumbs = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Generate breadcrumbs from the current location
  const paths = location.pathname.split("/").filter((x) => x);

  // Function to navigate while persisting state
  const handleNavigation = (path) => {
    navigate(path, { state: location.state });
  };

  return (
    <MuiBreadcrumbs separator="›" aria-label="breadcrumb">
      {/* Home Link */}
      <Link
        component="button"
        underline="hover"
        color="inherit"
        onClick={() => handleNavigation("/dashboard")}
      >
        Home
      </Link>

      {/* Dynamic Breadcrumb Links */}
      {paths.map((path, index) => {
        const routeTo = `/${paths.slice(0, index + 1).join("/")}`;
        const isLast = index === paths.length - 1;

        return isLast ? (
          <Typography key={routeTo} color="text.primary">
            {path.charAt(0).toUpperCase() + path.slice(1)}
          </Typography>
        ) : (
          <Link
            key={routeTo}
            component="button"
            underline="hover"
            color="inherit"
            onClick={() => handleNavigation(routeTo)} // Persist state
          >
            {path.charAt(0).toUpperCase() + path.slice(1)}
          </Link>
        );
      })}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
