import React from "react";
import PageContainer from "../../components/Containers/PageContainer";
import BookingRow from "../../components/Booking/BookingRow";
import { bookingsApi } from "../../services/api";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import QueryContainer from "../../components/Containers/QueryContainer/Main";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { hasPermission } from "../../middleware/permission";
import { PERMISSION_CODE } from "../../config/permissionCodes";
import { hasFeature, showPlanUpgrade } from "../../middleware/subscription";
import { PlanUpgrade } from "../../components/Icons/PlanUpgrade";

const Attendance = () => {
  const navigate = useNavigate();
  const handleToggleBookingForm = () => {
    if (!hasFeaturePermission) return showPlanUpgrade();
    navigate("/attendance/add");
  };

  const hasAddPermission = hasPermission(PERMISSION_CODE.ATTENDANCE.ASSIGN_SEAT_OR_CHECK_IN);
  const hasFeaturePermission = hasFeature("addAttendance");

  const handleCheckOut = (bookingId, studentId, refetch) => async () => await handleReleaseSeatAndCheckOut({ studentId, bookingId, refetch });

  const handleReleaseSeatAndCheckOut = async ({ studentId, bookingId, refetch }) => {
    try {
      await bookingsApi().releaseSeat_or_checkOut({ studentId, bookingId });
      refetch && refetch();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error["message"] || "Something went wrong",
      });
    }
  };

  const sortOptions = [
    { key: "checkedInAt", label: "Check In Time" },
    { key: "name", label: "Name" },
    { key: "bookedAt", label: "Booked On" },
    { key: "checkedOutAt", label: "Check Out Time" },
  ];

  const filters = {
    source: [
      { value: "", label: "All" },
      { value: "online", label: "Online" },
      { value: "offline", label: "By Admin" },
    ],
    status: [
      { value: "", label: "All" },
      { value: "pending", label: "Pending" },
      { value: "running", label: "Running" },
      { value: "completed", label: "Completed" },
      { value: "cancelled", label: "Cancelled" },
    ],
  };

  const header = [
    { size: 1, align: "start", key: "image", label: "Image" },
    { size: 2.5, align: "start", key: "name", label: "Name & Contact No." },
    { size: 2, align: "center", key: "booked_on", label: "Booked On" },
    { size: 2.5, align: "center", key: "status", label: "Status" },
    { size: 3, align: "center", key: "check_in_out_time", label: "Check In/Out Time" },
    { size: 1, align: "end", key: "actions", label: "Actions" }
  ];

  const bookASeatButton = (
    <Button
      variant="outlined"
      size="small"
      color="primary"
      onClick={handleToggleBookingForm}
      startIcon={<Add />}
      endIcon={!hasFeaturePermission ? <PlanUpgrade /> : null}
      disabled={!hasAddPermission}
    >
      Attendance
    </Button>
  );

  return (
    <PageContainer headerContent={bookASeatButton}>
      <QueryContainer
        fetchData={bookingsApi().getAll}
        filters={filters}
        sortOptions={sortOptions}
        header={header}
        getItems={(bookings, refetch) => bookings.map((booking) =>
          <BookingRow
            booking={booking}
            header={header}
            onCheckOut={handleCheckOut(booking._id, booking.studentId, refetch)}
          />
        )}
        noDataFound={{
          title: 'No Attendance Found',
          subTitle: hasAddPermission ? "Click on the button below to add a new attendance." : "Please Refresh the page.",
          buttonText: hasAddPermission ? "Add Attendance" : "",
          ButtonIcon: hasAddPermission ? (hasFeaturePermission ? Add : PlanUpgrade) : "",
          onRefresh: hasAddPermission ? handleToggleBookingForm : "",
        }}
      />
    </PageContainer>
  );
};

export default Attendance;
