/**
 * LoginPage.js
 */
import React, { useState } from "react";
import { Box, Typography, TextField, Button, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { authApi } from "../services/api";
import config from "../config/config";

const termsAndConditionUrl = config.termsAndConditionUrl;

export default function LoginPage() {
  const navigate = useNavigate();
  const { sendOtpForLogin } = authApi();

  const [inputValue, setInputValue] = useState("");
  const [isPhoneNumber, setIsPhoneNumber] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isGuestSubmitting, setGuestIsSubmitting] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const validateInput = (value) => {
    let error = "";
    const isNumber = /^[0-9]+$/.test(value);
    const isValidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
    const valueLength = value.trim().length;

    if (isNumber) {   // It's a phone number
      if (valueLength > 10) {
        error = "Phone number cannot exceed 10 digits.";
      } else if (valueLength < 10 && valueLength > 0) {
        error = "";
      }
    } else if (valueLength > 0 && isValidEmail) {
      error = "";
    }

    setIsButtonEnabled(!error && (isNumber ? valueLength === 10 : (valueLength && isValidEmail)));
    setErrorMessage(error);
    setIsPhoneNumber(isNumber); // Only digits -> phone number
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setInputValue(value);
    validateInput(value);
  };

  const handleSendOtp = async () => {
    setIsSubmitting(true);
    setErrorMessage("");

    const formattedInput = isPhoneNumber ? `+91${inputValue}` : inputValue;

    try {
      // Call your API
      const response = await sendOtpForLogin({ value: formattedInput });

      // Navigate to OTP Verification Page with state
      navigate("/otp-verification", {
        state: {
          value: formattedInput,
          token: response.token,
          isPhoneNumber,
          isGuest: false
        },
      });
    } catch (err) {
      setErrorMessage(err.message || "Failed to send OTP.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleContinueAsGuest = async () => {
    try {
      setGuestIsSubmitting(true);
      const guestEmail = config.guestEmail;
      const response = await sendOtpForLogin({ value: guestEmail });

      navigate("/otp-verification", {
        state: {
          value: guestEmail,
          token: response.token,
          isPhoneNumber: false,
          isGuest: true,
        },
      });
    } catch (err) {
      setErrorMessage(err.message || "Failed to send OTP.");
    } finally {
      setGuestIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        alignItems: "center",
      }}
    >

      {/* Title */}
      <Typography variant="h5" fontWeight="bold" textAlign="center">
        Welcome Back!
      </Typography>

      <Typography variant="body1" textAlign="center" color="text.secondary">
        Simplify library operations and provide better service to students.
      </Typography>

      {/* Input Field */}
      <TextField
        fullWidth
        label={
          inputValue
            ? (isPhoneNumber ? "Phone Number" : "Email Address")
            : "Phone Number or Email"
        }
        placeholder="Phone Number or Email"
        variant="outlined"
        value={inputValue}
        onChange={handleInputChange}
        error={Boolean(errorMessage)}
        helperText={errorMessage}
        InputProps={{
          startAdornment: isPhoneNumber ? <Box sx={{ mr: 1 }}>+91</Box> : null,
        }}
      />

      {/* Send OTP Button */}
      <Button
        fullWidth
        variant="contained"
        disabled={!isButtonEnabled || isSubmitting}
        onClick={handleSendOtp}
        sx={{ py: 1.2, borderRadius: 2 }}
      >
        {
          isSubmitting ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Send OTP"
        }
      </Button>

      {/* Continue as Guest */}
      <Button variant="text" onClick={handleContinueAsGuest}>
        {
          isGuestSubmitting ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Try Demo"
        }
      </Button>

      {/* Terms & Privacy */}
      <Typography variant="body2" textAlign="center" color="text.secondary">
        By continuing, you agree to our{" "}
        <a
          href={termsAndConditionUrl}
          target="_blank"
          rel="noreferrer"
          style={{ color: "#1976d2", textDecoration: "underline" }}
        >
          Terms of Service & Privacy Policy
        </a>
      </Typography>
    </Box>
  );
}
