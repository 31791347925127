import React from "react";
import { Grid2 } from "@mui/material";
import KpiCard from "./KpiCard";
import { People, EventSeat, AccountBalanceWallet, MonetizationOn } from "@mui/icons-material";
import { useQuery } from "react-query";
import { walletApi } from "../../services/api";

const Kpis = () => {
  // React Query to fetch locations
  const { data: balance, isLoading } = useQuery(
    ["wallet-balance"],
    async () => {
      const data = await walletApi().fetchBalance();

      return data['balance'] || 0;
    },
    {
      retry: false, // Disable retries on error
    }
  );

  const kpis = [
    {
      Icon: People,
      title: "Total Students",
      value: "0",
      trend: 0,
      up: true,
    },
    {
      Icon: EventSeat,
      title: "Active Seats",
      value: "0",
      trend: 0,
      up: true,
    },
    {
      Icon: MonetizationOn,
      title: "Monthly Revenue",
      value: "₹0",
      trend: 0,
      up: true,
    },
    {
      Icon: AccountBalanceWallet,
      title: "Wallet Balance",
      value: `₹${isLoading ? 0 : balance}`,
      trend: 0,
      up: true,
    },
  ];

  return (
    <Grid2 container spacing={3}>
      {kpis.map((kpi, index) => (
        <Grid2 size={{ xs: 12, sm: 6, md: 3 }} key={index}>
          <KpiCard {...kpi} />
        </Grid2>
      ))}
    </Grid2>
  );
};

export default Kpis;
