import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import underDevelopmentIcon from "../../assets/svgs/under development.svg";

const UnderDevelopment = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        height: "70vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <img src={underDevelopmentIcon} alt="Under Development" style={{ width: "100px", height: "100px" }} />
      <Typography variant="h4" sx={{ mt: 2, mb: 1, color: "primary.main" }}>
        Page Under Development
      </Typography>
      <Typography variant="body1" sx={{ mb: 3, color: "text.secondary" }}>
        This feature is under development. Please check back soon!
      </Typography>
      <Button
        startIcon={<ArrowBack />}
        variant="contained"
        color="primary"
        onClick={() => navigate(-1)}
      >
        Back
      </Button>
    </Box>
  );
};

export default UnderDevelopment;
