import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

const usePagination = (fetchData, defaultQuery) => {
  const [query, setQuery] = useState(defaultQuery);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);

  const { isLoading, isFetching, refetch } = useQuery(
    ['data', query],
    () => fetchData({ ...query, page: loadingMore ? query.page : 1 }),
    {
      keepPreviousData: true,
      retry: false, // Disable retries on error
      onSuccess: (response) => {
        if (!response) return;
        const { results, page, totalPages, totalResults } = response;

        if (page === 1) {
          setData(results);
        } else {
          setData((prevData) => [...prevData, ...results]);
        }

        setLoadingMore(false);
        setHasMore(page < totalPages);
        setTotalItems(totalResults);
      },
      enabled: query.page > 0, // Only run the query if the page is greater than 0
    }
  );

  const loadMore = () => {
    if (!loading && hasMore) {
      setLoadingMore(true);
      setQuery((prevQuery) => ({ ...prevQuery, page: prevQuery.page + 1 }));
    }
  };

  useEffect(() => {
    if (isLoading || isFetching) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isLoading, isFetching]);

  return { data, loadMore, hasMore, refetch, totalItems, loading: isFetching, query, setQuery };
};

export default usePagination; 