import React, { useState } from "react";
import {
  Drawer,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Collapse,
  IconButton,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { hasPermission } from "../../middleware/permission";
import { PERMISSION_CODE } from "../../config/permissionCodes";

const PermissionsDrawer = ({
  open,
  role,
  permissionsList,
  selectedPermissions,
  onClose,
  onPermissionChange,
  onSave,
  needToSave,
}) => {
  const [expandedGroups, setExpandedGroups] = useState({});

  const hasEditPermission = hasPermission(PERMISSION_CODE.STAFF_ROLE.UPDATE);

  // Group permissions by category
  const categorizedPermissions = permissionsList?.reduce((acc, permission) => {
    acc[permission.permission_group] = acc[permission.permission_group] || [];
    acc[permission.permission_group].push(permission);
    return acc;
  }, {}) || {};

  // Toggle group collapse state
  const toggleGroup = (group) => {
    setExpandedGroups((prev) => ({ ...prev, [group]: !prev[group] }));
  };

  // Check if all permissions in a group are selected
  const isGroupSelected = (group) =>
    categorizedPermissions[group].every((perm) =>
      selectedPermissions.includes(perm.code)
    );

  // Check if some permissions in a group are selected (for indeterminate state)
  const isGroupIndeterminate = (group) =>
    categorizedPermissions[group].some((perm) =>
      selectedPermissions.includes(perm.code)
    ) && !isGroupSelected(group);

  // Handle permission selection
  const handlePermissionToggle = (code) => {
    onPermissionChange(code);
  };

  // Handle group selection
  const handleGroupToggle = (group) => {
    const groupPermissions = categorizedPermissions[group].map((perm) => perm.code);

    const selectedPer = groupPermissions.filter((perm) => selectedPermissions.includes(perm));
    const nonSelectedPer = groupPermissions.filter((perm) => !selectedPermissions.includes(perm));

    const isAllSelected = selectedPer.length === groupPermissions.length;

    (isAllSelected ? selectedPer : nonSelectedPer).forEach(onPermissionChange);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: 400, marginTop: 6, paddingBottom: 6 }, // Customize drawer width
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingX: 2,
          paddingY: 1,
          borderBottom: "1px solid",
          borderColor: "divider",
        }}
      >
        <Typography variant="h6">View Permissions</Typography>
      </Box>

      {/* Permissions List */}
      {Object.keys(categorizedPermissions).map((category) => (
        <Box key={category} sx={{ paddingX: 2, paddingY: 1 }}>
          {/* Group Checkbox */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
              paddingY: 1,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={isGroupSelected(category)}
                  indeterminate={isGroupIndeterminate(category)}
                  onChange={(e) => {
                    e.stopPropagation(); // Prevent collapse toggle when clicking checkbox
                    handleGroupToggle(category);
                  }}
                />
              }
              label={
                <Typography variant="subtitle1">
                  {category.replace(/_/g, " ").toUpperCase()}
                </Typography>
              }
            />
            <IconButton size="small" onClick={() => toggleGroup(category)}>
              {expandedGroups[category] ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>

          <Collapse in={expandedGroups[category]} timeout="auto" unmountOnExit>
            <Box sx={{ pl: 4, mt: 1 }}>
              {categorizedPermissions[category].map((permission) => (
                <FormControlLabel
                  key={permission.code}
                  control={
                    <Checkbox
                      checked={selectedPermissions.includes(permission.code)}
                      onChange={() => handlePermissionToggle(permission.code)}
                    />
                  }
                  label={permission.title}
                />
              ))}
            </Box>
          </Collapse>
        </Box>
      ))}

      {/* Footer */}
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          width: "100%",
          borderTop: "1px solid",
          borderBottom: "1px solid",
          borderColor: "divider",
          padding: 2,
          backgroundColor: "inherit",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="outlined"
          onClick={onClose}
          color="error"
          sx={{ width: "45%" }}
        >
          Close
        </Button>

        <Button
          variant="outlined"
          onClick={onSave}
          color="primary"
          sx={{ width: "45%" }}
          disabled={!needToSave || !hasEditPermission}
        >
          Save
        </Button>
      </Box>
    </Drawer>
  );
};

export default PermissionsDrawer;
