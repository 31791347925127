import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import reduxData from '../../utils/useReduxData';
import upgradePlanIcon from "../../assets/svgs/upgradePlan.svg";
import { PlanUpgrade } from '../Icons/PlanUpgrade';
import { ArrowBack, Close } from '@mui/icons-material';

const UpgradePlan = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showPlanUpgrade = useSelector(state => state.showPlanUpgrade);

  const showCloseBtn = !location.pathname.split("/").includes("add");

  useEffect(() => {
    setIsModalOpen(showPlanUpgrade);
  }, [showPlanUpgrade]);

  const onClose = () => {
    setIsModalOpen(false)
    reduxData("showPlanUpgrade", "set")(false);
  };

  const handleCancel = () => {
    onClose();
    navigate(-1);
  }

  const handlePricing = () => {
    onClose();
    navigate("/pricing");
  }

  return (
    <Dialog open={isModalOpen} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ fontSize: '1.5rem', fontWeight: 'bold', textAlign: 'center' }}>
        <img src={upgradePlanIcon} alt="Upgrade Required" style={{ width: "100px" }} />
      </DialogTitle>

      <DialogTitle sx={{ fontSize: '1.5rUpgradePlanem', fontWeight: 'bold', textAlign: 'center' }}>
        Upgrade Required
      </DialogTitle>

      <DialogContent sx={{ textAlign: 'center', padding: '20px' }}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          You need to upgrade your plan to perform this action.
        </Typography>
        <Typography variant="body2" sx={{ color: 'gray' }}>
          Upgrade to access premium features and unlock all functionalities.
        </Typography>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'space-evenly', pb: 2 }}>
        {
          showCloseBtn
            ? <Button variant='outlined' onClick={onClose} startIcon={<Close />} > Close </Button>
            : <Button variant='outlined' onClick={handleCancel} startIcon={<ArrowBack />} > Back </Button>
        }


        <Button
          variant='contained'
          onClick={handlePricing}
          startIcon={<PlanUpgrade />}
        >
          Upgrade Now
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpgradePlan;
