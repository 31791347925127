import React, { useMemo } from 'react';
import { Box, Chip, Drawer, IconButton, List, Typography, useMediaQuery } from '@mui/material';
import { Dashboard, LocationOn, People, Notifications, Settings, Brightness4, WbSunny, Logout, Discount, QrCode, Subscriptions, Apartment, Diversity3 } from '@mui/icons-material';
import Swal from 'sweetalert2';
import ScrollableBox from "../../Containers/ScrollableBox";
import BadgeAvatars from "../../Avatar/BadgeAvatars";
import MenuItem from "./MenuItem";
import constents from '../../../config/constents';
import { useNavigate } from 'react-router-dom';
import { clearStore } from "../../../utils/clearStore";
import { authApi } from '../../../services/api';
import { checkTokensValidation } from '../../../utils/checkTokensValidation';
import { useSelector } from 'react-redux';
import { checkLocation } from '../../../utils/location';
import { hasPermission } from '../../../middleware/permission';
import { PERMISSION_CODE } from '../../../config/permissionCodes';

const Sidebar = ({ isDrawerOpen, toggleDrawer, themeMode, onThemeChange }) => {
  const navigate = useNavigate();
  const { logout } = authApi();
  const profile = useSelector(state => state.profile);
  const staffRole = useSelector(state => state.staffRole);
  const locations = useSelector(state => state.locations);

  const { refresh } = checkTokensValidation();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { sidebarHeight, headerHight, sidebarWidth } = constents();

  const CustomDrawer = isMobile
    ? ({ children }) => <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer}>{children}</Drawer>
    : ({ children }) => <>{children}</>;

  const onLogout = () => {
    toggleDrawer();

    Swal.fire({
      title: 'Are you sure?',
      text: "You will be logged out!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, log out!',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Logged Out!',
          text: "You have been logged out successfully !",
          icon: 'success',
          timer: 2000,
        });

        // Perform logout actions here, such as redirecting to the login page or clearing tokens
        navigate("/");
        logout({ refreshToken: refresh });
        clearStore();
      }
    });
  };

  // Memoize badge content based on invalid locations
  const locationsBadgeContent = useMemo(() => {
    return locations.reduce((count, location) => {
      const { isLocationValid } = checkLocation(location);
      return isLocationValid ? count : count + 1;
    }, 0);
  }, [locations]);

  const menuItems = [
    { text: 'Dashboard', Icon: Dashboard, link: '/dashboard', badgeContent: 0, hasPermission: true },
    { text: 'Locations', Icon: LocationOn, link: '/locations', badgeContent: locationsBadgeContent, hasPermission: hasPermission(PERMISSION_CODE.LOCATION.VIEW) },
    { text: 'Floors', Icon: Apartment, link: '/floors', badgeContent: 0, hasPermission: hasPermission(PERMISSION_CODE.FLOOR.VIEW) },
    { text: 'Offers', Icon: Discount, link: '/offers', badgeContent: 0, hasPermission: hasPermission(PERMISSION_CODE.OFFER.VIEW) },
    { text: 'Staffs', Icon: Diversity3, link: '/staffs', badgeContent: 0, hasPermission: hasPermission(PERMISSION_CODE.STAFF_ROLE.VIEW) },
    { text: 'Students', Icon: People, link: '/students', badgeContent: 0, hasPermission: hasPermission(PERMISSION_CODE.STUDENT.VIEW) },
    { text: 'Attendance', Icon: QrCode, link: '/attendance', badgeContent: 0, hasPermission: hasPermission(PERMISSION_CODE.ATTENDANCE.VIEW) },
    { text: 'Subscriptions', Icon: Subscriptions, link: '/subscriptions', badgeContent: 0, hasPermission: hasPermission(PERMISSION_CODE.SUBSCRIPTION.VIEW) },
    { text: 'Notifications', Icon: Notifications, link: '/notifications', badgeContent: 0, hasPermission: true },
    { text: 'Settings', Icon: Settings, link: '/settings', badgeContent: 0, hasPermission: true },
  ].filter(({ hasPermission }) => hasPermission);

  return (
    <CustomDrawer>
      <Box
        sx={{
          width: { xs: 200, sm: sidebarWidth },
          display: 'flex',
          flexDirection: 'column',
          boxShadow: 2,
          mt: `${headerHight}px`,
          height: sidebarHeight,
          borderRight: 1,
          borderColor: 'divider',
        }}
      >
        {/* Profile Section */}
        <Box
          sx={{
            p: 2,
            backgroundColor: 'primary.main',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
            <BadgeAvatars userImage={profile.image} userName={profile.name} />
            <IconButton onClick={onThemeChange} aria-label="toggle theme">
              {themeMode === 'light' ? <Brightness4 sx={{ color: 'black' }} /> : <WbSunny sx={{ color: 'white' }} />}
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="body1" sx={{ color: 'primary.contrastText' }} noWrap>
              {profile.name?.toUpperCase()}
            </Typography>

            <Chip
              label={staffRole.name}
              size="small"
              variant="filled"
              color='warning'
            />
          </Box>
        </Box>

        {/* Menu Items */}
        <ScrollableBox>
          <List>
            {menuItems.map((item, index) => (
              <MenuItem key={index} {...item} toggleDrawer={toggleDrawer} />
            ))}
            <MenuItem Icon={Logout} text="Logout" onClick={onLogout} toggleDrawer={toggleDrawer} />
          </List>
        </ScrollableBox>
      </Box>
    </CustomDrawer>
  );
};

export default React.memo(Sidebar);