import React, { useState, useEffect } from "react";
import { TextField, InputAdornment, CircularProgress, Box, useTheme } from "@mui/material";
import { Search } from "@mui/icons-material";
// import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg"; // Replace with your SVG path

const SearchWithDebounce = ({ searchQuery = "", onSearch, placeholder }) => {
  const [searchTerm, setSearchTerm] = useState(searchQuery);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    setSearchTerm(searchQuery);
  }, [searchQuery]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    setLoading(true);

    const newTimeout = setTimeout(() => {
      onSearch(value);
      setLoading(false);
    }, 1000); // Debounce delay: 1 second

    setDebounceTimeout(newTimeout);
  };

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
    };
  }, [debounceTimeout]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 2,
        backgroundColor: theme.palette.background.default,
        padding: "8px 12px",
        width: "100%",
        boxShadow: theme.shadows[1],
        "&:focus-within": {
          borderColor: theme.palette.primary.dark,
        },
      }}
    >
      <Search style={{ width: 20, height: 20, color: theme.palette.primary.main, marginRight: 8 }} />
      <TextField
        variant="standard"
        fullWidth
        size="small"
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder={placeholder}
        InputProps={{
          disableUnderline: true,
          style: {
            fontSize: "16px",
            color: theme.palette.text.primary,
          },
          endAdornment: (
            <InputAdornment position="end">
              {loading && <CircularProgress size={20} color="inherit" />}
            </InputAdornment>
          ),
        }}
        sx={{
          "& .MuiInputBase-input": {
            padding: 0,
          },
        }}
      />
    </Box>
  );
};

export default SearchWithDebounce;
