import React from "react";
import { Box, Typography, IconButton, Badge } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import floorLayoutImage from "../../assets/svgs/floor_layout.svg";
import { useTheme } from "@emotion/react";
import CustomCard from "../Containers/Card";
import { hasPermission } from "../../middleware/permission";
import { PERMISSION_CODE } from "../../config/permissionCodes";

const FloorCard = ({ name, seats, onViewSeats, onEditSeats, onEditName, onDelete, canDelete = false }) => {
  const theme = useTheme();

  const hasViewPermission = hasPermission(PERMISSION_CODE.FLOOR.MANAGE_SEATS);
  const hasEditPermission = hasPermission(PERMISSION_CODE.FLOOR.UPDATE);
  const hasDeletePermission = hasPermission(PERMISSION_CODE.FLOOR.DELETE);

  return (
    <CustomCard
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing(2),
        cursor: hasViewPermission ? "pointer" : "default",
        ":hover": {
          transform: hasViewPermission ? "scale(0.98)" : ""
        },
        ":active": {
          transform: hasViewPermission ? "scale(0.95)" : ""
        },
        transition: "transform 0.2s ease-in-out",
      }}
      onClick={hasViewPermission ? onViewSeats : null}
    >
      {/* Title at the top center */}
      <Box sx={{ display: "flex", alignItems: "center", }}>
        <Typography variant="h6" > {name} </Typography>
        <IconButton sx={{ padding: 0, paddingLeft: 2, color: theme.palette.primary.main }} onClick={onEditName} disabled={!hasEditPermission}>
          <EditIcon />
        </IconButton>
      </Box>

      {/* Floor Layout Image */}
      <Box
        component="img"
        src={floorLayoutImage}
        alt="Floor Layout"
      />

      {/* Action Buttons */}
      <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
        {/* Seats Icon with Badge */}
        <IconButton color="inherit" sx={{ cursor: "default" }}>
          <Badge
            badgeContent={seats || "0"}
            sx={{
              "& .MuiBadge-badge": {
                top: -4,
                right: -4,
              },
            }}
          >
            <TableRestaurantIcon />
          </Badge>
        </IconButton>

        {/* Edit Icon */}
        <IconButton disabled={!hasViewPermission} onClick={onEditSeats} sx={{ color: theme.palette.primary.main }}>
          <EditIcon />
        </IconButton>

        {/* Delete Icon */}
        <IconButton disabled={!hasDeletePermission || !canDelete} onClick={onDelete} sx={{ color: theme.palette.error.main }}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </CustomCard>
  );
};

export default FloorCard;
