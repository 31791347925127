import React from "react";
import { Typography, Stack, Grid2, useMediaQuery, useTheme } from "@mui/material";
import { EventBusy, EventAvailable, Apartment, Chair } from "@mui/icons-material";
import * as moment from "moment";

const SubscriptionInfo = ({ duration, finalPrice, floorName, seatNumber, startDate, endDate }) => {
  const theme = useTheme(); // Access the current theme
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Grid2 container>
      <Grid2 size={{ xs: 12 }}>
        <Typography color="primary" mb={isMobile ? 1 : 0} > Active Reservation </Typography>
      </Grid2>

      <Grid2 size={{ xs: 12 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant="body2"
            color="text.primary"
            sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
          >
            <EventAvailable fontSize="small" />
            {duration?.value} {duration?.unit}
          </Typography>
          <Typography
            variant="body2"
            color="text.primary"
            sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
          >
            {/* Price: ₹{finalPrice || 1000} */}
            <EventBusy fontSize="small" sx={{ color: theme.palette.error.main }} />
            End: {moment(endDate).format("DD MMM YYYY")}
          </Typography>
        </Stack>
      </Grid2>

      <Grid2 size={{ xs: 12 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant="body1" color="text.secondary"
            sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
          >
            <Apartment fontSize="small" sx={{ color: theme.palette.text.secondary }} />
            Floor: {floorName || "Floor 1"}
          </Typography>

          <Typography
            variant="body1" color="text.secondary"
            sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
          >
            <Chair fontSize="small" sx={{ color: theme.palette.text.secondary }} />
            Seat: {seatNumber}
          </Typography>
        </Stack>
      </Grid2>

      {/* <Grid2 size={{ xs: 12 }}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                  >
                    Valid:{" "}
                    {moment(startDate).format("DD MMM YYYY")} -{" "}
                    {moment(endDate).format("DD MMM YYYY")}
                  </Typography>
                </Stack>
              </Grid2> */}
    </Grid2>
  );
};

export default SubscriptionInfo;
