import React from "react";
import { Button, Grid2 } from "@mui/material";
import { useQuery } from "react-query";
import PageContainer from "../../components/Containers/PageContainer";
import StaffCard from "../../components/Staffs/StaffCard";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { staffsApi } from "../../services/api";
import StaffCardSkeleton from "../../components/Staffs/StaffCardSkeleton";
import NoRecordsFound from "../../components/NoRecordFound";
import { Add } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { hasPermission } from "../../middleware/permission";
import { PERMISSION_CODE } from "../../config/permissionCodes";
import { hasFeature, showPlanUpgrade } from "../../middleware/subscription";
import { PlanUpgrade } from "../../components/Icons/PlanUpgrade";
import reduxData from "../../utils/useReduxData";

const StaffListPage = () => {
  const navigate = useNavigate();
  const permissions = useSelector(state => state.permissions) || [];

  const hasAddPermission = hasPermission(PERMISSION_CODE.STAFF_ROLE.CREATE);

  // React Query to fetch locations
  const { data: queryData, refetch, isLoading } = useQuery(
    ["staff-roles"],
    async () => await staffsApi().getAll(),
    {
      retry: false, // Disable retries on error
    },
  );

  const staffRoles = queryData?.results || [];
  reduxData("assetsCount", "updateObj")({ roles: staffRoles.length });
  reduxData("assetsCount", "updateObj")({ members: staffRoles.reduce((count, role) => count + (role?.totalMembers || 0), 0) });
  const hasFeaturePermission = hasFeature("roles");

  const handleView = (role) => () => {
    navigate(`members`, { state: { role } });
  }

  const handleAdd = () => {
    if (!hasFeaturePermission) return showPlanUpgrade();

    Swal.fire({
      title: "Add Role",
      input: "text",
      inputLabel: "Role Name",
      inputPlaceholder: "Enter role name",
      confirmButtonText: "Save",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      inputValidator: (value) => {
        if (!value) {
          return "Role name is required";
        }
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const roleName = result.value;
          const role = await staffsApi().create({ name: roleName });
          navigate(`members`, { state: { role } });
        } catch (error) {
        }
      }
    });
  };

  const handleEdit = (id) => () => {
    Swal.fire({
      title: "Edit Role",
      input: "text",
      inputLabel: "Role Name",
      inputPlaceholder: "Enter role name",
      inputValue: staffRoles.find((role) => role._id === id)?.name,
      confirmButtonText: "Save",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      inputValidator: (value) => {
        if (!value) {
          return "Role name is required";
        }
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const roleName = result.value;
          await staffsApi().update(id, { name: roleName });
          refetch();
        } catch (error) {
        }
      }
    })
  };

  const handleDelete = (id) => () => {
    Swal.fire({
      title: "Are you sure you want to delete this role?",
      text: "This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await staffsApi().deleteStaff(id);
          refetch();
        } catch (error) {
        }
      }
    });
  };

  const addStaffButton = (
    <Button
      startIcon={<Add />}
      endIcon={!hasFeaturePermission ? <PlanUpgrade /> : null}
      variant="outlined"
      size="small"
      onClick={handleAdd}
      disabled={!hasAddPermission}
    >
      Role
    </Button>
  );

  return (
    <PageContainer headerContent={addStaffButton}>
      <Grid2 container spacing={2}>
        {
          isLoading
            ? <>
              <Grid2 size={{ xs: 12, sm: 6, md: 4 }} > <StaffCardSkeleton /> </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, md: 4 }} > <StaffCardSkeleton /> </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, md: 4 }} > <StaffCardSkeleton /> </Grid2>
            </>
            : (
              staffRoles.length
                ? staffRoles.map((role) => (
                  <Grid2 key={role._id} size={{ xs: 12, sm: 6, md: 4 }}>
                    <StaffCard
                      {...role}
                      permissions={permissions.filter((permission) => role.permissions.includes(permission.code))}
                      onView={handleView(role)}
                      onEdit={handleEdit(role._id)}
                      onDelete={handleDelete(role._id)}
                    />
                  </Grid2>
                ))
                : <NoRecordsFound />
            )
        }
      </Grid2>
    </PageContainer>
  );
};

export default StaffListPage;
