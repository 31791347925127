import React from "react";
import PageContainer from "../../components/Containers/PageContainer";
import StudentForm from "../../components/Students/StudentForm";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

const AddStudent = () => {
  const navigate = useNavigate();

  // eslint-disable-next-line
  const handleCancel = () => {
    navigate(-1);
  };

  const GoBack = () => (
    <Button
      startIcon={<ArrowBack />}
      variant="outlined"
      color="primary"
      size="small"
      onClick={handleCancel}
    >
      Back
    </Button>
  );


  return (
    <PageContainer headerContent={<GoBack />}>
      <StudentForm mode="add" />
    </PageContainer>
  );
};

export default AddStudent;
