import React from "react";
import { Typography, Stack, Chip, Grid2, Avatar, useMediaQuery } from "@mui/material";
import { Email, Phone } from "@mui/icons-material";

const StudentInfo = ({ name, profilePicture, email, isActive, contactNumber, isSubscribed }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Grid2 container spacing={2} justifyContent={isSubscribed ? "start" : "end"}>
      <Grid2 size={{ xs: 3, sm: 2 }} sx={{ maxWidth: 70, minWidth: 70 }}>
        <Avatar
          src={profilePicture || ""}
          sx={{ width: 70, height: 70 }}
        />
      </Grid2>

      <Grid2 size={{ xs: 9, sm: 7 }} sx={{ minWidth: 200 }}>
        <Grid2 container>
          <Grid2 size={{ xs: 12 }}>
            <Stack direction="row" justifyContent={isMobile ? "space-between" : "flex-start"}>
              <Typography variant="h5" fontWeight="bold" noWrap> {name} </Typography>
              <Chip
                label={isActive ? "Active" : "Inactive"}
                color={isActive ? "success" : "error"}
                size="small"
                sx={{ width: '70px', ml: 2 }}
              />
            </Stack>
          </Grid2>

          <Grid2 size={{ xs: 12 }}>
            <Stack direction="column" justifyContent="flex-start" >
              <Typography
                variant="body1" color="text.secondary"
                sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
              >
                <Email fontSize="small" />
                {email}
              </Typography>

              <Typography
                variant="body1" color="text.secondary"
                sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
              >
                <Phone fontSize="small" />
                {contactNumber}
              </Typography>
            </Stack>
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default StudentInfo;
