import React, { useState } from 'react';
import Sidebar from "./Sidebar";
import TopBar from "./TopBar";
import ThemeSwitching from "./ThemeSwitching";
import { useSelector } from 'react-redux';
import reduxData from "../../../utils/useReduxData";
import { Box } from '@mui/material';

const PrivateWrapper = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const themeMode = useSelector(state => state.theme);
  const [isSwitchingTheme, setIsSwitchingTheme] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  const toggleTheme = () => {
    const newThemeMode = themeMode === 'light' ? 'dark' : 'light';
    reduxData("theme", "set")(newThemeMode);
  };

  const handleThemeChange = () => {
    setIsSwitchingTheme(true);
    toggleTheme();

    setTimeout(() => {
      setIsSwitchingTheme(false);
    }, 500);
  };

  return (
    <>
      <TopBar toggleDrawer={toggleDrawer} />
      <Box display="flex">
        <Sidebar isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} themeMode={themeMode} onThemeChange={handleThemeChange} />
        {children}
      </Box>
      {/* Theme Switching Animation */}
      {isSwitchingTheme && <ThemeSwitching themeMode={themeMode} />}
    </>
  );
};

export default PrivateWrapper;
