import React from "react";
import { Avatar, Box, Chip, Tooltip, Typography } from "@mui/material";
import { RowBuilder } from "../Containers/QueryContainer/RowBuilder";
import formattedContactNumber from "../../utils/formateContactNumber";
import moment from "moment";

const SubscriberRow = ({ subscriber, header }) => {
  const isCanceled = subscriber.isCanceled;
  const cancelReason = subscriber.cancelReason;
  const isActive = subscriber.status === "active";
  const statusColor = isCanceled ? "error" : isActive ? "success" : "warning";

  const subscriptionType = subscriber.type || "fixed";
  const isFixed = subscriptionType === "fixed";
  const startDate = moment(subscriber?.startDate).format("DD MMM YYYY");
  const canceledAt = moment(subscriber?.canceledAt).format("DD MMM YYYY");
  const endDate = moment(subscriber?.endDate).format("DD MMM YYYY");

  const floorSeatComponent = isFixed ? (
    <Box sx={{
      display: "flex",
      gap: 2,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <Typography variant="caption" noWrap> Floor: {subscriber?.floorName || "null"} </Typography>
      <Typography variant="caption" color="textSecondary" noWrap> Seat No.: {subscriber?.seatNumber || "null"} </Typography>
    </Box>
  ) : null;

  return (
    <RowBuilder
      header={header}
      columns={{
        image: {
          primary: (
            <Avatar
              src={"studentImage" || ""}
              alt={subscriber?.name}
              sx={{ width: 60, height: 60 }}
            />
          ),
          secondary: null,
        },
        name: {
          primary: <Tooltip title={subscriber?.email}>
            <Typography variant="body1" noWrap> {subscriber?.name} </Typography>
          </Tooltip >,
          secondary: <Typography variant="body2" color="textSecondary" noWrap> {formattedContactNumber(subscriber?.contactNumber)} </Typography>,
        },
        type: {
          primary: (
            <Chip
              label={subscriptionType}
              size="small"
              color={isFixed ? "primary" : "secondary"}
              sx={{ color: "white", width: "50%", textTransform: "capitalize", fontWeight: "bold" }}
            />
          ),
          secondary: floorSeatComponent
        },
        dates: {
          primary: <Typography variant="body2" noWrap> Start: {startDate} </Typography>,
          secondary: <Typography variant="body2" color="textSecondary" noWrap>
            {
              subscriber.canceledAt
                ? `Cancelled on ${canceledAt}`
                : `End: ${endDate}`
            }
          </Typography>,
        },
        status: {
          primary: (
            <Chip
              label={subscriber.status}
              size="small"
              color={statusColor}
              sx={{ color: "white", width: "50%", textTransform: "capitalize", fontWeight: "bold" }}
            />
          ),
          secondary: isCanceled
            ? <Typography variant="caption" color="error" noWrap width="90%"><Tooltip title={cancelReason}>  {cancelReason}</Tooltip> </Typography>
            : null
        },
      }}
    />
  );
};

export default SubscriberRow;
