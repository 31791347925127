import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { checkTokensValidation } from "../utils/checkTokensValidation";
import Private from "../components/Layouts/Private";
import Public from "../components/Layouts/Public";
import { checkPermission, hasPermission } from "../middleware/permission";
import { checkSubscription, hasFeature } from "./subscription";

export const checkAuthentication = (Component, isProtected = true, subscriptionsFeature = "", permissionCode) => (props) => {
  const navigate = useNavigate();
  const { isLoggedIn } = checkTokensValidation();

  useEffect(() => {
    if (isLoggedIn && !isProtected) navigate("/dashboard");
    if (!isLoggedIn && isProtected) navigate("/");
  }, [isLoggedIn, navigate]);

  const Layout = isLoggedIn ? Private : Public;

  if (!hasFeature(subscriptionsFeature)) {
    Component = checkSubscription(Component, subscriptionsFeature);
  } else if (!hasPermission(permissionCode)) {
    Component = checkPermission(Component, permissionCode);
  }

  return <Layout> <Component {...props} /> </Layout>;
};