// services/paymentService.ts

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}

export const initiatePayment = async (order) => {
  return new Promise(async (resolve, reject) => {

    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (!res) {
      alert('Razropay failed to load!!')
      return
    }

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY, // Razorpay Key from env
      amount: order.amount,
      currency: "INR",
      name: "S3 Library",
      description: `Payment for ${order.description}`,
      order_id: order.id,
      handler: function (response) {
        resolve(response);
      },
      prefill: order.prefill,
      theme: {
        color: "#08518b",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();

    paymentObject.on("payment.failed", function (response) {
      reject(response.error);
    });
  });
};
