import { Check, Close } from "@mui/icons-material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, Chip, useTheme, Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { hasPermission } from "../../middleware/permission";
import { PERMISSION_CODE } from "../../config/permissionCodes";
import { calculatePrice } from "../../utils/calculatePrice";

const PricingTable = ({ plans, featuresKeyName, onChange }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState({ planId: "" });
  const mySubscription = useSelector(state => state.subscription);

  const hasChangePermission = hasPermission(PERMISSION_CODE.PLAN.CHANGE)

  const handlePlanSelect = async (plan) => {
    const planId = plan._id;

    setLoading({ planId });
    try {
      await onChange(planId);
    } catch (error) {
      alert("Plan Selection Failed. Please try again.");
    }
    setLoading({ planId: "" });
  };

  const isCurrentPlan = (plan) => {
    if (mySubscription && Object.keys(mySubscription).length) {
      return mySubscription.planInfo.key === plan.key;
    } else {
      return false;
    }
  }

  const isSubscribed = (plan) => {
    if (mySubscription && Object.keys(mySubscription).length) {
      return mySubscription.planInfo?.key === plan?.key &&
        mySubscription.planInfo?.duration?.value === plan?.duration?.value &&
        mySubscription.planInfo?.duration?.key === plan?.duration?.key;
    } else {
      return false;
    }
  }

  const canClick = (plan) => {
    return !(loading.planId === plan._id || isSubscribed(plan) || plan.key === "free") && hasChangePermission;
  }

  const getBtnText = (plan) => {
    if (mySubscription && Object.keys(mySubscription).length) {
      if (mySubscription.planInfo.finalPrice > plan?.finalPrice) {
        return "Downgrade plan";
      } else if (mySubscription.planInfo.finalPrice < plan?.finalPrice) {
        return "Upgrade plan";
      } else if (mySubscription.planInfo.finalPrice === plan?.finalPrice) {
        return "Current Plan";
      }

    } else {
      return "Upgrade Now";
    }
  }

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: 3,
        overflowX: "auto",
        border: "1px solid",
        borderBottom: "2px solid",
        borderColor: theme.palette.divider,
      }}
    >
      <Table>
        {/* Table Head */}
        <TableHead>
          <TableRow sx={{ backgroundColor: theme.palette.background.default }}>
            <TableCell
              align="center"
              sx={{
                fontWeight: "bold",
                padding: "16px",
                width: "40%",
                backgroundColor: theme.palette.action.hover,
                position: "sticky",
                left: 0,
                zIndex: 1,
              }}
            >
              Features
            </TableCell>
            {plans.map((plan, index) => {
              const { price, discountAmount, priceAfterDiscount } = calculatePrice(plan.price, plan.discount).getMonthly(plan.duration)

              return (
                <TableCell
                  key={plan.key}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    padding: "16px",
                    backgroundColor: isCurrentPlan(plan) ? theme.palette.primary.main : theme.palette.action.hover,
                    color: isCurrentPlan(plan) ? "white" : "inherit",
                    borderLeft: `1px solid ${theme.palette.divider}`
                  }}
                >
                  <Typography variant="h6" fontWeight="bold">{plan.title}</Typography>

                  {/* Pricing */}
                  <Box sx={{ my: 1 }}>
                    {plan.discount.value > 0 && <Typography color={isCurrentPlan(plan) ? theme.palette.grey[400] : "textSecondary"} sx={{ textDecoration: "line-through", mr: 1 }}>₹{price}</Typography>}
                    <Typography> ₹{priceAfterDiscount}</Typography>
                    {plan.discount.value > 0 && (
                      <Chip
                        label={`Save ₹${discountAmount}`}
                        color="success"
                        size="small"
                        sx={{ mt: 1 }}
                      />
                    )}
                    <Typography variant="body2" color={isCurrentPlan(plan) ? theme.palette.grey[400] : "textSecondary"}>per month</Typography>
                  </Box>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>

        {/* Table Body */}
        <TableBody>
          {featuresKeyName.map((feature, featureIndex) => (
            <TableRow
              key={feature.key}
              sx={{
                backgroundColor: featureIndex % 2 === 0 ? theme.palette.action.disabled : "inherit",
                "&:hover": { backgroundColor: theme.palette.action.selected },
                transition: "all 0.2s ease-in-out"
              }}
            >
              {/* Feature Name */}
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  backgroundColor: theme.palette.action.hover,
                  position: "sticky",
                  left: 0,
                  zIndex: 1,
                  padding: "12px",
                }}
              >
                {feature.name}
              </TableCell>

              {/* Feature Values */}
              {plans.map((plan) => (
                <TableCell
                  key={plan.key}
                  align="center"
                  sx={{
                    padding: "12px",
                    borderLeft: `1px solid ${theme.palette.divider}`,
                    p: 0,
                    m: 0,
                  }}
                >
                  {typeof plan.features[feature.key] === "boolean"
                    ? plan.features[feature.key]
                      ? <Check sx={{ color: theme.palette.success.main }} />
                      : <Close sx={{ color: theme.palette.error.main }} />
                    : plan.features[feature.key]}
                </TableCell>
              ))}
            </TableRow>
          ))}

          <TableRow sx={{ backgroundColor: theme.palette.background.default }}>
            <TableCell
              align="center"
              sx={{
                fontWeight: "bold",
                padding: "16px",
                width: "40%",
                backgroundColor: theme.palette.action.hover,
                position: "sticky",
                left: 0,
                zIndex: 1,
              }}
            >
              Action
            </TableCell>
            {plans.map((plan, index) => (
              <TableCell
                key={plan.key}
                align="center"
                sx={{
                  fontWeight: "bold",
                  padding: "16px",
                  borderLeft: `1px solid ${theme.palette.divider}`
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  fullWidth
                  onClick={() => canClick(plan) ? handlePlanSelect(plan) : null}
                  disabled={!canClick(plan)}
                >
                  {loading.planId === plan._id ? <CircularProgress size={24} color="inherit" /> : getBtnText(plan)}
                </Button>
              </TableCell>
            ))}
          </TableRow>

        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PricingTable;
