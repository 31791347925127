import React from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";

import PageContainer from "../../components/Containers/PageContainer";
import StudentRow from "../../components/Students/StudentRow";
import { studentApi } from "../../services/api";
import QueryListContainer from "../../components/Containers/QueryContainer/Main";
import { hasPermission } from "../../middleware/permission";
import { PERMISSION_CODE } from "../../config/permissionCodes";
import { hasFeature, showPlanUpgrade } from "../../middleware/subscription";
import { PlanUpgrade } from "../../components/Icons/PlanUpgrade";
import Swal from "sweetalert2";

const Students = () => {
  const navigate = useNavigate();
  const { deleteStudent } = studentApi();

  const hasAddPermission = hasPermission(PERMISSION_CODE.STUDENT.CREATE);
  const hasFeaturePermission = hasFeature("addStudent");

  // Toggle student form
  const handleToggleStudentForm = () => {
    if (!hasFeaturePermission) return showPlanUpgrade();
    navigate(`/students/add`);
  };

  // View student details
  const handleView = (student) => {
    navigate(`/students/bookings`, { state: { student } });
  };

  // Edit student details
  const handleEdit = (student) => {
    navigate(`/students/edit`, { state: { student } });
  };

  // Delete student (placeholder for actual functionality)
  const handleDelete = (refetch) => async (student) => {
    Swal.fire({
      type: "warning",
      title: "Remove Student",
      text: "Are you sure you want to remove this student from this location?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it!"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteStudent(student._id);
          refetch();
        } catch (error) {
          Swal.fire({
            icon: "error",
            type: "error",
            title: "Can't remove the student",
            text: error["message"],
          });
        }
      }
    });
  };

  // Add student button
  const addStudentButton = (
    <Button
      variant="outlined"
      size="small"
      color="primary"
      onClick={handleToggleStudentForm}
      startIcon={<Add />}
      endIcon={!hasFeaturePermission ? <PlanUpgrade /> : null}
      disabled={!hasAddPermission}
    >
      Add Student
    </Button>
  );

  const sortOptions = [
    { key: "name", label: "Name" },
    { key: "email", label: "Email" },
  ];

  const filters = {
    status: [
      { value: "", label: "All" },
      { value: "active", label: "Active" },
      { value: "inactive", label: "In Active" },
    ],
    subscriptionType: [
      { value: "", label: "All" },
      { value: "no_subscriptions", label: "No Subscriptions" },
      { value: "fixed", label: "Fixed" },
      { value: "flexible", label: "Flexible" },
    ]
  };

  const header = [
    { size: 1, align: "start", key: "image", label: "Image" },
    { size: 3, align: "start", key: "name", label: "Name" },
    { size: 3, align: "center", key: "contact", label: "Contact" },
    { size: 2, align: "center", key: "status", label: "Status" },
    // { size: 2, align: "center", key: "rating", label: "Rating" },
    { size: 2, align: "center", key: "subscription", label: "Subscription" },
    { size: 1, align: "end", key: "actions", label: "Actions" },
  ];

  return (
    <PageContainer headerContent={addStudentButton}>
      <QueryListContainer
        fetchData={studentApi().getAll}
        filters={filters}
        sortOptions={sortOptions}
        header={header}
        getItems={(students, refetch) => students.map((student) => (
          <StudentRow
            student={student}
            header={header}
            onView={handleView}
            onEdit={handleEdit}
            onDelete={handleDelete(refetch)}
          />
        ))}
        noDataFound={{
          title: 'No Students Found',
          subTitle: hasAddPermission ? "Click on the button below to add a new students." : "Please Refresh the page",
          buttonText: hasAddPermission ? "Add Students" : "",
          ButtonIcon: hasAddPermission ? (hasFeaturePermission ? Add : PlanUpgrade) : "",
          onRefresh: hasAddPermission ? handleToggleStudentForm : "",
        }}
      />
    </PageContainer>
  );
};

export default Students;
