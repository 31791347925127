import React from "react";
import { Box, Skeleton, Stack, Grid2, LinearProgress } from "@mui/material";
import CustomCard from "../Containers/Card";

const LocationReviewSummarySkeleton = () => {
  return (
    <CustomCard>
      <Grid2 container spacing={2} alignItems="center">

        {/* Overall Rating Section */}
        <Grid2 size={{ xs: 12, sm: 5 }}>
          <Box
            sx={{
              width: "100%",
              minWidth: 120,
              textAlign: "center",
              borderRadius: 4,
              boxShadow: 4,
              backgroundColor: "primary.main",
              alignItems: "center",
              p: 1,
            }}
          >
            <Skeleton variant="text" width={60} height={48} sx={{ margin: "auto" }} />
            <Skeleton variant="text" width={100} height={20} sx={{ margin: "auto" }} />
            <Skeleton variant="text" width={80} height={20} sx={{ margin: "auto" }} />
          </Box>
        </Grid2>

        {/* Ratings Breakdown Section */}
        <Grid2 size={{ xs: 12, sm: 7 }}>
          {[...Array(5)].map((_, index) => (
            <Stack key={index} spacing={1} direction="row" alignItems="center">
              <Skeleton variant="text" width={20} height={20} />
              <Skeleton variant="circular" width={16} height={16} />
              <LinearProgress
                sx={{ height: 6, borderRadius: 2, width: "100%", minWidth: 100 }}
                variant="determinate"
                value={0}
              />
              <Skeleton variant="text" width={30} height={20} />
            </Stack>
          ))}
        </Grid2>

      </Grid2>
    </CustomCard>
  );
};

export default LocationReviewSummarySkeleton;
