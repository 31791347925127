import { Box } from '@mui/material';
import React from 'react';
import appIcon from "../../../assets/icons/app_icon.svg";

const PublicWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        textAlign: 'center',
        background: 'linear-gradient(to bottom, #08518b, #00b3de)',
        flexDirection: 'column',
        px: 2,
        position: 'relative', // Ensure child elements respect the parent positioning
      }}
    >
      {/* Main Content */}
      <Box
        sx={{

          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          flexDirection: 'column',

          bgcolor: 'background.paper',
          width: { xs: '100%', sm: '60%', md: '50%' },
          p: { xs: 2, sm: 3, md: 4 },
          borderRadius: 3,
          boxShadow: 4,
        }}
      >
        <Box component="img" src={appIcon} sx={{ width: 150, pb: 2, pt: 2 }} />
        {children}
      </Box>
    </Box>
  );
};

export default PublicWrapper;
