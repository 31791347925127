import React from "react";
import { Menu, MenuItem, ListItemIcon, Typography, TextField, useTheme, Box } from "@mui/material";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";

const MenuList = ({ items, anchorEl, onOpen, onClose, selectedItem, onSelect, label = "label" }) => {
  const theme = useTheme();

  return (
    <>
      <Box sx={{ position: "relative", width: "100%" }}>
        <TextField
          variant="outlined"
          value={selectedItem}
          size="small"
          onClick={onOpen}
          label={label}
          sx={{
            maxWidth: "120px",
            cursor: "pointer",
            "& .MuiOutlinedInput-root": {
              borderRadius: 2,
              borderColor: theme.palette.divider,
            },
            "&:hover .MuiOutlinedInput-root": {
              borderColor: theme.palette.primary.main,
            },
          }}
          InputProps={{
            readOnly: true,
          }}
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        PaperProps={{
          sx: {
            padding: 1,
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[2],
          },
        }}
      >
        {items.map((item) => (
          <MenuItem
            key={item.key}
            onClick={() => onSelect(item.key)}
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "2px 16px",
            }}
          >
            <ListItemIcon>
              {item.label === selectedItem ? (
                <RadioButtonChecked color="primary" />
              ) : (
                <RadioButtonUnchecked color="disabled" />
              )}
            </ListItemIcon>
            <Typography variant="body1">{item.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MenuList;
