import { store } from "../redux/store";
import reduxData from "../utils/useReduxData";
import PageContainer from "../components/Containers/PageContainer";

export const checkSubscription = (Component, featureKey) => ({ propes }) => {

  if (!hasFeature(featureKey)) {
    showPlanUpgrade();
    return <PageContainer> Please Upgrade Your Plan </PageContainer>
  }

  return <Component {...propes} />;
};

export const hasFeature = (featureKey) => {
  let allow = true;
  const subscription = store.getState().subscription || {};
  const assetsCount = store.getState().assetsCount || {};

  if (featureKey && subscription && subscription.planInfo && subscription.planInfo?.features && Object.keys(subscription.planInfo.features).includes(featureKey)) {
    allow = false;

    const features = subscription.planInfo?.features;
    const featureVal = features[featureKey];

    if (typeof featureVal === "boolean") {
      allow = featureVal;
    } else if (typeof featureVal === "number" && Object.keys(assetsCount).includes(featureKey)) {
      const assetsCountVal = assetsCount[featureKey];

      allow = featureVal > assetsCountVal;
    }
  }

  return allow;
};

export const showPlanUpgrade = () => {
  reduxData("showPlanUpgrade", "set")(true);
}
