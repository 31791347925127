import React from 'react';
import PageContainer from "../components/Containers/PageContainer"
import UnderDevelopment from '../components/UnderDevelopment';

const Notifications = () => {
  return (
    <PageContainer>
      <UnderDevelopment />
    </PageContainer>
  );
};

export default Notifications;
