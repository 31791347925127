import React, { useState } from "react";
import { Box, Typography, Chip, Avatar, Tooltip, Button, CircularProgress } from "@mui/material";
import * as moment from "moment";
import config from "../../config/config";
import { RowBuilder } from "../Containers/QueryContainer/RowBuilder";
import formattedContactNumber from "../../utils/formateContactNumber";
import { FloorSeat } from "../Common/FloorSeat";
import { hasPermission } from "../../middleware/permission";
import { PERMISSION_CODE } from "../../config/permissionCodes";

const BookingRow = ({ booking, header, onCheckOut }) => {
  const { bookedAt, checkedOutAt, checkedInAt, studentImage, studentContactNumber, studentName, studentEmail, source = "online", status = "pending", floorName, seatNumber } = booking;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const hasCheckOutPermission = hasPermission(PERMISSION_CODE.ATTENDANCE.RELEASE_SEAT_OR_CHECK_OUT);

  const bookingDate = moment(bookedAt).format("MMM DD, YYYY");
  const checkInTime = checkedInAt
    ? checkedOutAt ? moment(checkedInAt).format("hh:mm A") : moment(checkedInAt).fromNow()
    : "Coming Soon";
  const checkOutTime = checkedOutAt ? moment(checkedOutAt).format("hh:mm A") : null;

  const statusColor = config.bookingStatusColor[status];
  const isBookingRunning = status === "running";
  const isCanceled = status === "cancelled";
  const cancelledAt = moment(booking.cancelledAt).format("hh:mm A");
  const cancelledReason = booking.cancelledReason;

  const handleCheckOut = async () => {
    setIsSubmitting(true);
    await onCheckOut();
    setIsSubmitting(false);
  };

  return (
    <RowBuilder
      header={header}
      columns={{
        image: {
          primary: <Avatar
            src={studentImage || ""}
            alt={studentName || "Student"}
            sx={{ width: 60, height: 60 }}
          />,
          secondary: "",
        },
        name: {
          primary: (
            <Tooltip title={studentEmail}>
              <Typography variant="body1" noWrap> {studentName} </Typography>
            </Tooltip>
          ),
          secondary: <Typography variant="body2" color="textSecondary" noWrap> {formattedContactNumber(studentContactNumber)} </Typography>,
        },
        booked_on: {
          primary: (
            <Chip
              label={source}
              size="small"
              color={source === "online" ? "success" : "primary"}
              variant="outlined"
              sx={{ width: "50%", textTransform: "capitalize", fontWeight: "bold" }}
            />
          ),
          secondary: <Typography variant="body2" color="textSecondary"> {bookingDate} </Typography>,
        },
        status: {
          primary: (
            <Chip
              label={status}
              size="small"
              color={statusColor}
              variant="filled"
              sx={{ width: "50%", textTransform: "capitalize", fontWeight: "bold" }}
            />
          ),
          secondary: (
            isCanceled
              ? <Typography variant="caption" color="error" noWrap width="90%"><Tooltip title={cancelledReason}>  {cancelledReason}</Tooltip> </Typography>
              : <FloorSeat floorName={floorName} seatNumber={seatNumber} />

          ),
        },
        check_in_out_time: {
          primary: (
            isCanceled
              ? <Typography variant="body1" color="error"> Cancelled On:  {cancelledAt} </Typography>
              : <Typography variant="body1"> Check In: {checkInTime} </Typography>
          ),
          secondary: (
            isCanceled
              ? null
              : <Typography variant="caption" color="text.secondary"> Check Out: {checkOutTime} </Typography>
          ),
        },
        actions: {
          primary: (
            isBookingRunning && <Button
              variant="contained"
              size="small"
              color="error"
              onClick={handleCheckOut}
              disabled={isSubmitting || !hasCheckOutPermission}
              sx={{ textTransform: "capitalize", boxShadow: 2 }}
            >
              {isSubmitting && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <CircularProgress size={20} />
                </Box>
              )}
              Check Out
            </Button>
          ),
          secondary: "",
        },
      }} />
  );
};

export default BookingRow;
