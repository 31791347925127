import React from "react";
import { Box, CircularProgress, Grid2, useTheme, Stack } from "@mui/material";
import InfiniteScroll from "./InfiniteScroll";
import ItemSkeleton from "./ItemSkeleton";
import ScrollableBox from "../ScrollableBox";

const DataList = ({ items, header, isLoading, hasMore, loadMore }) => {
  const theme = useTheme();

  if (!items.length && !isLoading) {
    return null;
  }

  return <ScrollableBox
    sx={{
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      mt: "10px",
      borderRadius: 2,
      height: "auto",
      maxHeight: "57vh",
      minHeight: "100px",
      border: "1px solid",
      borderBottom: "2px solid",
      borderColor: theme.palette.divider,
    }}
  >
    <Stack spacing={2} minWidth="1000px">
      <Grid2 container spacing={2} sx={{ position: "sticky", top: 0, padding: 2, py: 1, background: theme.palette.primary.main, zIndex: 10 }}>
        {header.map((col) => (
          <Grid2 size={{ xs: col.size }} key={col.key} sx={{ fontWeight: "bold", color: "white", textAlign: col.align || "start" }} >
            {col.label}
          </Grid2>
        ))}
      </Grid2>

      <InfiniteScroll
        loadMore={loadMore}
        hasMore={hasMore}
        loader={
          <Box display="flex" justifyContent="center" padding={2}>
            <CircularProgress />
          </Box>
        }
      >
        {
          isLoading
            ? (<ItemSkeleton cols={header.length} />)
            : items
        }
      </InfiniteScroll>
    </Stack>
  </ScrollableBox>
};

export default DataList;
