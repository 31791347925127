import React from "react";
import { Grid2, Skeleton } from "@mui/material";

const ItemSkeleton = ({ rows = 6, cols = 5 }) => {
  return Array.from({ length: rows }).map((row) => (
    <Grid2 container spacing={2} alignItems="center" sx={{ borderBottom: "2px solid gray", padding: 2, py: 0.5 }}>
      {Array.from({ length: cols }).map((col) => (
        <Grid2 size={{ xs: (10 / cols) }}>
          <Skeleton variant="text" width="70%" height={30} />
          <Skeleton variant="text" width="70%" height={20} />
        </Grid2>
      ))}
      <Grid2 size={{ xs: (10 / cols) }}>
        <Skeleton variant="text" width="70%" height={20} />
      </Grid2>
    </Grid2>
  ));
};

export default ItemSkeleton;
