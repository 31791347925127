import React from "react";
import { Typography, Divider } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";

const RevenueChart = ({ data }) => {
  return (
    <>
      <Typography variant="h6">
        Monthly Revenue
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="revenue" fill="#08518b" />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default RevenueChart;
