import React from "react";
import { TextField, Grid2 } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

// Reusable Field Component
const CustomField = ({ name, label, value, type = "text", error, helperText, disabled = false, inputProps = {}, onChange = () => { }, }) => (
  <Field
    name={name}
    as={TextField}
    value={value}
    label={label}
    type={type}
    variant="outlined"
    size="small"
    fullWidth
    error={error}
    helperText={helperText}
    disabled={disabled}
    inputProps={inputProps}
    onChange={onChange}
    InputLabelProps={{
      shrink: value !== "" && value !== undefined, // Ensures label floats if there's a value
    }}
  />
);

const AddLocationForm = ({ initialValues, handleLocationNameChange, handleLocationContactChange, onSubmit, locationFound }) => {
  // Validation schema
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    contactNumber: Yup.string()
      .matches(/^\d{10}$/, "Phone number must contain exactly 10 digits")
      .required("Phone number is required"),
  });

  return (
    <Formik
      initialValues={{ ...initialValues, contactNumber: initialValues.contactNumber?.phoneNumber || initialValues.contactNumber }}
      validationSchema={validationSchema}
      onSubmit={onSubmit} // Pass onSubmit handler
      enableReinitialize // Allow the form to update initialValues dynamically
    >
      {({ errors, touched, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Grid2 container spacing={2} sx={{ mb: 2 }}>
            {/* Name */}
            <Grid2 size={{ xs: 12, sm: 6, md: 12 }}>
              <CustomField
                name="name"
                label="Branch Name"
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
                value={initialValues.name}
                onChange={handleLocationNameChange}
              />
            </Grid2>

            {/* Contact Number */}
            <Grid2 size={{ xs: 12, sm: 6, md: 12 }}>
              <CustomField
                name="contactNumber"
                label="Contact Number"
                error={touched.contactNumber && Boolean(errors.contactNumber)}
                helperText={touched.contactNumber && errors.contactNumber}
                inputProps={{ maxLength: 10 }}
                value={initialValues.contactNumber?.phoneNumber || initialValues.contactNumber}
                onChange={handleLocationContactChange}
              />
            </Grid2>

            {/* City */}
            <Grid2 size={{ xs: 12, sm: 6, md: 12 }}>
              <CustomField
                name="city"
                label="City"
                disabled={locationFound}
                value={initialValues.city}
              />
            </Grid2>

            {/* State */}
            <Grid2 size={{ xs: 12, sm: 6, md: 12 }}>
              <CustomField
                name="state"
                label="State"
                disabled={locationFound}
                value={initialValues.state}
              />
            </Grid2>

            {/* Postal Code */}
            <Grid2 size={{ xs: 12, sm: 6, md: 12 }}>
              <CustomField
                name="postalCode"
                label="Postal Code"
                disabled={locationFound}
                value={initialValues.postalCode}
              />
            </Grid2>

            {/* Country */}
            <Grid2 size={{ xs: 12, sm: 6, md: 12 }}>
              <CustomField
                name="country"
                label="Country"
                disabled={locationFound}
                value={initialValues.country}
              />
            </Grid2>
          </Grid2>

          <button type="submit" style={{ display: "none" }} />
        </Form>
      )}
    </Formik>
  );
};

export default AddLocationForm;
