export const checkLocation = (location) => {
  let isLocationValid = false;
  if (location && location.geoLocation) {
    let { coordinates } = location.geoLocation;
    coordinates = coordinates.filter((val) => val);
    isLocationValid = coordinates.length === 2;
  }

  return {
    isLocationValid,
  }
}

export const getCompleteAddress = (location) => {
  const { address, city, state, postalCode, country } = location;
  const completeAddress = [address, city, state, postalCode, country]
    .filter((item) => item)
    .join(", ");
  return completeAddress;
};
