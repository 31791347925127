import React from "react";
import { Box, Typography, Grid2, LinearProgress, useMediaQuery, useTheme, Stack } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import RatingStar from "../Common/RatingStar";
import CustomCard from "../Containers/Card";

const LocationReviewSummary = ({ ratingSummary = {} }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Mobile Check
  const hasRatingSummary = ratingSummary && !!Object.keys(ratingSummary).length;

  return (
    <CustomCard>
      <Grid2 container spacing={2} alignItems="center">
        {/* Overall Rating Section */}
        {hasRatingSummary &&
          <>
            <Grid2 size={{ xs: 12, sm: 5 }}>
              <Box
                sx={{
                  width: "100%",
                  minWidth: 120,
                  textAlign: "center",
                  borderRadius: 4,
                  boxShadow: 4,
                  // border: "1px solid #E0E0E0",
                  backgroundColor: "primary.main",
                  alignItems: "center",
                  p: 1,
                }}
              // color="CaptionText"
              >
                <Typography variant={isMobile ? "h2" : "h2"} fontWeight="bold" color="white">
                  {ratingSummary.overallRating}
                </Typography>

                <RatingStar rating={ratingSummary.overallRating} />
                <Box height={5} />

                <Typography variant="body2" color="white">
                  {ratingSummary.totalRatings?.toLocaleString()} ratings
                </Typography>
              </Box>
            </Grid2>

            {/* Ratings Breakdown */}
            <Grid2 size={{ xs: 12, sm: 7 }}>
              {ratingSummary.breakdown?.map((item) => (
                <Stack
                  display="flex"
                  spacing={1}
                  direction="row"
                  alignItems="center"
                >
                  <Typography variant="body2">{item.stars}</Typography>

                  <StarIcon sx={{ fontSize: 16, color: "#FFD700" }} />

                  <LinearProgress
                    variant="determinate"
                    value={item.percentage}
                    sx={{
                      height: 6,
                      borderRadius: 2,
                      width: "100%",
                      minWidth: 100,
                      // backgroundColor: "#E0E0E0",
                    }}
                  />

                  <Typography variant="body2" color="text.secondary">
                    {item.percentage}%
                  </Typography>
                </Stack>
              ))}
            </Grid2>
          </>
        }
      </Grid2>
    </CustomCard>
  );
};

export default LocationReviewSummary;
