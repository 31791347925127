import React, { useState } from "react";
import { Typography, useTheme, Stack, Tooltip, Box, Button, CircularProgress, Avatar, Chip } from "@mui/material";
import { EventBusy, Discount } from "@mui/icons-material";
import moment from "moment";
import config from "../../config/config";
import Swal from "sweetalert2";
import { RowBuilder } from "../Containers/QueryContainer/RowBuilder";
import formattedContactNumber from "../../utils/formateContactNumber";
import { FloorSeat } from "../Common/FloorSeat";

const SubscriptionRow = ({
  subscription,
  header,
  onConfirmPayment,
  onCancelSubscription,
}) => {
  const theme = useTheme();
  const [isSubmiting, setIsSubmiting] = useState({ key: "" });

  const { seatNumber, floorName, startDate, endDate, status, student, discount, price, finalPrice, type, canceledAt, cancelReason = "Canceled by admin" } = subscription;

  const formattedStartDate = startDate ? moment(startDate).format("DD MMM YYYY") : "N/A";
  const formattedEndDate = endDate ? moment(endDate).format("DD MMM YYYY") : "N/A";
  const formattedCancelDate = canceledAt ? moment(canceledAt).format("DD MMM YYYY") : "N/A";
  const isReserved = type === 'fixed';
  const isPanding = status === 'pending';
  const isCanceled = status === 'cancelled';
  const isActive = status === 'active';

  const statusColor = config.subscriptionStatusColor[status];
  const subscriptionStatus = status;

  const remainingTime = isActive ?
    isReserved
      ? "End " + moment(endDate).fromNow()
      : moment().add(subscription.totalAvailableSeconds, "seconds").diff(moment(), "hours") + " hours remaining"
    : formattedEndDate

  const floorSeatComponent = isReserved
    ? (
      <FloorSeat
        floorName={floorName}
        seatNumber={seatNumber}
      />
    )
    : null;

  const handleCancelSubscription = async () => {
    setIsSubmiting({ key: "cancel" });
    Swal.fire({
      title: 'Reason For Cancelcation',
      input: 'text',
      inputPlaceholder: 'Enter reason for cancellation',
      confirmButtonText: 'Confirm',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      inputValidator: (value) => {
        if (!value) {
          return 'Reason is required';
        }
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        await onCancelSubscription(result.value);
        setIsSubmiting({ key: "" });
      } else {
        setIsSubmiting({ key: "" });
      }
    })
  }

  const handleConfirmPayment = async () => {
    setIsSubmiting({ key: "confirm" });
    await onConfirmPayment();
    setIsSubmiting({ key: "" });
  }

  return (
    <RowBuilder
      header={header}
      columns={{
        image: {
          primary: <Avatar src={student?.image || ""} alt={student?.name} sx={{ width: 60, height: 60 }} />,
        },
        name: {
          primary: (
            <Tooltip title={student?.email}>
              <Typography variant="body1" noWrap>
                {student?.name}
              </Typography>
            </Tooltip>
          ),
          secondary: <Typography variant="body2" color="textSecondary" noWrap>{formattedContactNumber(student?.contactNumber)}</Typography>,
        },
        type: {
          primary: (
            <Chip
              label={subscription.type}
              size="small"
              color={isReserved ? "primary" : "secondary"}
              variant="outlined"
              sx={{ width: "50%", textTransform: "capitalize", fontWeight: "bold" }}
            />
          ),
          secondary: floorSeatComponent
        },
        dates: {
          primary: <Typography variant="body2" noWrap> Start: {formattedStartDate} </Typography>,
          secondary: <Typography
            noWrap
            variant="body2"
            color="textSecondary"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            {
              isCanceled
                ? `Cancelled on ${formattedCancelDate}`
                : <>
                  <EventBusy color="warning" fontSize="small" />
                  {remainingTime}
                </>
            }
          </Typography>,
        },
        status: {
          primary: (
            <Chip
              label={subscriptionStatus}
              size="small"
              color={statusColor}
              sx={{ color: "white", width: "50%", textTransform: "capitalize", fontWeight: "bold" }}
            />
          ),
          secondary: isCanceled
            ? <Typography variant="caption" color="error" noWrap width="90%"><Tooltip title={cancelReason}>  {cancelReason}</Tooltip> </Typography>
            : null
        },
        offer_details: {
          primary: (
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
              <Typography
                variant="body2"
                sx={{
                  textDecoration: "line-through",
                  color: theme.palette.text.secondary,
                }}
              >
                ₹{price}
              </Typography>
              <Typography variant="h6" fontWeight="bold" color="text.primary">
                {finalPrice ? `₹${finalPrice}` : "N/A"}
              </Typography>
            </Stack>
          ),
          secondary: (
            <Box sx={{ display: "flex", alignItems: "center", }}>
              <Discount fontSize="small" color="info" />
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mt: 0.3, ml: 1, display: "inline-block" }}
              >
                {discount?.type === "fixed"
                  ? `₹${discount?.value} OFF`
                  : `${discount?.value}% OFF`}
              </Typography>
            </Box>
          )
        },
        actions: {
          primary: isPanding
            ? (
              <Button
                variant="contained"
                size="small"
                color="primary"
                disabled={isSubmiting.key === "confirm"}
                onClick={handleConfirmPayment}
              >
                {isSubmiting.key === "confirm" ? <CircularProgress size={16} /> : "Confirm Payment"}
              </Button>
            )
            : null,
          secondary: isCanceled
            ? null
            : (
              <Button
                variant="text"
                size="small"
                color="error"
                disabled={isSubmiting.key === "cancel"}
                onClick={handleCancelSubscription}
              >
                {isSubmiting.key === "cancel" ? <CircularProgress size={16} /> : "Cancel"}
              </Button>
            )
        }
      }}
    />
  );
};

export default SubscriptionRow;
