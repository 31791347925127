import React, { useState } from "react";
import { Button } from "@mui/material";
import PageContainer from "../../components/Containers/PageContainer";
import { useNavigate } from "react-router-dom";
import { offersApi } from "../../services/api";
import Swal from "sweetalert2";
import { Add } from "@mui/icons-material";
import QueryListContainer from "../../components/Containers/QueryContainer/Main";
import OfferRow from "../../components/Offers/OfferRow";
import { hasPermission } from "../../middleware/permission";
import { PERMISSION_CODE } from "../../config/permissionCodes";
import { PlanUpgrade } from "../../components/Icons/PlanUpgrade";
import { hasFeature, showPlanUpgrade } from "../../middleware/subscription";
import reduxData from "../../utils/useReduxData";

const OffersList = () => {
  const navigate = useNavigate();
  const [hasFeaturePermission, setHasFeaturePermission] = useState(false);

  const hasAddPermission = hasPermission(PERMISSION_CODE.OFFER.CREATE);

  const sortOptions = [
    { key: "createdAt", label: "Created At" },
    { key: "finalPrice", label: "Price" },
    { key: "activeSubscribers", label: "Subscribers" },
    { key: "durationInDays", label: "Duration" },
    { key: "discountInFixedAmount", label: "Discount" },
  ];
  const filters = {
    status: [
      { value: "", label: "All" },
      { value: "active", label: "Active" },
      { value: "inactive", label: "In Active" },
    ]
  };

  const handleView = (offer) => (event) => {
    event.stopPropagation();
    navigate(`/offers/subscribers`, { state: { offerDetails: offer } });
  }

  const handleAdd = (event) => {
    if (!hasFeaturePermission) return showPlanUpgrade();
    navigate(`/offers/add`);
  };

  const handleEdit = (id) => (event) => {
    event.stopPropagation();
  };

  const handleDelete = (id, refetch) => async (event) => {
    event.stopPropagation();
    event.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await offersApi().deleteOffer(id);
          refetch && refetch();
        } catch (error) {
        }
      }
    });
  };

  const addStaffButton = (
    <Button
      startIcon={<Add />}
      endIcon={!hasFeaturePermission ? <PlanUpgrade /> : null}
      variant="outlined"
      size="small"
      onClick={handleAdd}
      disabled={!hasAddPermission}
    >
      Offer
    </Button>
  );

  const header = [
    { size: 2, align: "start", key: "duration", label: "Duration" },
    { size: 2, align: "center", key: "status", label: "Status" },
    { size: 2, align: "center", key: "discount", label: "Discount" },
    { size: 2, align: "center", key: "price", label: "Price" },
    { size: 2, align: "center", key: "subscribers", label: "Subscribers" },
    { size: 2, align: "end", key: "actions", label: "Actions" }
  ];

  return (
    <PageContainer headerContent={addStaffButton}>
      <QueryListContainer
        fetchData={offersApi().getAll}
        filters={filters}
        sortOptions={sortOptions}
        header={header}
        getItems={(offers, refetch) => {
          reduxData("assetsCount", "updateObj")({ offers: offers.length });
          setHasFeaturePermission(hasFeature("offers"));

          return offers.map((offer) => (
            <OfferRow
              key={offer.id}
              offer={offer}
              header={header}
              onView={handleView(offer)}
              onEdit={handleEdit(offer._id)}
              onDelete={handleDelete(offer._id, refetch)}
            />
          ))
        }}
        noDataFound={{
          title: 'No Offers Found',
          subTitle: hasAddPermission ? "Click on the button below to add a new offer." : "Please refresh the page",
          buttonText: hasAddPermission ? "Add Offer" : "",
          ButtonIcon: hasAddPermission ? (hasFeaturePermission ? Add : PlanUpgrade) : "",
          onRefresh: hasAddPermission ? handleAdd : "",
        }}
      />
    </PageContainer>
  );
};

export default OffersList;
