const constents = () => {
  const headerHight = 50;
  const sidebarWidth = 250;

  return {
    headerWidth: "100vh",
    headerHight,

    sidebarWidth,
    sidebarHeight: `calc(100vh - ${headerHight}px)`,

    pageContainerWidht: `calc(100vh - ${sidebarWidth}px)`,
    pageContainerHeight: `calc(100vh - ${headerHight}px)`,
  }
}

export default constents