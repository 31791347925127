import React, { useState } from "react";
import { Typography, TextField, FormControlLabel, Checkbox, Grid2 } from "@mui/material";
import CollapsableSection from "../Library/SectionContainer";

const DayTiming = ({ day, status, timing, onToggle, onTimeChange, isDisabled }) => (
  <Grid2 container spacing={1} alignItems="center">
    <Grid2 size={{ xs: 4, md: 2 }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={status}
            onChange={() => onToggle(day)}
            disabled={isDisabled}
          />
        }
        label={day}
      />

    </Grid2>
    <Grid2 size={{ xs: 4, md: 5 }}>
      <TextField
        type="time"
        size="small"
        value={timing?.openTime}
        onChange={(e) => onTimeChange(day, "openTime", e.target.value)}
        fullWidth
        disabled={!status}
      />

    </Grid2>
    <Grid2 size={{ xs: 4, md: 5 }}>
      <TextField
        type="time"
        size="small"
        value={timing?.closeTime}
        onChange={(e) => onTimeChange(day, "closeTime", e.target.value)}
        fullWidth
        disabled={!status}
      />

    </Grid2>
  </Grid2>

);

const TimeSelection = ({ daysOfWeek, onTimingChange }) => {
  const [timeData, setTimeData] = useState(daysOfWeek);
  const [allSelected, setAllSelected] = useState(true);

  // Handle toggle for "All" checkbox
  const handleAllToggle = () => {
    const newAllSelected = !allSelected;
    setAllSelected(newAllSelected);
    const updatedData = timeData.map((day) => ({
      ...day,
      status: newAllSelected,
      ...(newAllSelected && {
        openTime: timeData[0]?.openTime,
        closeTime: timeData[0]?.closeTime,
      }),
    }));
    setTimeData(updatedData);
    onTimingChange(updatedData);
  };

  // Handle toggle for individual day
  const handleDayToggle = (day) => {
    const updatedData = timeData.map((d) =>
      d.day === day ? { ...d, status: !d.status } : d
    );
    setTimeData(updatedData);
    onTimingChange(updatedData);
    setAllSelected(updatedData.every((d) => d.status));
  };

  // Handle time change for individual day
  const handleTimeChange = (day, field, value) => {
    const updatedData = timeData.map((d) =>
      d.day === day ? { ...d, [field]: value } : d
    );
    setTimeData(updatedData);
    onTimingChange(updatedData);

    // Check if all timings match
    const isAllSame = updatedData.every(
      (d) =>
        d.openTime === updatedData[0]?.openTime &&
        d.closeTime === updatedData[0]?.closeTime
    );
    setAllSelected(isAllSame);
  };

  return (
    <CollapsableSection title="Opening / Closing Times">
      <Grid2 container spacing={1}>
        {/* Days Header */}
        <Grid2 size={{ xs: 4, md: 2 }}>
          <Typography variant="subtitle1" color="textDisabled">Days</Typography>
        </Grid2>
        <Grid2 size={{ xs: 4, md: 5 }}>
          <Typography variant="subtitle1" color="textDisabled">Opening Time</Typography>
        </Grid2>
        <Grid2 size={{ xs: 4, md: 5 }}>
          <Typography variant="subtitle1" color="textDisabled">Closing Time</Typography>
        </Grid2>
      </Grid2>
      <DayTiming
        day="All"
        status={allSelected}
        timing={timeData[0]}
        onToggle={handleAllToggle}
        onTimeChange={(day, field, value) => {
          const updatedData = timeData.map((d) => ({
            ...d,
            [field]: value,
          }));
          setTimeData(updatedData);
          onTimingChange(updatedData);
          setAllSelected(true);
        }}
        isDisabled={false}
      />

      {/* Individual Day Inputs */}
      {timeData.map((timeObj) => (
        <DayTiming
          key={timeObj.day}
          day={timeObj.day}
          status={timeObj.status}
          timing={timeObj}
          onToggle={handleDayToggle}
          onTimeChange={handleTimeChange}
          isDisabled={false}
        />
      ))}
    </CollapsableSection >
  );
};

export default TimeSelection;
