import { configureStore, combineReducers } from '@reduxjs/toolkit';
import myCustomSlice from "./slices";
import config from "../config/config";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // or the storage of your choice

const slicesArray = config.slices
const reducersObj = {};

slicesArray.forEach(({ name, initialValue }) => {
    reducersObj[name] = myCustomSlice(name, initialValue).reducer;
});

const rootReducer = combineReducers(reducersObj);

const persistConfig = {
    key: 'root', // the key for storing the data in storage
    storage, // the storage engine to use (e.g., localStorage)
};


// Wrap the root reducer with the persist reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = configureStore({ reducer: rootReducer });
export const store = configureStore({ reducer: persistedReducer });
export const persistor = persistStore(store);
