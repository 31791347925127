import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Locations from "./pages/Locations";
import Floors from "./pages/Floors";
import Roles from "./pages/Staffs/Roles";
import RoleDetail from "./pages/Staffs/RoleDetail";
import ViewMember from "./pages/Staffs/ViewMember";
import AddMember from "./pages/Staffs/AddMember";
import EditMember from "./pages/Staffs/EditMember";
import Students from "./pages/Students/List";
import StudentBookings from "./pages/Students/Bookings";
// import StudentRating from "./pages/Students/Rating";
import StudentAdd from "./pages/Students/Add";
import StudentEdit from "./pages/Students/Edit";
import Notifications from "./pages/Notifications";
import Settings from "./pages/Settings";
import Pricing from "./pages/Pricing";
import ConfirmPayment from "./pages/ConfirmPayment";
import Login from "./pages/Login";
import OtpVerification from "./pages/OtpVerification";
import NotFound from "./pages/NotFound";
import AddLocation from "./pages/AddLocation";
import FloorById from "./pages/FloorById";
import OffersListPage from './pages/Offers/list'
import AddOffer from './pages/Offers/AddOffer'
import OfferSubscribers from './pages/Offers/Subscribers'
import AddSubscriber from './pages/Offers/AddSubscriber'
import Attendance from './pages/Attendance/List'
import AddAttendance from "./pages/Attendance/Add";
import Reservations from './pages/Reservations'
import { PERMISSION_CODE } from "./config/permissionCodes";

import { checkAuthentication } from "./middleware/auth";
import LocationReviews from "./pages/LocationReviews";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" Component={checkAuthentication(Login, false)} />
        <Route path="/login" Component={checkAuthentication(Login, false)} />
        <Route path="/otp-verification" Component={checkAuthentication(OtpVerification, false)} />

        <Route path="/dashboard" Component={checkAuthentication(Dashboard)} />
        <Route path="/locations" Component={checkAuthentication(Locations, true, "", PERMISSION_CODE.LOCATION.VIEW)} />
        <Route path="/locations/add" Component={checkAuthentication(AddLocation, true, "locations", PERMISSION_CODE.LOCATION.CREATE)} />
        <Route path="/locations/reviews" Component={checkAuthentication(LocationReviews)} />
        <Route path="/locations/:locationId" Component={checkAuthentication(AddLocation, true, "", PERMISSION_CODE.LOCATION.UPDATE)} />
        <Route path="/floors" Component={checkAuthentication(Floors, true, "", PERMISSION_CODE.FLOOR.VIEW)} />
        <Route path="/floors/:floorId" Component={checkAuthentication(FloorById, true, "", PERMISSION_CODE.FLOOR.MANAGE_SEATS)} />

        {/* Students Routes */}
        <Route path="/students" Component={checkAuthentication(Students, true, "", PERMISSION_CODE.STUDENT.VIEW)} />
        <Route path="/students/add" Component={checkAuthentication(StudentAdd, true, "addStudent", PERMISSION_CODE.STUDENT.CREATE)} />
        <Route path="/students/edit" Component={checkAuthentication(StudentEdit, true, "", PERMISSION_CODE.STUDENT.UPDATE)} />
        <Route path="/students/bookings" Component={checkAuthentication(StudentBookings, true, "", PERMISSION_CODE.STUDENT.VIEW_ATTENDANCE)} />
        {/* <Route path="/students/rating" Component={checkAuthentication(StudentRating)} /> */}

        <Route path="/notifications" Component={checkAuthentication(Notifications)} />
        <Route path="/settings" Component={checkAuthentication(Settings)} />
        <Route path="/pricing" Component={checkAuthentication(Pricing, true, "", PERMISSION_CODE.PLAN.VIEW)} />
        <Route path="/pricing/confirm-payment" Component={checkAuthentication(ConfirmPayment, true, "", PERMISSION_CODE.PLAN.CHANGE)} />

        {/* Updated paths and components for Staff */}
        <Route path="/staffs" Component={checkAuthentication(Roles, true, "", PERMISSION_CODE.STAFF_ROLE.VIEW)} />
        <Route path="/staffs/members" Component={checkAuthentication(RoleDetail, true, "", PERMISSION_CODE.MEMBER.VIEW)} />
        <Route path="/staffs/members/details" Component={checkAuthentication(ViewMember, true, "", PERMISSION_CODE.MEMBER.VIEW)} />
        <Route path="/staffs/members/add" Component={checkAuthentication(AddMember, true, "members", PERMISSION_CODE.MEMBER.CREATE)} />
        <Route path="/staffs/members/edit" Component={checkAuthentication(EditMember, true, "", PERMISSION_CODE.MEMBER.UPDATE)} />

        {/* Updated paths and components for attendance */}
        <Route path="/attendance" Component={checkAuthentication(Attendance, true, "", PERMISSION_CODE.ATTENDANCE.VIEW)} />
        <Route path="/attendance/add" Component={checkAuthentication(AddAttendance, true, "addAttendance", PERMISSION_CODE.ATTENDANCE.ASSIGN_SEAT_OR_CHECK_IN)} />

        <Route path="/subscriptions" Component={checkAuthentication(Reservations, true, "", PERMISSION_CODE.SUBSCRIPTION.VIEW)} />

        <Route path="/offers" Component={checkAuthentication(OffersListPage, true, "", PERMISSION_CODE.OFFER.VIEW)} />
        <Route path="/offers/add" Component={checkAuthentication(AddOffer, true, "offers", PERMISSION_CODE.OFFER.CREATE)} />
        <Route path="/offers/subscribers" Component={checkAuthentication(OfferSubscribers, true, "", PERMISSION_CODE.OFFER.VIEW)} />
        <Route path="/offers/subscribers/add" Component={checkAuthentication(AddSubscriber, true, "", PERMISSION_CODE.OFFER.SUBSCRIBE)} />

        <Route path="*" Component={NotFound} />
      </Routes>
    </Router>
  );
};

export default React.memo(AppRoutes);
