import React from "react";
import MemberForm from "../../components/Staffs/MemberForm";
import PageContainer from "../../components/Containers/PageContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { membersApi } from "../../services/api";

const EditMember = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { role, member } = location.state;

  const handleSubmit = async (data) => {
    try {
      await membersApi().update(role._id, member._id, data);
      navigate(`/staffs/members`, { state: { role } });
    } catch (error) {
    }
  };

  return (
    <PageContainer>
      <MemberForm
        initialData={member}
        mode="edit"
        onSubmit={handleSubmit}
      />
    </PageContainer>
  );
};

export default EditMember;
