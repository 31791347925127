import React from "react";
import StudentForm from "../../components/Students/StudentForm";
import PageContainer from "../../components/Containers/PageContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { Button } from "@mui/material";

const EditStudent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { student } = location.state;

  const handleCancel = () => {
    navigate(-1);
  };

  const GoBack = () => (
    <Button
      startIcon={<ArrowBack />}
      variant="outlined"
      color="primary"
      size="small"
      onClick={handleCancel}
    >
      Back
    </Button>
  );

  return (
    <PageContainer headerContent={<GoBack />}>
      <StudentForm
        initial={student}
        mode="edit"
      />

    </PageContainer>
  );
};

export default EditStudent;
