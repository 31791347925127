import React from "react";
import { Chip, Box, IconButton } from "@mui/material";
import { Avatar, Typography, Tooltip } from "@mui/material";
import formattedContactNumber from "../../utils/formateContactNumber";
import { RowBuilder } from "../Containers/QueryContainer/RowBuilder";
import { FloorSeat } from "../Common/FloorSeat";
import { Delete, Edit } from "@mui/icons-material";
import { hasPermission } from "../../middleware/permission";
import { PERMISSION_CODE } from "../../config/permissionCodes";
import RatingStar from "../Common/RatingStar";
import { useNavigate } from "react-router-dom";

const StudentRow = ({ student, header, onView, onEdit, onDelete }) => {
  const navigate = useNavigate();
  const { name, email, contactNumber, isActive, rating, image, subscription } = student;

  const isReserved = subscription?.type === "fixed" && subscription?.status === "active";
  const hasSubscription = subscription?.status === "active";
  const type = subscription?.type;

  const floorName = subscription?.floorName;
  const seatNumber = subscription?.seatNumber;

  const hasEditPermission = hasPermission(PERMISSION_CODE.STUDENT.UPDATE);
  const hasDeletePermission = hasPermission(PERMISSION_CODE.STUDENT.DELETE);

  const floorSeatComponent = isReserved
    ? (
      <FloorSeat
        floorName={floorName}
        seatNumber={seatNumber}
      />
    )
    : null;

  const handleEdit = (student) => (e) => {
    e.stopPropagation(); // Prevent ListItem's onClick from firing
    onEdit(student);
  }

  const handleDelete = (student) => (e) => {
    e.stopPropagation(); // Prevent ListItem's onClick from firing
    onDelete(student);
  }

  const handleViewRatings = (student) => (e) => {
    navigate("/students/rating", { state: { student } });
  }

  return <RowBuilder
    header={header}
    columns={{
      image: {
        primary: <Avatar src={image || ""} alt={name} sx={{ width: 60, height: 60 }} />,
      },
      name: {
        primary: (
          <Tooltip title={email}>
            <Typography variant="body1" noWrap>
              {name}
            </Typography>
          </Tooltip>
        ),
        secondary: <Typography variant="body2" color="textSecondary" noWrap>{email}</Typography>,
      },
      contact: {
        primary: <Typography variant="body2" noWrap>{formattedContactNumber(contactNumber)}</Typography>,
      },
      rating: {
        primary: <RatingStar rating={rating} onClick={handleViewRatings(student)} />,
      },
      status: {
        primary: (
          <Chip
            label={isActive ? "Active" : "Inactive"}
            color={isActive ? "success" : "error"}
            size="small"
            sx={{ width: "90px", color: "white" }}
          />
        ),
      },
      subscription: {
        primary: (
          <Chip
            label={hasSubscription ? type : "Not Subscribed"}
            color={hasSubscription ? (type === "fixed" ? "primary" : "secondary") : "error"}
            size="small"
            sx={{ width: "150px", color: "white" }}
          />
        ),
        secondary: floorSeatComponent,
      },
      actions: {
        primary: (
          <Box sx={{ display: "flex" }}>
            <IconButton
              size="small"
              onClick={handleEdit(student)}
              disabled={!hasEditPermission}
              color="primary"
            >
              <Edit />
            </IconButton>
            <IconButton
              size="small"
              onClick={handleDelete(student)}
              disabled={!hasDeletePermission}
              color="error"
            >
              <Delete />
            </IconButton>
          </Box>
        ),
      },
    }}
  />
};

export default StudentRow;
