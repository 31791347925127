import React, { useState, useEffect, useCallback } from "react";
import { Grid2, Select, MenuItem, Button, IconButton, CircularProgress, Pagination, Tooltip } from "@mui/material";
import { Sort } from "@mui/icons-material";
import SearchWithDebounce from "./Search";
import CustomCard from "../Card";

const DataFetchingHOC = ({ defaultParams, apiRes, fetchQueryReq, children }) => {
  const defaultFilters = Object.keys(defaultParams?.filters || {}).reduce((acc, key) => {
    acc[key] = defaultParams.filters[key][0];
    return acc;
  }, {});

  // States
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState(defaultFilters || {});
  const [sortKey, setSortKey] = useState(defaultParams.sortKey || "createdAt");
  const [sortOrder, setSortOrder] = useState(defaultParams.sortOrder || "ASC");
  const [pagination, setPagination] = useState({ page: 1, limit: defaultParams.limit || 10 });
  const [loading, setLoading] = useState(false);
  const [totalResults, setTotalResults] = useState(0);

  // Update total results and items when API response changes
  useEffect(() => {
    if (apiRes) {
      const { totalResults } = apiRes;
      setTotalResults(totalResults);
    }
  }, [apiRes]);

  // Fetch data
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const params = {
        search: searchQuery,
        sortKey,
        sortOrder,
        page: pagination.page,
        limit: pagination.limit,
        ...filters,
      };

      await fetchQueryReq(params);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [searchQuery, sortKey, sortOrder, filters, pagination]);

  // Fetch data on dependency updates
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Handlers
  const handleSearch = (value) => {
    setSearchQuery(value);
    setPagination({ ...pagination, page: 1 });
  };

  const handleFilterChange = (filterKey, value) => {
    setFilters({ ...filters, [filterKey]: value });
    setPagination({ ...pagination, page: 1 });
  };

  const handleSortChange = (key) => {
    setSortKey(key);
  };

  const handleSortOrderToggle = () => {
    setSortOrder((prevOrder) => (prevOrder === "ASC" ? "DESC" : "ASC"));
  };

  const handleReset = () => {
    setSearchQuery("");
    setFilters(defaultFilters || {});
    setSortKey(defaultParams.sortKey || "createdAt");
    setSortOrder(defaultParams.sortOrder || "ASC");
    setPagination({ page: 1, limit: defaultParams.limit || 10 });
  };

  const handlePageChange = (event, newPage) => {
    setPagination({ ...pagination, page: newPage });
  };

  const handleLimitChange = (event) => {
    setPagination({ page: 1, limit: parseInt(event.target.value, 10) });
  };

  // Render
  return (
    <div>
      {/* Controls */}
      <CustomCard>
        <Grid2 container spacing={2} alignItems="center" justifyContent="space-between">
          {/* Search Box */}
          <Grid2 size={{ xs: 12, md: 2 }} minWidth={100}>
            <SearchWithDebounce searchQuery={searchQuery} onSearch={handleSearch} />
          </Grid2>

          {/* Filters */}
          {Object.keys(defaultParams.filters || {}).map((filterKey) => (
            <Grid2 size={{ xs: 12, md: 3 }} minWidth={100} key={filters[filterKey]}>
              <Select
                size="small"
                value={filters[filterKey] || ""}
                onChange={(e) => handleFilterChange(filterKey, e.target.value)}
                fullWidth
              >
                {(defaultParams.filters[filterKey] || []).filter(Boolean).map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Grid2>
          ))}

          {/* Reset Button */}
          <Grid2 size={{ xs: 4, md: 1 }} minWidth={80}>
            <Button variant="contained" onClick={handleReset} fullWidth>
              Reset
            </Button>
          </Grid2>

          {/* Sorting */}
          <Grid2 size={{ xs: 8, md: 3 }} minWidth={100} display="flex">
            <Select
              size="small"
              value={sortKey}
              onChange={(e) => handleSortChange(e.target.value)}
              fullWidth
              sx={{ maxWidth: "70%" }}
            >
              {defaultParams.sortOptions.map((option) => (
                <MenuItem key={option.key} value={option.key}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <Tooltip title={sortOrder === "ASC" ? "Ascending order" : "Descending Order"} >
              <IconButton onClick={handleSortOrderToggle}>
                <Sort
                  sx={{
                    transform: sortOrder === "ASC" ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "ease-in-out 0.5s",
                    color: "primary.main",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid2>
        </Grid2>
      </CustomCard>

      {/* Data Display */}
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {children}
          {/* Pagination Controls */}
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "16px" }}>
            {/* Limit Selector */}
            <Select size="small" value={pagination.limit} onChange={handleLimitChange}>
              {[5, 10, 20, 50].map((limit) => (
                <MenuItem key={limit} value={limit} selected={limit === pagination.limit}>
                  {limit} per page
                </MenuItem>
              ))}
            </Select>

            {/* Pagination Component */}
            <Pagination
              count={Math.ceil(totalResults / pagination.limit)}
              page={pagination.page}
              onChange={handlePageChange}
              color="primary"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DataFetchingHOC;
