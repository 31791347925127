import React from "react";
import { Grid2 } from "@mui/material";

export const RowBuilder = ({ header, columns, onClick = null }) => (
  <Grid2
    container
    onClick={onClick}
    sx={{
      borderBottom: "2px solid gray",
      alignItems: "center",
      padding: 2, py: 0.5,
      cursor: onClick ? "pointer" : "default",
      "&:hover": {
        backgroundColor: onClick ? "rgba(0, 0, 0, 0.04)" : "transparent",
      },
      "&:last-child": {
        borderBottom: "none",
      },
    }}>
    {header.map((col) => (
      <Grid2
        key={col.key}
        size={{ xs: col.size }}
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: col.align || "start",
          justifyContent: col.align || "flex-start",
          alignItems: col.align || "flex-start",
        }} >
        {columns[col.key]?.primary}
        {columns[col.key]?.secondary}
      </Grid2>
    ))}
  </Grid2>
);
