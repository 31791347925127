import React from "react";
import { Typography, Box, Chip, Stack, IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { RowBuilder } from "../Containers/QueryContainer/RowBuilder";
import { PERMISSION_CODE } from "../../config/permissionCodes";
import { hasPermission } from "../../middleware/permission";

const OfferRow = ({ offer, header, onView, onEdit, onDelete }) => {
  const hasEditPermission = hasPermission(PERMISSION_CODE.OFFER.UPDATE);
  const hasDeletePermission = hasPermission(PERMISSION_CODE.OFFER.CREATE);

  return (
    <RowBuilder
      header={header}
      onClick={onView}
      columns={{
        duration: {
          primary: <Typography variant="body2" noWrap>{offer?.duration?.value} {offer?.duration?.unit}</Typography>,
          secondary: null
        },
        status: {
          primary: (
            <Chip
              label={offer.isActive ? "Active" : "Inactive"}
              size="small"
              color={offer.isActive ? "success" : "error"}
              sx={{ color: "white", width: "50%", textTransform: "capitalize", fontWeight: "bold" }}
            />
          ),
          secondary: null
        },
        discount: {
          primary: <Typography variant="body2" noWrap>{offer?.discount?.value} {offer?.discount?.type}</Typography>,
          secondary: null
        },
        price: {
          primary: <Box sx={{ display: "flex", alignItems: "center", }}>
            <Typography sx={{ textDecoration: "line-through", color: "text.secondary", mr: 1 }}>₹{offer.price}</Typography>
            <Typography> ₹{offer.finalPrice || 0}</Typography>
          </Box>,
          secondary: null,
        },
        subscribers: {
          primary: <Typography variant="body2" sx={{ color: "primary.main", fontWeight: "bold" }} noWrap>{offer.activeSubscribers}</Typography>,
          secondary: null
        },
        actions: {
          primary: (
            <Stack direction="row" spacing={1}>
              <IconButton color="primary" onClick={onEdit} disabled={!hasEditPermission}>
                <Edit />
              </IconButton>
              <IconButton color="error" onClick={onDelete} disabled={!hasDeletePermission}>
                <Delete />
              </IconButton>
            </Stack>
          ),
          secondary: null,
        }
      }}
    />
  );
};

export default OfferRow;
