import React, { useState } from "react";
import { Button, Grid2 } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import LocationCard from "../components/Location/LocationCard";
import PageContainer from "../components/Containers/PageContainer";
import { locationsApi } from "../services/api";
import Swal from "sweetalert2";
import reduxData from "../utils/useReduxData";
import { useSelector } from "react-redux";
import { Add } from "@mui/icons-material";
import { checkLocation, getCompleteAddress } from "../utils/location";
import { hasPermission } from "../middleware/permission";
import { PERMISSION_CODE } from "../config/permissionCodes";
import { PlanUpgrade } from "../components/Icons/PlanUpgrade";
import { hasFeature, showPlanUpgrade } from "../middleware/subscription";

const Locations = () => {
  const navigate = useNavigate();
  const [expandedLocation, setExpandedLocation] = useState(null);
  const localStoredLocations = useSelector((state) => state.locations);
  const currentLocation = useSelector((state) => state.currentLocation);
  const library = useSelector((state) => state.library);

  const hasAddPermission = hasPermission(PERMISSION_CODE.LOCATION.CREATE);
  const hasFeaturePermission = hasFeature("locations");

  // React Query to fetch locations
  const { data: locations, refetch } = useQuery(
    ["locations"],
    async () => {
      const { results } = await locationsApi().getAll();
      reduxData("locations", "set")(results);
      reduxData("assetsCount", "updateObj")({ locations: results.length });

      return results;
    },
    {
      retry: false, // Disable retries on error
    }
  );

  const handleExpandToggle = (id) => () => {
    setExpandedLocation((prev) => (prev === id ? null : id));
  };

  const handleAddLocation = () => {
    if (!hasFeaturePermission) return showPlanUpgrade();

    navigate("/locations/add");
  };

  const AddLocationButton = () => (
    <Button
      startIcon={<Add />}
      endIcon={!hasFeaturePermission ? <PlanUpgrade /> : null}
      variant="outlined"
      color="primary"
      size="small"
      onClick={handleAddLocation}
      disabled={!hasAddPermission}
    >
      Location
    </Button>
  );

  const handleDelete = (id) => () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You Want to Delete the location!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await locationsApi().deleteLocation(id);
        await refetch();
      }
    });
  };

  const handleEdit = (id) => () => {
    navigate(`/locations/${id}`);
  };

  return (
    <PageContainer headerContent={<AddLocationButton />}>
      <Grid2 container spacing={2}>
        {(locations || localStoredLocations).map((location) => (
          <Grid2 key={location._id} size={{ xs: 12 }}>
            <LocationCard
              {...location}
              address={getCompleteAddress(location)}
              expanded={expandedLocation === location._id}
              toggleExpand={handleExpandToggle(location._id)}
              onDelete={handleDelete(location._id)}
              onEdit={handleEdit(location._id)}
              canDelete={currentLocation?._id !== location._id}
              isLocationValid={checkLocation(location).isLocationValid}
              libraryName={library.name}
              libraryId={library._id}
            />
          </Grid2>
        ))}
      </Grid2>
    </PageContainer>
  );
};

export default Locations;
