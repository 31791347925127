import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Box, Typography, Button } from "@mui/material";
import { Add } from "@mui/icons-material";

import StaffMembersList from "../../components/Staffs/StaffMember";
import PermissionsDialog from "../../components/Staffs/PermissionsDrawer";
import PageContainer from "../../components/Containers/PageContainer";
import { membersApi, staffsApi } from "../../services/api";
import Swal from "sweetalert2";
import QueryListContainer from "../../components/Containers/QueryContainer/Main";
import { useSelector } from "react-redux";
import { hasPermission } from "../../middleware/permission";
import { PERMISSION_CODE } from "../../config/permissionCodes";
import { hasFeature, showPlanUpgrade } from "../../middleware/subscription";
import { PlanUpgrade } from "../../components/Icons/PlanUpgrade";
import reduxData from "../../utils/useReduxData";

const StaffDetailsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { role } = location.state;
  const roleId = role?._id;

  const hasAddPermission = hasPermission(PERMISSION_CODE.MEMBER.CREATE);
  const hasFeaturePermission = hasFeature("members");

  const [openPermissionsDialog, setOpenPermissionsDialog] = useState(false);
  const [tempPermissions, setTempPermissions] = useState(role?.permissions || []);
  const [needToSave, setNeedToSave] = useState(false);

  const locations = useSelector(state => state.locations);
  const permissions = useSelector(state => state.permissions);
  const assetsCount = useSelector(state => state.assetsCount);

  useEffect(() => {
    const savedPermissionsCodes = role?.permissions || [];
    setNeedToSave(tempPermissions.some(p => !savedPermissionsCodes.includes(p)) || savedPermissionsCodes.some(p => !tempPermissions.includes(p)));
  }, [tempPermissions, role]);

  const togglePermissionsDialog = () => setOpenPermissionsDialog(!openPermissionsDialog);

  // Handle Editing Staff Permissions
  const handleViewPermissions = () => {
    togglePermissionsDialog();
  };

  // Toggle Permissions in Dialog
  const handlePermissionChange = (permission) => {
    setTempPermissions((prev) =>
      prev.includes(permission)
        ? prev.filter((p) => p !== permission)
        : [...prev, permission]
    );
  };

  // Save Updated Staff Permissions
  const handleSavePermissions = async () => {
    try {
      await staffsApi().update(roleId, { permissions: tempPermissions });
      role.permissions = tempPermissions;
      togglePermissionsDialog();
      setNeedToSave(false);
    } catch (error) {
    }
  };

  // Member Actions
  const handleViewMember = (member) => {
    navigate(`/staffs/members/details`, { state: { member, role } });
  };

  // Add Staff Member Button
  const handleAddMember = () => {
    if (!hasFeaturePermission) return showPlanUpgrade();

    navigate(`/staffs/members/add`, { state: { role } });
  };

  const handleEditMember = (member) => {
    navigate(`/staffs/members/edit`, { state: { member, role } });
  };

  const handleDeleteMember = (refetch) => (member) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this action.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await membersApi().deleteMember(roleId, member._id)
          refetch();
          reduxData("assetsCount", "updateObj")({ members: assetsCount.members - 1 });
        } catch (error) {
        }
      }
    });
  };

  const getLocationName = (member) => {
    const location = locations.find(location => location._id === member.locationId) || {};
    return location.name;
  }

  const addStaffMemberButton = (
    <Button
      variant="outlined"
      startIcon={<Add />}
      endIcon={!hasFeaturePermission ? <PlanUpgrade /> : null}
      onClick={handleAddMember}
      size="small"
      disabled={!hasAddPermission}
    >
      Member
    </Button>
  );

  const sortOptions = [
    { key: "name", label: "Name" },
    { key: "email", label: "Email" },
  ];

  const filters = {
    status: [
      { value: "", label: "All" },
      { value: "active", label: "Active" },
      { value: "inactive", label: "In Active" },
    ],
    location: [
      { value: "", label: "All" },
      ...locations.map((location) => ({
        value: location._id,
        label: location.name,
      }))
    ]
  };

  const header = [
    { size: 1, align: "start", key: "image", label: "Image" },
    { size: 3, align: "start", key: "name", label: "Name" },
    { size: 2, align: "center", key: "contact", label: "Contact" },
    { size: 2, align: "center", key: "status", label: "Status" },
    { size: 3, align: "center", key: "location", label: "Location" },
    { size: 1, align: "end", key: "actions", label: "Actions" },
  ];

  return (
    <PageContainer headerContent={addStaffMemberButton}>
      {/* Staff Header */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
          paddingBottom: 2,
        }}
      >
        {/* Editable Staff Name */}
        <Typography variant="h3">{role.name}</Typography>

        <Button
          variant="text"
          // startIcon={<Visibility />}
          onClick={handleViewPermissions}
          size="small"
          sx={{
            textTransform: "none",
          }}
        >
          View Permissions
        </Button>
      </Box>

      {/* Members List */}
      <QueryListContainer
        fetchData={membersApi().getAll(roleId)}
        filters={filters}
        sortOptions={sortOptions}
        header={header}
        getItems={(members, refetch) => members?.map((member) => (
          <StaffMembersList
            member={member}
            header={header}
            locationName={getLocationName(member)}
            onView={handleViewMember}
            onEdit={handleEditMember}
            onDelete={handleDeleteMember(refetch)}
          />
        ))}
        noDataFound={{
          title: "No Members Found",
          subTitle: hasAddPermission ? "Click on the button below to add a new member." : "Please Refresh the page",
          buttonText: hasAddPermission ? "Add Member" : "",
          ButtonIcon: hasAddPermission ? Add : "",
          onRefresh: hasAddPermission ? handleAddMember : "",
        }}
      />

      {/* Permissions Dialog */}
      <PermissionsDialog
        open={openPermissionsDialog}
        permissionsList={permissions}
        selectedPermissions={tempPermissions}
        onClose={() => togglePermissionsDialog()}
        onPermissionChange={handlePermissionChange}
        onSave={handleSavePermissions}
        needToSave={needToSave}
      />
    </PageContainer>
  );
};

export default StaffDetailsPage;
