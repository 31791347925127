import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, Button, Typography, Card, IconButton, Stack, List, ListItem, ListItemText, useTheme } from "@mui/material";
import { WarningAmber, Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import reduxData from "../../utils/useReduxData";

const ExtraAssets = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const extraAssets = useSelector((state) => state.extraAssets);

  useEffect(() => {
    if (extraAssets && Object.values(extraAssets).some((value) => value > 0 || (Array.isArray(value) && value.length))) {
      setIsModalOpen(true);
    }
  }, [extraAssets]);

  const onClose = () => {
    setIsModalOpen(false);
    reduxData("extraAssets", "set")({});
  };

  const getRedirectPath = (key) => {
    onClose();

    const paths = {
      roles: "staffs",
      members: "staffs",
      locations: "locations",
      floors: "floors",
      offers: "offers",
    };
    return `/${paths[key] || key}`;
  };

  return (
    <Dialog open={isModalOpen} maxWidth="sm" fullWidth>
      {/* Header */}
      <DialogTitle sx={{ textAlign: "center", position: "relative", color: theme.palette.warning.light }}>
        <WarningAmber sx={{ fontSize: 50, color: theme.palette.warning.main, mb: 1 }} />
        <Typography variant="h5" fontWeight="bold">
          Unable to Downgrade Plan
        </Typography>

        {/* Close Button (Top-Right Corner) */}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: theme.palette.grey[800],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      {/* Content */}
      <DialogContent sx={{ textAlign: "center", padding: 3 }}>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
          Your current plan has more assets than the selected downgrade plan allows.
          Please remove the extra assets before proceeding.
        </Typography>

        <Stack spacing={2}>
          {/* Locations Assets (Show only if extra floors or offers exist) */}
          {extraAssets.locationsAssets?.length > 0 &&
            extraAssets.locationsAssets.some(({ floors, offers }) => floors > 0 || offers > 0) && (
              <Card variant="outlined" sx={{ px: 2, py: 1, textAlign: "left" }}>
                <Typography variant="body2" color="error" fontWeight="bold" sx={{ mb: 1 }}>
                  Extra Assets in Locations:
                </Typography>
                <List dense>
                  {extraAssets.locationsAssets.map(({ name, floors, offers }) =>
                    floors > 0 || offers > 0 ? (
                      <ListItem key={name} sx={{ display: "flex", justifyContent: "space-between" }}>
                        <ListItemText primary={name} secondary={`${floors > 0 ? `Floors: ${floors}` : ""} ${offers > 0 ? `Offers: ${offers}` : ""}`} />
                        <Button
                          variant="text"
                          size="small"
                          sx={{ textDecoration: "underline", fontWeight: "bold", color: theme.palette.error.main }}
                          onClick={() => navigate(getRedirectPath(floors > 0 ? "floors" : "offers"))}
                        >
                          Manage
                        </Button>
                      </ListItem>
                    ) : null
                  )}
                </List>
              </Card>
            )}

          {/* Other Assets (Roles, Members, Locations) */}
          {["roles", "members", "locations"].map((key) => {
            if (extraAssets[key] > 0) {
              return (
                <Card
                  key={key}
                  variant="outlined"
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="body2" color="error" fontWeight="bold">
                    {extraAssets[key]} Extra {key}
                  </Typography>
                  <Button
                    variant="text"
                    size="small"
                    sx={{ textDecoration: "underline", fontWeight: "bold", color: theme.palette.error.main }}
                    onClick={() => navigate(getRedirectPath(key))}
                  >
                    Manage
                  </Button>
                </Card>
              );
            }
            return null;
          })}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ExtraAssets;
