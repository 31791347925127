import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";

import ToolBar from "../components/Floor/Toolbar";
import SeatArrangementPanel from "../components/Floor/SeatArrangementPanel";
import PageContainer from "../components/Containers/PageContainer";
import { floorsApi } from "../services/api";
import { Button } from "@mui/material";
import Swal from "sweetalert2";

const initialRows = 6;
const initialCols = 17;
const initialItems = [];

const FloorById = () => {
  const { floorId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { mode } = location.state;

  const [rows, setRows] = useState(initialRows);
  const [cols, setCols] = useState(initialCols);
  const [items, setItems] = useState(initialItems);
  const [history, setHistory] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const { isLoading, refetch } = useQuery(
    ['floors-details', floorId],
    async () => await floorsApi().getFloorSeats(floorId),
    {
      retry: false, // Disable retries on error
      enabled: !!floorId,
      select: (data) => {
        // Calculate rows and columns in a temporary variable
        let maxRows = rows;
        let maxCols = cols;

        const seats = data.seats.map((item) => {
          const row = item.position.row;
          const col = item.position.col;

          maxRows = Math.max(maxRows, row);
          maxCols = Math.max(maxCols, col);

          return {
            id: item._id,
            status: item.status,
            seatNumber: item.number,
            row: row,
            col: col,
            rotation: item.position.rotation,
          };
        });

        // Return the transformed seats and calculated maxRows and maxCols
        return { seats, maxRows, maxCols };
      },
      onSuccess: (data) => {
        // Update state once here
        setItems(data.seats);
        setRows(data.maxRows + 1);
        setCols(data.maxCols + 1);
      },
    }
  );

  const getNextSeatNumber = () => {
    const usedNumbers = items.map((item) => item.seatNumber);
    let nextNumber = 1;
    while (usedNumbers.includes(nextNumber)) {
      nextNumber++;
    }
    return nextNumber;
  };

  const addHistory = () => {
    setHistory((prevHistory) => [...prevHistory, { rows, cols, items }]);
    setRedoStack([]); // Clear redo stack after a new action
  };

  // Undo functionality
  const undo = () => {
    if (history.length > 0) {
      const previousState = history[history.length - 1];
      setRedoStack((prevRedoStack) => [{ rows, cols, items }, ...prevRedoStack]);
      setHistory((prevHistory) => prevHistory.slice(0, -1));
      setRows(previousState.rows);
      setCols(previousState.cols);
      setItems(previousState.items);
    }
  };

  // Redo functionality
  const redo = () => {
    if (redoStack.length > 0) {
      const nextState = redoStack[0];
      setHistory((prevHistory) => [...prevHistory, { rows, cols, items }]);
      setRedoStack((prevRedoStack) => prevRedoStack.slice(1));
      setRows(nextState.rows);
      setCols(nextState.cols);
      setItems(nextState.items);
    }
  };

  const handleCellClick = (row, col) => {
    const itemIndex = items.findIndex((item) => item.row === row && item.col === col);
    if (itemIndex !== -1) {
      // Seat already placed, handle click for rotation or deletion
      handleSeatClick(itemIndex);
    } else {
      // If cell is empty, add a new seat
      const nextSeatNumber = getNextSeatNumber();

      addHistory();
      setItems([
        ...items,
        { id: `seat-${nextSeatNumber}`, row, col, rotation: 0, status: "available", seatNumber: nextSeatNumber },
      ]);
    }
  };

  const handleSeatClick = (itemIndex) => {
    const updatedItems = [...items];
    const item = updatedItems[itemIndex];
    const clickCount = (item.rotation / 90) + 1;

    if (clickCount < 4) {
      // First Four clicks: rotate the seat
      updatedItems[itemIndex] = { ...item, rotation: (item.rotation + 90) % 360 };
    } else if (clickCount === 4) {
      // Second click: delete the seat
      updatedItems.splice(itemIndex, 1);
    }

    addHistory();
    setItems(updatedItems);
  };

  const handleDropItem = (event, row, col) => {
    event.preventDefault();
    const seatId = event.dataTransfer.getData("seatId");
    const currentItemIndex = items.findIndex((item) => item.id === seatId);

    const isItemAlreadyExists = items.some((item) => item.row === row && item.col === col);

    if (currentItemIndex !== -1 && !isItemAlreadyExists) {
      const updatedItems = [...items];
      updatedItems[currentItemIndex] = { ...updatedItems[currentItemIndex], row, col };
      addHistory();
      setItems(updatedItems);
    }
  };

  // Adds a row above or below the selected row
  const onAddRow = (rowIndex, position) => {
    addHistory(); // Call to track history (if needed)
    setRows((prevRows) => prevRows + 1);

    setItems((prevItems) => {
      const updatedItems = prevItems.map((item) => {
        // Shift rows below the selected row if adding below
        if (position === "below" && item.row > rowIndex) {
          return { ...item, row: item.row + 1 };
        }
        // Shift rows above the selected row if adding above
        if (position === "above" && item.row >= rowIndex) {
          return { ...item, row: item.row + 1 };
        }
        return item;
      });

      return updatedItems;
    });
  };

  // Deletes the selected row
  const onDeleteRow = (rowIndex) => {
    addHistory(); // Call to track history (if needed)
    setRows((prevRows) => prevRows - 1);

    setItems((prevItems) =>
      prevItems.filter((item) => item.row !== rowIndex).map((item) => {
        // Shift rows above the deleted row
        if (item.row > rowIndex) {
          return { ...item, row: item.row - 1 };
        }
        return item;
      })
    );
  };

  // Adds a column to the left or right of the selected column
  const onAddCol = (colIndex, position) => {
    addHistory(); // Call to track history (if needed)
    setCols((prevCols) => prevCols + 1);

    setItems((prevItems) => {
      const updatedItems = prevItems.map((item) => {
        // Shift columns to the right if adding to the right
        if (position === "right" && item.col > colIndex) {
          return { ...item, col: item.col + 1 };
        }
        // Shift columns to the right if adding to the left
        if (position === "left" && item.col >= colIndex) {
          return { ...item, col: item.col + 1 };
        }
        return item;
      });

      return updatedItems;
    });
  };

  // Deletes the selected column
  const onDeleteCol = (colIndex) => {
    addHistory(); // Call to track history (if needed)
    setCols((prevCols) => prevCols - 1);

    setItems((prevItems) =>
      prevItems.filter((item) => item.col !== colIndex).map((item) => {
        // Shift columns to the left of the deleted column
        if (item.col > colIndex) {
          return { ...item, col: item.col - 1 };
        }
        return item;
      })
    );
  };

  const clearAll = () => {
    if (items.length) {
      closeDrawer();
      addHistory();
      setItems([]);
    }
  }

  const saveChanges = async () => {
    setIsSubmitting(true);
    closeDrawer();
    const allSeats = [];

    items.forEach((item) => {
      const isAnySeatNumberExists = allSeats.some(seat => seat.seatNumber === item.seatNumber);

      isAnySeatNumberExists || allSeats.push(item);
    });
    try {
      await floorsApi().updateFloorSeats(floorId, allSeats);
      setTimeout(() => {
        navigate("/floors");
      }, 300);
    } catch (error) {
      setDrawerOpen(false);
      setIsSubmitting(false);
      await refetch();
    }
  }

  const discardChanges = () => {
    closeDrawer();

    if (!history.length) {
      navigate("/floors");
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "This will discard all unsaved changes!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, discard it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/floors");
      }
    });
  };

  const handleUpdateSeatStatus = async (seatId, newStatus) => {
    try {
      await floorsApi().updateSeatStatus(floorId, seatId, newStatus);
      await refetch();
    } catch (error) {
    }
  }

  const toolbar = (
    <ToolBar
      undo={history.length > 0 ? undo : null}
      redo={redoStack.length > 0 ? redo : null}
      clear={clearAll}
      save={history.length ? saveChanges : null}
      drawerOpen={drawerOpen}
      toggleDrawer={toggleDrawer}
      discardChanges={discardChanges}
      isSubmitting={isSubmitting}
    />
  );

  const editButton = (
    <Button onClick={() => navigate(`/floors/${floorId}`, { state: { mode: "edit" } })}>
      Edit
    </Button>
  );

  return (
    <PageContainer headerContent={mode === "edit" ? toolbar : editButton}>
      <SeatArrangementPanel
        rows={rows}
        cols={cols}
        items={items}
        onDropItem={handleDropItem}
        onCellClick={handleCellClick}
        onAddCol={onAddCol}
        onAddRow={onAddRow}
        onDeleteCol={onDeleteCol}
        onDeleteRow={onDeleteRow}
        mode={mode}
        isLoading={isLoading}
        onUpdateStatus={handleUpdateSeatStatus}
      />
    </PageContainer>
  );
};

export default FloorById;
