import axios from "axios";
import config from "../../config/config";
import { checkTokensValidation } from '../../utils/checkTokensValidation';
import { extractDataFromResponse, parseApiErrorResponse } from "./api_utils";
import apisEndPoints from "./apis_routes";
import { getDeviceInfo } from '../../utils/getDeviceInfo';
import reduxData from "../../utils/useReduxData";
import { clearStore } from "../../utils/clearStore";
import { store } from "../../redux/store";

const device = getDeviceInfo();
let apiCallsCount = 0;

const defaultHeaders = {
	Accept: "application/json, text/plain, */*", // Corrected wildcard
	"Content-Type": "application/json",
	"Access-Control-Allow-Origin": "*", // Consider specifying allowed domains for security
	"Access-Control-Allow-Credentials": true,
	"Access-Control-Allow-Methods": "GET, POST, PUT, PATCH, DELETE",
	"Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
	"X-Requested-With": "*",
	"environment": "local",
	"device-type": "web",
	"device-name": device.deviceName,
	"device-id": device.deviceId,
	"device-token": device.deviceId,
	"os-version": device.appVersion,
	"device-platform": device.devicePlatform,
};

/**
 * Calls the API and handles the response and errors.
 * @param {Object} options - Options for the API call.
 * @returns {Promise<Object>} - Result of the API call or error.
 */
const callApi = async ({ method, url, data, successCode = 200, successStatus = true, showSuccessToast = false, showErrorToast = false, isAuthApi = false }) => {
	const { access, refresh } = checkTokensValidation();
	const device = store.getState().device;
	const currentLocation = store.getState().currentLocation;

	// Prevent API call if tokens are not valid and it's not an auth API call
	if (!refresh && !isAuthApi) {
		return;
	}

	const headers = { ...defaultHeaders };
	if (!isAuthApi) {
		headers['Authorization'] = `Bearer ${access}`;
	}

	if (currentLocation._id) {
		headers["location_id"] = currentLocation._id;
	}

	if (device._id) {
		headers['device_id'] = device._id;
	}

	try {
		const response = await axios({ baseURL: config.base_url, timeout: 10000, headers, data, url, method });
		return extractDataFromResponse({ response, showErrorToast, showSuccessToast, successCode, successStatus });
	} catch (error) {
		const errorResponse = parseApiErrorResponse({ error, showToast: showErrorToast });

		if (errorResponse?.statusCode === 401 && apiCallsCount < 2) {
			apiCallsCount += 1;
			// If unauthorized, attempt to refresh tokens
			return await handleRefreshTokenApi({ method, url, data, isAuthApi, showErrorToast, showSuccessToast, successCode, successStatus }, refresh);
		} else if (errorResponse?.statusCode === 422) {
			reduxData("showPermissionDenied", "set")(true);
		} else if (errorResponse?.statusCode === 403) {
			reduxData("showPlanUpgrade", "set")(true);
		}
		return Promise.reject(errorResponse);
	}
};

/**
 * Handles refreshing the token API call.
 * @param {Object} apiOptions - Options for the API call.
 * @param {string} refreshToken - The refresh token.
 * @returns {Promise<Object>} - Result of the API call or clears store on error.
 */
const handleRefreshTokenApi = async (apiOptions, refreshToken) => {
	try {
		const response = await authApi().refreshToken({ refreshToken });
		reduxData('tokens', 'set')(response);

		await new Promise((resolve) => setTimeout(resolve, 1000));
		return await callApi(apiOptions);
	} catch (error) {
		clearStore(error.message);
		return Promise.reject(error);
	}
};

/**
 * Auth API functions
 */
export const authApi = () => {
	const sendOtpForLogin = async ({ value }) => {
		const data = { value, role: "librarian" }

		return await callApi({ ...apisEndPoints.auth.sendOtpForLogin, data });
	};

	const checkOtpForLogin = async (body = { otp: "", token: "" }) => {
		return await callApi({ ...apisEndPoints.auth.checkOtpForLogin, data: body });
	};

	const sendOtp = async ({ value }) => {
		const data = { value }

		return await callApi({ ...apisEndPoints.auth.sendOtp, data });
	};

	const checkOtp = async (body = { otp: "", token: "" }) => {
		return await callApi({ ...apisEndPoints.auth.checkOtp, data: body });
	};

	const googleSignIn = async (body = { googleAuthTokenId: "", libraryName: "" }) => {
		const data = {
			googleAuthTokenId: body.googleAuthTokenId,
			libraryName: body.libraryName,
			role: "librarian"
		}

		return await callApi({ ...apisEndPoints.auth.googleSignIn, data });
	}

	const logout = async (body = { refreshToken: "" }) => {
		return await callApi({ ...apisEndPoints.auth.logout, data: body });
	};

	const refreshToken = async (body = { refreshToken: "" }) => {
		return await callApi({ ...apisEndPoints.auth.refreshTokens, data: body });
	};

	const forgotPassword = async (body = { email: "" }) => {
		return await callApi({ ...apisEndPoints.auth.forgotPassword, data: body });
	};

	const resetPassword = async (body = { token: "", password: "" }) => {
		return await callApi({ ...apisEndPoints.auth.resetPassword, data: body });
	};

	const checkEmail = async (value) => {
		const data = {
			type: "email",
			value,
		};

		return await callApi({ ...apisEndPoints.auth.checkCredentials, data });
	};

	const checkNumber = async (value) => {
		const data = {
			type: "number",
			value: {
				countryCode: "91",
				phoneNumber: value
			}
		};

		return await callApi({ ...apisEndPoints.auth.checkCredentials, data });
	};

	const checkLibraryName = async (libraryName) => {
		const data = {
			type: "library",
			value: libraryName,
		};

		return await callApi({ ...apisEndPoints.auth.checkCredentials, data });
	};

	return {
		sendOtpForLogin,
		checkOtpForLogin,
		sendOtp,
		checkOtp,
		googleSignIn,
		logout,
		refreshToken,
		forgotPassword,
		resetPassword,
		checkEmail,
		checkNumber,
		checkLibraryName,
	};
};

export const profileApi = () => {
	const updateProfile = async (name = "", image = "") => {
		return await callApi({ ...apisEndPoints.profile.updateProfile, data: { name, image } });
	}

	const updateLibrary = async (name = "", logo = "", banner = "") => {
		return await callApi({ ...apisEndPoints.profile.updateLibrary, data: { name, logo, banner } });
	}

	const changePassword = async (body = { oldPassword: "", newPassword: "" }) => {
		return await callApi({ ...apisEndPoints.profile.changePassword, data: body });
	};

	const deleteAccount = async () => {
		return await callApi({ ...apisEndPoints.profile.deleteAccount });
	}

	return {
		updateProfile,
		updateLibrary,
		changePassword,
		deleteAccount,
	}
}

/**
 * Locations API functions
 */
export const locationsApi = () => {
	const getById = async (locationId) => {
		return await callApi({ ...apisEndPoints.locations.getById(locationId) });
	};

	const getAll = async (body = { sortKey: "createdAt", sortOrder: "DESC", page: 1, limit: 10, search: "" }) => {
		return await callApi({ ...apisEndPoints.locations.getAll, data: body });
	};

	const create = async (body = { name: "", address: "", city: "", state: "", postalCode: "", country: "", contactNumber: "" }) => {
		const data = { ...body, contactNumber: { countryCode: "91", phoneNumber: body.contactNumber } };
		return await callApi({ ...apisEndPoints.locations.create, data });
	};

	const update = async (locationId, body = { name: "", address: "", city: "", state: "", postalCode: "", country: "", contactNumber: "" }) => {
		const data = { ...body, contactNumber: { countryCode: "91", phoneNumber: body.contactNumber } };
		return await callApi({ ...apisEndPoints.locations.update(locationId), data });
	};

	const deleteLocation = async (locationId) => {
		return await callApi({ ...apisEndPoints.locations.delete(locationId) });
	};

	const getLibrarySettings = async (locationId) => {
		return await callApi({ ...apisEndPoints.locations.getLibrarySettings(locationId) });
	};

	const updateLibrarySettings = async (locationId, body = {}) => {
		return await callApi({ ...apisEndPoints.locations.updateLibrarySettings(locationId), data: body });
	};

	return {
		getById,
		getAll,
		create,
		update,
		deleteLocation,
		getLibrarySettings,
		updateLibrarySettings,
	};
};

/**
 * Floors API functions
 */
export const floorsApi = () => {
	const getAll = async (body = { sortKey: "createdAt", sortOrder: "DESC", page: 1, limit: 10, search: "" }) => {
		return await callApi({ ...apisEndPoints.floors.getAll, data: body });
	};

	const getAllWithSeats = async (body = { sortKey: "createdAt", sortOrder: "DESC", page: 1, limit: 100, search: "" }) => {
		return await callApi({ ...apisEndPoints.floors.getAllWithSeats, data: body });
	};

	const create = async (body = { name: "" }) => {
		return await callApi({ ...apisEndPoints.floors.create, data: body });
	};

	const update = async (floorId, body = { name: "" }) => {
		return await callApi({ ...apisEndPoints.floors.update(floorId), data: body });
	};

	const deleteFloor = async (floorId) => {
		return await callApi({ ...apisEndPoints.floors.delete(floorId) });
	};

	const getFloorSeats = async (floorId) => {
		return await callApi({ ...apisEndPoints.floors.getFloorSeats(floorId) });
	};

	const updateFloorSeats = async (floorId, body = [{ id: "", row: "", col: "", rotation: "", seatNumber: "" }]) => {
		const data = body.map(({ col, id, rotation, row, seatNumber }) => ({
			_id: id,
			number: seatNumber,
			position: { row, col, rotation },
		}));

		return await callApi({ ...apisEndPoints.floors.updateFloorSeats(floorId), data });
	};

	const updateSeatStatus = async (floorId, seatId, newStatus) => {
		return await callApi({ ...apisEndPoints.floors.updateSeatStatus(floorId), data: { status: newStatus, seatId: seatId } });
	};

	return {
		getAll,
		getAllWithSeats,
		create,
		update,
		deleteFloor,
		getFloorSeats,
		updateFloorSeats,
		updateSeatStatus,
	};
};

export const staffsApi = () => {
	const getAll = async (body = { sortKey: "createdAt", sortOrder: "DESC", page: 1, limit: 10, search: "" }) => {
		return await callApi({ ...apisEndPoints.staffs.getAll, data: body });
	};

	const create = async (body = { name: "" }) => {
		return await callApi({ ...apisEndPoints.staffs.create, data: body });
	};

	const update = async (roleId, body = { name: "", permissions: [] }) => {
		return await callApi({ ...apisEndPoints.staffs.update(roleId), data: body });
	};

	const deleteStaff = async (roleId) => {
		return await callApi({ ...apisEndPoints.staffs.delete(roleId) });
	};

	const getAllPermissions = async () => {
		return await callApi({ ...apisEndPoints.staffs.getAllPermissions });
	};

	return {
		getAll,
		create,
		update,
		deleteStaff,
		getAllPermissions,
	};
};

export const membersApi = () => {
	const getAll = (roleId) => async (body = { sortKey: "createdAt", sortOrder: "DESC", page: 1, limit: 10, search: "" }) => {
		return await callApi({ ...apisEndPoints.members.getAll, data: { ...body, roleId } });
	};

	const create = async (roleId, body = { name: "", email: "", contactNumber: "", image: "", locationId: "" }) => {
		const data = {
			name: body.name,
			email: body.email,
			contactNumber: { countryCode: "91", phoneNumber: body.contactNumber },
			image: body.image ? body.image : null,
			locationId: body.locationId ? body.locationId : null,
		};

		return await callApi({ ...apisEndPoints.members.create, data: { ...data, roleId } });
	};

	const update = async (roleId, memberId, body = { name: "", email: "", contactNumber: "", image: "", locationId: "" }) => {
		const data = {
			name: body.name,
			email: body.email,
			contactNumber: { countryCode: "91", phoneNumber: body.contactNumber },
			image: body.image ? body.image : null,
			locationId: body.locationId ? body.locationId : null,
		};

		return await callApi({ ...apisEndPoints.members.update(memberId), data: { ...data, roleId } });
	};

	const deleteMember = async (roleId, memberId) => {
		return await callApi({ ...apisEndPoints.members.delete(memberId), data: { roleId } });
	};

	return {
		getAll,
		create,
		update,
		deleteMember,
	};
};

export const offersApi = () => {
	const getAll = async (body = { sortKey: "price", sortOrder: "ASC", page: 1, limit: 10, search: "" }) => {
		const offers = await callApi({ ...apisEndPoints.offers.getAll, data: body });
		const totalOffers = offers?.results?.length ?? 0;
		reduxData("assetsCount", "updateObj")({ offers: totalOffers });
		return offers;
	};

	const create = async (body) => {
		return await callApi({ ...apisEndPoints.offers.create, data: body });
	}

	const getById = async (offerId) => {
		return await callApi({ ...apisEndPoints.offers.getById(offerId) });
	}

	const update = async (offerId, body) => {
		return await callApi({ ...apisEndPoints.offers.update(offerId), data: body });
	}

	const deleteOffer = async (offerId) => {
		return await callApi({ ...apisEndPoints.offers.delete(offerId) });
	}

	const addSubscriber = async (offerId, body) => {
		return await callApi({ ...apisEndPoints.offers.addSubscriber(offerId), data: body });
	}

	const getSubscribers = (offerId) => async (body = { sortKey: "createdAt", sortOrder: "DESC", page: 1, limit: 10, search: "" }) => {
		return await callApi({ ...apisEndPoints.offers.getSubscribers(offerId), data: body });
	}

	return {
		getAll,
		create,
		getById,
		update,
		deleteOffer,
		addSubscriber,
		getSubscribers,
	}
}

export const studentApi = () => {
	const getAll = async (body = { sortKey: "createdAt", sortOrder: "DESC", page: 1, limit: 10, search: "" }) => {
		return await callApi({ ...apisEndPoints.students.getAll, data: body });
	};

	const getOtherStudents = async (body = { sortKey: "createdAt", sortOrder: "DESC", page: 1, limit: 100, search: "" }) => {
		return await callApi({ ...apisEndPoints.students.getOtherStudents, data: body });
	};

	const create = async ({ value, name, studentId = "" }) => {
		return await callApi({ ...apisEndPoints.students.create, data: { value, name, studentId } });
	}

	const getById = async (studentId) => {
		return await callApi({ ...apisEndPoints.students.getById(studentId) });
	}

	const getBookings = async (studentId, body = { sortKey: "bookedAt", sortOrder: "DESC", page: 1, limit: 10, search: "" }) => {
		return await callApi({ ...apisEndPoints.students.getBookings(studentId), data: body });
	}

	const deleteStudent = async (studentId) => {
		return await callApi({ ...apisEndPoints.students.deleteStudent(studentId) });
	}

	return {
		getOtherStudents,
		getAll,
		create,
		getById,
		getBookings,
		deleteStudent,
	}
}

export const bookingsApi = () => {
	const getAll = async (body = { sortKey: "bookedAt", sortOrder: "DESC", page: 1, limit: 10, search: "" }) => {
		return await callApi({ ...apisEndPoints.bookings.getAll, data: body });
	};

	const assignSeat_or_checkIn = async ({ studentId, seatId, floorId, seatNumber, floorName, forceAssign = false, forceBooking = false }) => {
		const data = {
			studentId,
			seatId,
			floorId,
			floorName,
			seatNumber,
			forceAssign,
			forceBooking,
		}
		return await callApi({ ...apisEndPoints.bookings.assignSeat_or_checkIn, data });
	}

	const releaseSeat_or_checkOut = async ({ studentId, bookingId }) => {
		const data = { studentId, bookingId };
		return await callApi({ ...apisEndPoints.bookings.releaseSeat_or_checkOut, data });
	}

	const update = async (bookingId, body) => {
		return await callApi({ ...apisEndPoints.bookings.update(bookingId), data: body });
	}

	return {
		getAll,
		assignSeat_or_checkIn,
		releaseSeat_or_checkOut,
		update,
	}
}

export const subscriptionsApi = () => {
	const getAll = async (body = { sortKey: "createdAt", sortOrder: "DESC", page: 1, limit: 10, search: "" }) => {
		return await callApi({ ...apisEndPoints.reservations.getAll, data: body });
	};

	const confirmPayment = async (subscriptionId) => {
		return await callApi({ ...apisEndPoints.reservations.confirmPayment, data: { subscriptionId } });
	}

	const cancel = async (subscriptionId, reason) => {
		return await callApi({ ...apisEndPoints.reservations.cancel, data: { subscriptionId, reason } });
	}

	return {
		getAll,
		confirmPayment,
		cancel,
	}
}

export const planApi = () => {
	const getAll = async () => {
		return await callApi({ ...apisEndPoints.plans.getAll });
	}

	const changePlan = async (planId) => {
		return await callApi({ ...apisEndPoints.plans.changePlan(planId) });
	}

	const getActiveSubscription = async () => {
		return await callApi({ ...apisEndPoints.plans.getActiveSubscription })
	}

	return {
		getAll,
		changePlan,
		getActiveSubscription,
	}
}

export const reviewApi = () => {
	const getAll = async (body = { sortKey: "createdAt", sortOrder: "DESC", page: 1, limit: 10, search: "", type: "student" }) => {
		return await callApi({ ...apisEndPoints.reviews.getAll, data: body });
	};

	const create = async ({ comment, rating, studentId }) => {
		return await callApi({ ...apisEndPoints.reviews.create, data: { comment, rating, type: "student", studentId } });
	}

	const getMyReview = async (body = { studentId: "" }) => {
		return await callApi({ ...apisEndPoints.reviews.getMyReview, data: body });
	}

	const editMyReview = async (body = { comment: "", rating: "", studentId: "" }) => {
		return await callApi({ ...apisEndPoints.reviews.editMyReview, data: body });
	}

	const deleteMyReview = async (body = { studentId: "" }) => {
		return await callApi({ ...apisEndPoints.reviews.deleteMyReview, data: body });
	}

	return {
		getAll,
		create,
		getMyReview,
		editMyReview,
		deleteMyReview,
	}
}

export const walletApi = () => {
	const fetchBalance = async () => {
		return await callApi({ ...apisEndPoints.wallet.fetchBalance });
	};

	return {
		fetchBalance,
	}
}