import { useEffect, useState } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import lightTheme from './themes/light';
import darkTheme from './themes/dark';
import Routes from './Routes';
import { useSelector } from 'react-redux';

function App() {
  const themeMode = useSelector((state) => state.theme);   // Default is "system"

  // Theme state for dark/light mode
  const [isDarkMode, setIsDarkMode] = useState(false);

  // Update theme based on user or system preference
  useEffect(() => {
    const updateThemeMode = () => {
      const isSystemThemeDark = themeMode === "system" && window.matchMedia("(prefers-color-scheme: dark)").matches;
      setIsDarkMode(themeMode === "dark" || isSystemThemeDark);
    };

    updateThemeMode();

    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    mediaQuery.addEventListener("change", updateThemeMode);

    // Cleanup listener on unmount
    return () => mediaQuery.removeEventListener("change", updateThemeMode);
  }, [themeMode]);

  // Choose themeMode based on current mode
  const currentTheme = isDarkMode ? darkTheme : lightTheme;

  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />
      {/* Application routes */}
      <Routes />
    </ThemeProvider>
  );
}

export default App;
