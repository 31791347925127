import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomCard = styled(({ showAsList, ...otherProps }) => <Box {...otherProps} />)(
  ({ theme, showAsList }) => {
    const styleForCard = {
      marginBottom: 16,
      borderRadius: 15,
      boxShadow: theme.shadows[1],
      border: "1px solid",
      backgroundColor: theme.palette.background.paper,
    };

    return {
      ...(showAsList ? {} : styleForCard),
      padding: theme.spacing(2), // Use theme.spacing for consistent padding
      borderBottom: "2px solid",
      borderColor: theme.palette.divider,
    };
  }
);

export default CustomCard;
