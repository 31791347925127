import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { Typography } from "@mui/material";
import QueryContainer from "../../components/Containers/QueryContainer";

import { studentApi } from "../../services/api";

import StudentBookingCard from "../../components/Booking/StudentBookingCard";
import PageContainer from "../../components/Containers/PageContainer";
import StudentCard from "../../components/Students/StudentCard";


const StudentBookings = () => {
  const location = useLocation();
  const { student } = location.state;

  const studentId = student._id;

  const [bookings, setBookings] = useState([]);
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
    search: "",
    sortKey: "createdAt",
    sortOrder: "ASC",
  });

  const { data: queryData } = useQuery(
    ["bookings", studentId, query],
    () => studentApi().getBookings(studentId),
    {
      retry: false, // Disable retries on error
    }
  );

  // Sort options for query container
  const sortOptions = [
    { key: "createdAt", label: "Created At" },
    { key: "name", label: "Name" },
  ];

  // Handle query updates
  const handleQueryChange = (queryParams) => {
    setQuery({ ...query, ...queryParams });
  };

  // Handle data changes
  useEffect(() => {
    if (queryData) {
      setBookings(queryData.results);
    }
  }, [queryData, query.page]);


  return (
    <PageContainer>
      <StudentCard {...student} />

      {/* Bookings Section */}
      <QueryContainer
        fetchQueryReq={handleQueryChange}
        apiRes={queryData}
        defaultParams={{ ...query, sortOptions }}
      >
        <Typography variant="h4" sx={{ mb: 1 }}>Student Aattendance</Typography>
        {bookings.map((booking) => (<StudentBookingCard key={booking._id} booking={booking} />))}

      </QueryContainer>
    </PageContainer>
  );
};

export default StudentBookings;
