const uuid = require("uuid").v4;

export const getDeviceInfo = function () {
  const Storage = require("versioned-storage");

  const devicePlatform = navigator.platform;
  const deviceName = navigator.appName;
  const deviceName2 = navigator.appCodeName;
  const deviceLanguage = navigator.language;
  const appVersion = navigator.appVersion.split(" ")[0];

  const storage = new Storage(devicePlatform, appVersion);
  const deviceId = storage.read();

  const deviceInfo = {
    devicePlatform,
    deviceName,
    deviceName2,
    "locale-code": deviceLanguage.split("-")[0] === "en" ? "en" : deviceLanguage,
    deviceId,
    appVersion
  };

  if (deviceId === null || deviceId === undefined) {
    const newID = uuid();
    storage.write(newID);

    deviceInfo.deviceId = newID;
  }

  return deviceInfo;
};